//五捨五超入を行う関数
function round5(num) {
  //小数点以下2桁までを整数として取得
  let num_int100 = Math.trunc(num * 100);
  let num_str = num_int100.toString();
  //小数点1桁目と2桁目を取得
  let num_1digit = num_str[num_str.length - 2];
  let num_2digit = num_str[num_str.length - 1];

  //少数点1桁目が5の場合
  if (parseInt(num_1digit) === 5) {
    //小数点2桁目が0を超える場合、整数1を足して返す  
    if (parseInt(num_2digit) > 0) {
      num_int100 += 100;
      return Math.trunc(num_int100 / 100);
    }
    //少数点2桁目が0なら少数を切り捨てて返す
    return Math.trunc(num_int100 / 100);
  
  } else if (parseInt(num_1digit) > 5) {
    //小数点1桁目が5を超える場合、整数1を足して返す
    num_int100 += 100;
    return Math.trunc(num_int100 / 100);
  } else {
    //下1桁が5を超えない場合少数をそのまま返す
    return Math.trunc(num);
  }
}

export { round5 };