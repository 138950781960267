<!-- スピンボタンのコンポーネントを作成 -->
<template>
  <div class="spin-button">
    <button @click="decrement" class="btn btn-light btn-spin">-</button>
    <div class="d-inlilne-block spin-value">{{ value }}</div>
    <button @click="increment" class="btn btn-light btn-spin">+</button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
      validator: function (value) {
        return value >= this.min && value <= this.max
      }
    },
    min: {
      type: Number,
      default: -6
    },
    max: {
      type: Number,
      default: 6
    }
  },
  methods: {
    increment() {
      this.$emit('input', this.value + 1)
    },
    decrement() {
      this.$emit('input', this.value - 1)
    },
  }
}
</script>

<style scoped>
.btn-spin {
      font-weight: bold;
      width: 20px;
      height: 20px;
      padding: 0;
      margin-bottom: 5px;
      border: 1px solid #ced4da;
      background-color: #F9F7F7;
      color: #535b62;
      cursor: pointer;
}

.spin-value {
      display: inline-block;
      width: 25px;
      text-align: center;
}
</style>