import { round5 } from './round5.js';
import { calcSkillPowerByAbility } from './skillPowerByAbility.js';
import { calcAttackByAbility } from './attackByAbility.js';
import { modSkillTypeByAbility } from './skillTypeByAbility.js';

//ダメージ計算、共通事前処理
//タイプ相性補正、タイプ一致補正、攻撃力補正、防御力補正を計算する
function preprocessDamageCalc(myMonster, opponentMonster, skillType, attackRank, defenseRank, typeCompatibilities, burnStatus) {
    let preCalcValues = {
        firstTypeCompatibility: { typeCompatibilityId: 0, attackType: 0, targetType: 0, magnification: 1.0 },
        secondTypeCompatibility: { typeCompatibilityId: 0, attackType: 0, targetType: 0, magnification: 1.0 },
        typeMatchModifier: 1.0,
        lastAttack: 0,
        lastSpecialAttack: 0,
        lastDefense: 0,
        lastSpecialDefense: 0,
    };

    if (opponentMonster.teraStatus) {
        //テラスタル状態での相性補正
        let teraTypeCheck = typeCompatibilities.find(list => list.attackType === skillType && list.targetType === opponentMonster.teraType.id);
        if (typeof firstTypeCheck !== "undefined") {
            preCalcValues.firstTypeCompatibility = teraTypeCheck;
        }
    } else {
        //タイプ相性補正
        let firstTypeCheck = typeCompatibilities.find(list => list.attackType === skillType && list.targetType === opponentMonster.firstType.id);
        if (typeof firstTypeCheck !== "undefined") {
            preCalcValues.firstTypeCompatibility = firstTypeCheck;
        }
        let secondTypeCheck = typeCompatibilities.find(list => list.attackType === skillType && list.targetType === opponentMonster.secondType.id);
        if (typeof secondTypeCheck !== "undefined") {
            preCalcValues.secondTypeCompatibility = secondTypeCheck;
        }
    }

    if (myMonster.teraStatus) {
        //テラスタル状態でのタイプ一致補正
        if (myMonster.teraType.id === skillType) {
            //テラスタルタイプと技タイプが一致している場合
            preCalcValues.typeMatchModifier = 1.5;
            if (myMonster.firstType.id === myMonster.teraType.id || myMonster.secondType.id === myMonster.teraType.id) {
                //テラスタルタイプとモンスターのタイプが一致している場合
                preCalcValues.typeMatchModifier = 2.0;
            }
        }
    } else {
        //タイプ一致補正
        if (myMonster.firstType.id === skillType || myMonster.secondType.id === skillType) {
            preCalcValues.typeMatchModifier = 1.5;
        }
    }
    //ランクによる攻撃力の補正
    if (attackRank == 0) {
        preCalcValues.lastAttack = myMonster.attack;
        preCalcValues.lastSpecialAttack = myMonster.specialAttack;
    } else if (attackRank > 0) {
        preCalcValues.lastAttack = myMonster.attack * (2 + attackRank) / 2;
        preCalcValues.lastSpecialAttack = myMonster.specialAttack * (2 + attackRank) / 2;
    } else if (attackRank < 0) {
        preCalcValues.lastAttack = myMonster.attack * 2 / (2 + Math.abs(attackRank));
        preCalcValues.lastSpecialAttack = myMonster.specialAttack * 2 / (2 + Math.abs(attackRank));
    }
    //特性による攻撃力の補正
    preCalcValues.lastAttack = round5(
        calcAttackByAbility(
        myMonster.ability, opponentMonster.ability,
        preCalcValues.lastAttack, burnStatus, skillType)
        );

    //ランクによる防御力の補正
    if (defenseRank == 0) {
        preCalcValues.lastDefense = opponentMonster.defense;
        preCalcValues.lastSpecialDefense = opponentMonster.specialDefense;
    } else if (defenseRank > 0) {
        preCalcValues.lastDefense = opponentMonster.defense * (2 + defenseRank) / 2;
        preCalcValues.lastSpecialDefense = opponentMonster.specialDefense * (2 + defenseRank) / 2;
    } else if (defenseRank < 0) {
        preCalcValues.lastDefense = opponentMonster.defense * 2 / (2 + Math.abs(defenseRank));
        preCalcValues.lastSpecialDefense = opponentMonster.specialDefense * 2 / (2 + Math.abs(defenseRank));
    }

    //特性による防御力の補正
    preCalcValues.lastDefense = round5(
        calcAttackByAbility(
        myMonster.ability, opponentMonster.ability,
        preCalcValues.lastDefense)
        );

    return preCalcValues;
}


//最小ダメージを計算する関数
function resultMin(myMonster, opponentMonster, typeCompatibilities, attackRank, defenseRank, burnStatus, wallStatus, teraType) {
    //非攻撃技の場合は0を返す
    if (myMonster.skillPower == 0) {
        return 0;
    }
    // わざの威力
    let skillPower = myMonster.skillPower;
    // わざのタイプ
    let skillType = myMonster.skillType.id;
    // わざの威力補正値を計算
    skillPower = round5(calcSkillPowerByAbility(myMonster.ability, skillPower, skillType, myMonster.skill));
    // わざのタイプ補正を計算
    skillType = modSkillTypeByAbility(myMonster.ability, skillType);
    //ダメージ計算前の補正値を計算
    let preCalcValues = preprocessDamageCalc(myMonster, opponentMonster, skillType, attackRank, defenseRank, typeCompatibilities, teraType);
    //乱数ダメージ
    let randomDamageMin = 0;
    //タイプダメージ
    let typeDamageMin = 0;

    //ダメージ計算処理
    if (myMonster.classification === '物理') {
        let step1 = Math.trunc(50 * 2 / 5 + 2);
        let step2 = Math.trunc(step1 * skillPower * preCalcValues.lastAttack / preCalcValues.lastDefense);
        let step3 = Math.trunc(step2 / 50 + 2);
        //乱数補正
        randomDamageMin = Math.trunc(step3 * 0.85);
        //タイプ相性補正
        typeDamageMin = Math.trunc(
            round5(randomDamageMin * preCalcValues.typeMatchModifier)
            * preCalcValues.firstTypeCompatibility.magnification
            * preCalcValues.secondTypeCompatibility.magnification
        );
        //やけどの補正
        if (burnStatus) {
            typeDamageMin = round5(typeDamageMin * 0.5);
        }
    }
    //ダメージ計算処理
    if (myMonster.classification === '特殊') {
        let step1 = Math.trunc(50 * 2 / 5 + 2);
        let step2 = Math.trunc(step1 * skillPower * preCalcValues.lastSpecialAttack / preCalcValues.lastSpecialDefense);
        let step3 = Math.trunc(step2 / 50 + 2);
        //乱数補正
        randomDamageMin = Math.trunc(step3 * 0.85);
        //タイプ相性補正
        typeDamageMin = Math.trunc(
            round5(randomDamageMin * preCalcValues.typeMatchModifier)
            * preCalcValues.firstTypeCompatibility.magnification
            * preCalcValues.secondTypeCompatibility.magnification
        );
    }
    //壁の補正
    if (wallStatus) {
        typeDamageMin = round5(typeDamageMin * 0.5);
    }

    return typeDamageMin;
}

//最大ダメージを計算する関数
function resultMax(myMonster, opponentMonster, typeCompatibilities, attackRank, defenseRank, burnStatus, wallStatus, teraType) {
    ////非攻撃技の場合は0を返す
    if (myMonster.skillPower == 0) {
        return 0;
    }
    // わざの威力
    let skillPower = myMonster.skillPower;
    // わざのタイプ
    let skillType = myMonster.skillType.id;
    // わざの威力補正値を計算
    skillPower = round5(calcSkillPowerByAbility(myMonster.ability, skillPower, skillType, myMonster.skill));
    // わざのタイプ補正を計算
    skillType = modSkillTypeByAbility(myMonster.ability, skillType);
    //ダメージ計算前の補正値を計算
    let preCalcValues = preprocessDamageCalc(myMonster, opponentMonster, skillType, attackRank, defenseRank, typeCompatibilities, teraType);
    //乱数ダメージ
    let randomDamageMax = 0;
    //タイプダメージ
    let typeDamageMax = 0;

    //ダメージ計算処理
    if (myMonster.classification === '物理') {
        //ダメージ計算
        let step1 = Math.trunc(50 * 2 / 5 + 2);
        let step2 = Math.trunc(step1 * skillPower * preCalcValues.lastAttack / preCalcValues.lastDefense);
        let step3 = Math.trunc(step2 / 50 + 2);
        //乱数補正
        randomDamageMax = Math.trunc(step3 * 1.00);
        //タイプ相性補正
        typeDamageMax = Math.trunc(
            round5(randomDamageMax * preCalcValues.typeMatchModifier)
            * preCalcValues.firstTypeCompatibility.magnification
            * preCalcValues.secondTypeCompatibility.magnification
        );

        //やけどの補正
        if (burnStatus) {
            typeDamageMax = round5(typeDamageMax * 0.5);
        }
    }
    //ダメージ計算処理
    if (myMonster.classification === '特殊') {
        let step1 = Math.trunc(50 * 2 / 5 + 2);
        let step2 = Math.trunc(step1 * skillPower * preCalcValues.lastSpecialAttack / preCalcValues.lastSpecialDefense);
        let step3 = Math.trunc(step2 / 50 + 2);
        //乱数補正
        randomDamageMax = Math.trunc(step3 * 1.00);
        typeDamageMax = Math.trunc(
            round5(randomDamageMax * preCalcValues.typeMatchModifier)
            * preCalcValues.firstTypeCompatibility.magnification
            * preCalcValues.secondTypeCompatibility.magnification
        );
    }

    //壁の補正
    if (wallStatus) {
        typeDamageMax = round5(typeDamageMax * 0.5);
    }

    return typeDamageMax;
}

export { resultMin, resultMax };
