//ダメージ計算、共通事前処理
//特性によるこうげき、特攻の威力補正を計算する
function calcAttackByAbility(myMonsterAbility, opponentMonsterAbility, Attack, burnStatus ,skillType) {

    //補正後の技の威力
    let calculatedAttack = Attack;

    //こんじょう
    if (myMonsterAbility === 'こんじょう') {
        //こんじょう状態の場合
        if (burnStatus) {
            calculatedAttack =  Attack * 1.5;
        }
    }

    //あついしぼう
    if (opponentMonsterAbility === 'あついしぼう') {
        //わざのタイプがほのおもしくはこおりの場合
        if (skillType === 2 || skillType === 6) {
            calculatedAttack =  Attack * 0.5;
        }
    }
    return calculatedAttack;
}

export { calcAttackByAbility };
