<template>
  <div class="container-fluid h-100 m-0 p-0">
    <nav class="navbar navbar-dark mb-4 py-3">
      <div class="container-fluid">
        <span class="navbar-brand mb-0 h1">ポケモンシングルバトル用計算機（SV）</span>
        
      </div>
    </nav>
    <router-view></router-view>
    <footer class="text-center">
      <p>&copy; 2023 ポケモンシングルバトル用計算機（SV）</p>
      <p><a class="footerPolicy" href="https://pokesingle.com/policy">当サイトについて</a></p>
      <br/>
    </footer>
  </div>

</template>

<script>
export default {
  methods: {
    policyPageGet() {
      this.axios.get("https://pokesingle.com/policy")
      .then()
      .catch(err => {
        console.error(err);
      })
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500&display=swap');

  .navbar-dark {
    background-color : #424242;
    font-size: 1.25rem;
  }

  html {
    font-size: 62.5%;
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    color: #F9F7F7;
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 500;
    background-color: #3C403B;
  }

  .footerPolicy:hover {
    color: #AAAAAA;
    text-decoration:none;
  }

  .footerPolicy {
    color: #AAAAAA;
  }

  footer {
    margin: 1.5rem auto;
    color: #AAAAAA;
  }
  
  footer p {
    margin-bottom: 0;
  }

</style>
