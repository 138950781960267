<script>

export default{
  props: {
    show: Boolean,
    nationalNo: Number,
    pokeNameList: Object,
    pokeList: Object,
    abilityList: Object,
    typeList: Object,
    pokeName: String,
    teraType: Object,
    effortHitPoints: Number,
    effortAttack: Number,
    effortDefense: Number,
    effortSpecialAttack: Number,
    effortSpecialDefense: Number,
    effortSpeed: Number
  },
  data() {
    return {
      formPokeInfo: {
        pokeName: this.pokeName,
        nationalNo: this.nationalNo,
        ability: this.ability,
        abilityList: this.abilityList,
        item: '-',
        teraType: this.teraType,
        effortHitPoints: this.effortHitPoints,
        effortAttack: this.effortAttack,
        effortDefense: this.effortDefense,
        effortSpecialAttack: this.effortSpecialAttack,
        effortSpecialDefense: this.effortSpecialDefense,
        effortSpeed: this.effortSpeed
      },
      skillList: [],
      abilities: [],
      itemList: [],
      effortPoints:[
        0,4,12,20,28,36,44,52,
        60,68,76,84,92,100,108,
        116,124,132,140,148,156,
        164,172,180,188,196,204,
        212,220,228,236,244,252
      ],
      tmpList: ["-"],
    }
  },
  watch: {
    //formPokeInfo.pokeNameが変更されたら、pokeListからnationalNoを更新して、特性リスト取得メソッドを呼び出す
    'formPokeInfo.pokeName': function (pokeName) {
      for (const elem of this.pokeList) {
        if (elem.monsterName == pokeName) {
          this.formPokeInfo.nationalNo = elem.nationalNo;
          this.formPokeInfo.abilityList = ["-"];
          this.fetchAbilities(elem.nationalNo);
        }
      }
    },
  },
  methods: {
  // ポケモンの特性リストを取得
  fetchAbilities(nationalNo) {
      var url = "https://pokesingle.com/abilityList/nationalNo/" + nationalNo
      this.axios.get(url)
        .then(response => {
            this.abilities = response.data;
            for (const elem of this.abilities) {
              this.formPokeInfo.abilityList.push(elem.abilities.abilityName);
            }
            this.formPokeInfo.ability = this.formPokeInfo.abilityList[0];
        })
        .catch(err => {
          console.error(err);
        })
    },
  }
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask" @click.self="$emit('close')">
      <div class="modal-container">
        <div class="modal-header text-center">
          ポケモン情報入力
        </div>

        <div class="modal-body">
          <form class="form-group row">
            <div class="col-12">
              <v-select name="pokeName" :options="pokeNameList" v-model="formPokeInfo.pokeName"></v-select>
            </div>
          </form>
          <p>努力値</p>
          <div class="form-group row">
            <div class="col-6">
                <label for="hitpoints">HP</label>
                <v-select name="effortHitPoints" :options="effortPoints" v-model="formPokeInfo.effortHitPoints"></v-select>
            </div>
            <div class="col-6">
              <label for="effortDefense">B</label>
              <v-select name="effortDeffense" :options="effortPoints" v-model="formPokeInfo.effortDefense"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-6">
                <label for="effortDefense">A</label>
              <v-select name="effortAttack" :options="effortPoints" v-model="formPokeInfo.effortAttack"></v-select>
            </div>
            <div class="col-6">
              <label for="effortSpecialDefense">D</label>
              <v-select name="effortSpecialDeffense" :options="effortPoints" v-model="formPokeInfo.effortSpecialDefense"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-6">
                <label for="effortSpecialAttack">C</label>
                <v-select name="effortSpecialAttack" :options="effortPoints" v-model="formPokeInfo.effortSpecialAttack"></v-select>
            </div>
            <div class="col-6">
              <label for="effortSpeed">S</label>
              <v-select name="effortSpeed" :options="effortPoints" v-model="formPokeInfo.effortSpeed"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-4">
              <label for="sbillity">特性</label>
            </div>
            <div class="col-8 modalAbility">
              <v-select name="ability" :options="formPokeInfo.abilityList" v-model="formPokeInfo.ability"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-4">
              <label for="teraType">テラスタル</label>
            </div>
            <div class="col-8">
              <v-select name="teraType" :options="typeList" v-model="formPokeInfo.teraType"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-4">
              <label for="item">持ち物</label>
            </div>
            <div class="col-8">
              <v-select name="item" :options="tmpList" v-model="formPokeInfo.item"></v-select>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            class="btn modal-default-button"
            @click="$emit('close'),$emit('formPokeInfoPost',this.formPokeInfo)"
          >OK</button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
@import "vue-select/dist/vue-select.css";
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 300px;
  margin: auto;
  padding: 20px 15px;
  background-color: #F9F7F7;
  border-radius: 30px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header{
  margin-top: 0;
  color: #202020;
  font-size: 1.25rem;
}

.modal-body {
  margin: 10px 0;
  color :#202020;
  font-size: 1.2rem;
}

.modal-default-button {
  width: 100%;
  background-color: #80D67F;
  font-weight: 500;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modalAbility .vs__search {
  padding-right: 0;
  padding-left: 0;
}
</style>