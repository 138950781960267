import { createApp } from 'vue'
import App from './App'
import vSelect from 'vue-select'
import router from './router'
import axios from "axios"
import VueAxios from 'vue-axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

vSelect.props.components.default = () => ({
  Deselect: {
  },
});

const app = createApp(App);
app.component("v-select", vSelect);
app.use(router);
app.use(VueAxios, axios);
app.mount('#app');