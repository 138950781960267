//ダメージ計算、共通事前処理
//特性によるわざの威力補正を計算する
function calcSkillPowerByAbility(ability, skillPower, skillType, skillName) {

    //補正後の技の威力
    let calculatedSkillPower = skillPower;

    //ノーマルタイプの技の場合
    if (skillType === 1) {
        // エレキスキン
        if (ability === 'エレキスキン') {
            calculatedSkillPower = skillPower * 1.2;
        }
        // フェアリースキン
        if (ability === 'フェアリースキン') {
            calculatedSkillPower = skillPower * 1.2;
        }
    }

    //てつのこぶし
    if (ability === 'てつのこぶし') {
        //パンチ技の場合
        if (skillName.includes('パンチ')) {
            calculatedSkillPower = skillPower * 1.2;
        }
        //すいりゅうれんだ 
        if (skillName === 'すいりゅうれんだ') {
            calculatedSkillPower = skillPower * 1.2;
        }
        //あんこくきょうだ
        if (skillName === 'あんこくきょうだ') {
            calculatedSkillPower = skillPower * 1.2;
        }
        //プラズマフィスト
        if (skillName === 'プラズマフィスト') {
            calculatedSkillPower = skillPower * 1.2;
        }
        //ふんどのこぶし
        if (skillName === 'ふんどのこぶし') {
            calculatedSkillPower = skillPower * 1.2;
        }
        //ぶちかまし
        if (skillName === 'ぶちかまし') {
            calculatedSkillPower = skillPower * 1.2;
        }
        //アイスハンマー
        if (skillName === 'アイスハンマー') {
            calculatedSkillPower = skillPower * 1.2;
        }
        //アームハンマー
        if (skillName === 'アームハンマー') {
            calculatedSkillPower = skillPower * 1.2;
        }
        //スカイアッパー
        if (skillName === 'スカイアッパー') {
            calculatedSkillPower = skillPower * 1.2;
        }
        //ダブルパンツァー
        if (skillName === 'ダブルパンツァー') {
            calculatedSkillPower = skillPower * 1.2;
        }

    }


    return calculatedSkillPower;
}

export { calcSkillPowerByAbility };
