import { createRouter, createWebHistory } from 'vue-router';
import TopPage from '../components/TopPage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/',
     redirect: '/top' },
    { path: '/top', component: TopPage,
      meta: {title: 'シングルバトル用ダメージ計算（SV）', desc: 'ポケモン対戦のシングルバトルで使いやすいダメージ計算ツールです。' } }
  ],
});

const DEFAULT_TITLE = 'Default Title'
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE
})
export default router;