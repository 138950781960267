//ダメージ計算、共通事前処理
//特性によるわざのタイプを変更する処理
function modSkillTypeByAbility(ability, skillType) {

    //補正後の技の威力
    let modSkillType = skillType;

    //ノーマルタイプの技の場合
    if (skillType === 1) {
        // エレキスキン
        if (ability === 'エレキスキン') {
            modSkillType = 4;
        }
        // フェアリースキン
        if (ability === 'フェアリースキン') {
            modSkillType = 18;
        }
    }

    return modSkillType;
}

export { modSkillTypeByAbility };
