<template>
  <div class="container-lg pokeCalc">
    <!-- 防御側のポケモン選択フィールド -->
    <div class="row opponentField mb-4">
      <div class="col-sm-12 mt-1">
        <div class="row text-center">
          <div class="col-4 pr-0 d-flex justify-content-center align-items-center">
            <div class="pt-1">
              <input v-model="opponentMonsterCursor" type="radio" name="opponentMonsterRadioOptions" id="inlineRadio1"
                value="1">
            </div>
            <div>
              <button type="button" class="btn opponentMonster ml-3" @click="showModalOpponentFirst = true">{{
                opponentMonsterFirst.pokeName }}</button>
            </div>
          </div>
          <div class="col-4 px-0 d-flex justify-content-center align-items-center">
            <div class="pt-1">
              <input v-model="opponentMonsterCursor" type="radio" name="opponentMonsterRadioOptions" id="inlineRadio1"
                value="2">
            </div>
            <div>
              <button type="button" class="btn opponentMonster ml-3" @click="showModalOpponentSecond = true">{{
                opponentMonsterSecond.pokeName }}</button>
            </div>
          </div>
          <div class="col-4 pl-0 d-flex justify-content-center align-items-center">
            <div class="pt-1">
              <input v-model="opponentMonsterCursor" type="radio" name="opponentMonsterRadioOptions" id="inlineRadio1"
                value="3">
            </div>
            <div>
              <button type="button" class="btn opponentMonster ml-3" @click="showModalOpponentThird = true">{{
                opponentMonsterThird.pokeName }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 防御側のポケモン情報フィールド -->
    <p class="opponentStatusName">相手側（防御）</p>
    <div class="row opponentStatus rounded mb-4 mx-0">
      <div class="col-12 text-left">
        <div class="row damageCaluc mx-0">
          <div class="col-4 ml-0 my-auto p-0 opponentName">
            <div>{{ opponentMonster.pokeName }}</div>
          </div>
          <div class="col-8 mb-0 mt-auto ml-auto mr-0 pb-0 pr-0">
            <div class="damagebar">
              <div class="progress">
                <div class="progress-bar max-bar"></div>
                <div class="progress-bar min-bar"></div>
              </div>
            </div>
            <p class="m-0 damageText">{{ damageResultMin }}～{{ damageResultMax }} {{ attackCount }}</p>
          </div>
        </div>
        <p class="opponentBaseStatus">{{ opponentMonster.firstType.label }} {{ opponentMonster.secondType.label }}</p>
        <div class="row statusRow">
          <div class="col-6">
            <div class="row lCol">
              <div class="col-4 statusTitle">
                HP
              </div>
              <div class="col-8 statusValue">
                {{ opponentMonster.hitPoints }}（{{ opponentMonster.effortHitPoints }}）
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row rCol">
              <div class="col-4 statusTitle">
                防御
              </div>
              <div class="col-8 statusValue">
                {{ opponentMonster.defense }}（{{ opponentMonster.effortDefense }}）
              </div>
            </div>
          </div>
        </div>
        <div class="row statusRow">
          <div class="col-6">
            <div class="row lCol">
              <div class="col-4 statusTitle">
                素早さ
              </div>
              <div class="col-8 statusValue">
                {{ opponentMonster.speed }}（{{ opponentMonster.effortSpeed }}）
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row rCol">
              <div class="col-4 statusTitle">
                特防
              </div>
              <div class="col-8 statusValue">
                {{ opponentMonster.specialDefense }}（{{ opponentMonster.effortSpecialDefense }}）
              </div>
            </div>
          </div>
        </div>
        <div class="row statusRow">
          <div class="col-6">
            <div class="row lCol">
              <div class="col-3 statusTitle">
                特性
              </div>
              <div class="col-9 statusValue">
                <v-select class="statusSelect" name="ability" :options="opponentMonster.abilityList"
                  v-model="opponentMonster.ability">
                  <template #no-options>
                    見つかりません。
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row rCol">
              <div class="col-6 statusTitle">
                防御ランク
              </div>
              <div class="col-6 statusValue">
                <spin-Button v-model="defenseRank" :value="defenseRank" @input="defenseRank = $event" />
              </div>
            </div>
          </div>
        </div>
        <div class="row statusRow">
          <div class="col-6">
            <div class="row lCol">
              <div class="col-4 statusTitle">
                持ち物
              </div>
              <div class="col-8 development statusValue">
                ※作成中
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row rCol">
              <div class="col-4 statusTitle">
                壁
              </div>
              <div class="col-8 d-flex justify-content-end statusValue">
                <input v-model="wallStatus" type="checkbox" />
              </div>
            </div>
          </div>
        </div>
        <div class="row statusRow">
          <div class="col-8">
            <div class="row lCol">
              <div class="col-9 statusTitle">
                テラスタル&emsp;&emsp;{{ opponentMonster.teraType.label }}
              </div>
              <div class="col-3 d-flex justify-content-end statusValue">
                <input v-model="opponentMonster.teraStatus" type="checkbox" />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row rCol">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>攻撃、防御入れ替え</div> -->
    <!-- 攻撃側のポケモン情報フィールド -->
    <p class="myStatusName">自分側（攻撃）</p>
    <div class="row myStatus rounded mb-4 mx-0">
      <div class="col-12 text-left">
        <div class="row damageCaluc mx-0">
          <div class="col-4 m-auto p-0 myName">
            <div>{{ myMonster.pokeName }}</div>
          </div>
          <div class="col-8 mb-0 mt-auto mx-auto pb-0">
            <div class="row damageCaluc">
              <div class="col-1 my-auto px-0 text-right">技</div>
              <div class="col my-auto pr-0">
                <v-select class="skillSelect" name="skill" :options="myMonster.skillList" v-model="myMonster.skill">
                  <template #no-options>
                    見つかりません。
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <p class="myBaseStatus">{{ myMonster.firstType.label }} {{ myMonster.secondType.label }}</p>
        <!-- 基本ステータス -->
        <div class="row statusRow">
          <!-- 攻撃 -->
          <div class="col-6">
            <div class="row lCol">
              <div class="col-4 statusTitle">
                攻撃
              </div>
              <div class="col-8 statusValue">
                {{ myMonster.attack }}（{{ myMonster.effortAttack }}）
              </div>
            </div>
          </div>
          <!-- 特攻 -->
          <div class="col-6">
            <div class="row rCol">
              <div class="col-4 statusTitle">
                特攻
              </div>
              <div class="col-8 statusValue">
                {{ myMonster.specialAttack }}（{{ myMonster.effortSpecialAttack }}）
              </div>
            </div>
          </div>
        </div>
        <div class="row statusRow">
          <!-- 素早さ -->
          <div class="col-6">
            <div class="row lCol">
              <div class="col-4 statusTitle">
                素早さ
              </div>
              <div class="col-8 statusValue">
                {{ myMonster.speed }}（{{ myMonster.effortSpeed }}）
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row rCol">
              <div class="col-12"></div>
            </div>
          </div>
        </div>
        <!-- 状態、持ち物 -->
        <div class="row statusRow">
          <div class="col-6">
            <div class="row lCol">
              <div class="col-3 statusTitle">
                特性
              </div>
              <div class="col-9 statusValue">
                <v-select class="statusSelect" name="ability" :options="myMonster.abilityList"
                clearable=false v-model="myMonster.ability">
                  <template #no-options>
                    見つかりません。
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row rCol">
              <div class="col-6 statusTitle">
                攻撃ランク
              </div>
              <div class="col-6 statusValue">
                <!-- use the Spinbutton component, pass in the prop -->
                <spin-Button v-model="attackRank" :value="attackRank" @input="attackRank = $event" />
              </div>
            </div>
          </div>
        </div>
        <div class="row statusRow">
          <div class="col-6">
            <div class="row lCol">
              <div class="col-4 statusTitle">
                持ち物
              </div>
              <div class="col-8 development statusValue">
                ※作成中
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row rCol">
              <div class="col-4 statusTitle">
                やけど
              </div>
              <div class="col-8 d-flex justify-content-end statusValue">
                <input v-model="burnStatus" type="checkbox" />
              </div>
            </div>
          </div>
        </div>
        <div class="row statusRow">
          <div class="col-8">
            <div class="row lCol">
              <div class="col-9 statusTitle">
                テラスタル&emsp;&emsp;{{ myMonster.teraType.label }}
              </div>
              <div class="col-3 d-flex justify-content-end statusValue">
                <input v-model="myMonster.teraStatus" type="checkbox" />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row rCol">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 攻撃側のポケモン選択フィールド -->
    <div class="row myField mb-4">
      <div class="col-sm-12">
        <div class="row text-center">
          <div class="col-4 pr-0 d-flex justify-content-center align-items-center">
            <div class="pt-1">
              <input v-model="myMonsterCursor" type="radio" name="myMonsterRadioOptions" id="inlineRadio4" value="4">
            </div>
            <div>
              <button type="button" class="btn myMonster ml-3" @click="showModalMyFirst = true">{{ myMonsterFirst.pokeName
              }}</button>
            </div>
          </div>
          <div class="col-4 px-0 d-flex justify-content-center align-items-center">
            <div class="pt-1">
              <input v-model="myMonsterCursor" type="radio" name="myMonsterRadioOptions" id="inlineRadio5" value="5">
            </div>
            <div>
              <button type="button" class="btn myMonster ml-3" @click="showModalMySecond = true">{{
                myMonsterSecond.pokeName }}</button>
            </div>
          </div>
          <div class="col-4 pl-0 d-flex justify-content-center align-items-center">
            <div class="pt-1">
              <input v-model="myMonsterCursor" type="radio" name="myMonsterRadioOptions" id="inlineRadio6" value="6">
            </div>
            <div>
              <button type="button" class="btn myMonster ml-3" @click="showModalMyThird = true">{{ myMonsterThird.pokeName
              }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- ポケモン入力用モーダル -->
  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modal :show="showModalOpponentFirst" :nationalNo="opponentMonsterFirst.nationalNo" :pokeList="pokeList"
      :pokeNameList="pokeNameList" :typeList="typeList" :abilityList="opponentMonsterFirst.abilityList"
      :pokeName="opponentMonsterFirst.pokeName" :teraType="opponentMonsterFirst.teraType"
      :effortHitPoints="opponentMonsterFirst.effortHitPoints" :effortAttack="opponentMonsterFirst.effortAttack"
      :effortDefense="opponentMonsterFirst.effortDefense" :effortSpecialAttack="opponentMonsterFirst.effortSpecialAttack"
      :effortSpecialDefense="opponentMonsterFirst.effortSpecialDefense" :effortSpeed="opponentMonsterFirst.effortSpeed"
      @close="showModalOpponentFirst = false" @formPokeInfoPost="opponentMonsterFirstUpdate">
    </modal>
  </Teleport>

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modal :show="showModalOpponentSecond" :nationalNo="opponentMonsterSecond.nationalNo" :pokeList="pokeList"
      :pokeNameList="pokeNameList" :typeList="typeList" :abilityList="opponentMonsterSecond.abilityList"
      :pokeName="opponentMonsterSecond.pokeName" :teraType="opponentMonsterSecond.teraType"
      :effortHitPoints="opponentMonsterSecond.effortHitPoints" :effortAttack="opponentMonsterSecond.effortAttack"
      :effortDefense="opponentMonsterSecond.effortDefense"
      :effortSpecialAttack="opponentMonsterSecond.effortSpecialAttack"
      :effortSpecialDefense="opponentMonsterSecond.effortSpecialDefense" :effortSpeed="opponentMonsterSecond.effortSpeed"
      @close="showModalOpponentSecond = false" @formPokeInfoPost="opponentMonsterSecondUpdate">
    </modal>
  </Teleport>

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modal :show="showModalOpponentThird" :nationalNo="opponentMonsterThird.nationalNo" :pokeList="pokeList"
      :pokeNameList="pokeNameList" :typeList="typeList" :abilityList="opponentMonsterThird.abilityList"
      :pokeName="opponentMonsterThird.pokeName" :teraType="opponentMonsterThird.teraType"
      :effortHitPoints="opponentMonsterThird.effortHitPoints" :effortAttack="opponentMonsterThird.effortAttack"
      :effortDefense="opponentMonsterThird.effortDefense" :effortSpecialAttack="opponentMonsterThird.effortSpecialAttack"
      :effortSpecialDefense="opponentMonsterThird.effortSpecialDefense" :effortSpeed="opponentMonsterThird.effortSpeed"
      @close="showModalOpponentThird = false" @formPokeInfoPost="opponentMonsterThirdUpdate">
    </modal>
  </Teleport>

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modal :show="showModalMyFirst" :nationalNo="myMonsterFirst.nationalNo" :abilityList="myMonsterFirst.abilityList"
      :pokeList="pokeList" :pokeNameList="pokeNameList" :typeList="typeList" :pokeName="myMonsterFirst.pokeName"
      :teraType="myMonsterFirst.teraType" :effortHitPoints="myMonsterFirst.effortHitPoints"
      :effortAttack="myMonsterFirst.effortAttack" :effortDefense="myMonsterFirst.effortDefense"
      :effortSpecialAttack="myMonsterFirst.effortSpecialAttack"
      :effortSpecialDefense="myMonsterFirst.effortSpecialDefense" :effortSpeed="myMonsterFirst.effortSpeed"
      @close="showModalMyFirst = false" @formPokeInfoPost="myMonsterFirstUpdate">
    </modal>
  </Teleport>

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modal :show="showModalMySecond" :nationalNo="myMonsterSecond.nationalNo" :pokeList="pokeList"
      :pokeNameList="pokeNameList" :typeList="typeList" :abilityList="myMonsterSecond.abilityList"
      :pokeName="myMonsterSecond.pokeName" :teraType="myMonsterSecond.teraType"
      :effortHitPoints="myMonsterSecond.effortHitPoints" :effortAttack="myMonsterSecond.effortAttack"
      :effortDefense="myMonsterSecond.effortDefense" :effortSpecialAttack="myMonsterSecond.effortSpecialAttack"
      :effortSpecialDefense="myMonsterSecond.effortSpecialDefense" :effortSpeed="myMonsterSecond.effortSpeed"
      @close="showModalMySecond = false" @formPokeInfoPost="myMonsterSecondUpdate">
    </modal>
  </Teleport>

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modal :show="showModalMyThird" :nationalNo="myMonsterThird.nationalNo" :abilityList="myMonsterThird.abilityList"
      :pokeList="pokeList" :pokeNameList="pokeNameList" :typeList="typeList" :pokeName="myMonsterThird.pokeName"
      :teraType="myMonsterThird.teraType" :effortHitPoints="myMonsterThird.effortHitPoints"
      :effortAttack="myMonsterThird.effortAttack" :effortDefense="myMonsterThird.effortDefense"
      :effortSpecialAttack="myMonsterThird.effortSpecialAttack"
      :effortSpecialDefense="myMonsterThird.effortSpecialDefense" :effortSpeed="myMonsterThird.effortSpeed"
      @close="showModalMyThird = false" @formPokeInfoPost="myMonsterThirdUpdate">
    </modal>
  </Teleport>
</template>


<script>
import Modal from './MonsterModalForm.vue';
import SpinButton from './SpinButton.vue';
import { resultMin, resultMax } from './damageCalc.js';
import { attackCount } from './calcAttackCount.js';

export default {
  components: {
    Modal,
    SpinButton
  },
  data() {
    return {
      pokeList: [],
      pokeNameList: [],
      skills: [],
      abilities: [],
      typeCompatibilities: [
        { typeCompatibilityId: 1, attackType: 1, targetType: 13, magnification: 0.5 },
        { typeCompatibilityId: 2, attackType: 1, targetType: 14, magnification: 0.0 },
        { typeCompatibilityId: 3, attackType: 1, targetType: 17, magnification: 0.5 },
        { typeCompatibilityId: 4, attackType: 2, targetType: 2, magnification: 0.5 },
        { typeCompatibilityId: 5, attackType: 2, targetType: 3, magnification: 0.5 },
        { typeCompatibilityId: 6, attackType: 2, targetType: 5, magnification: 2.0 },
        { typeCompatibilityId: 7, attackType: 2, targetType: 6, magnification: 2.0 },
        { typeCompatibilityId: 8, attackType: 2, targetType: 12, magnification: 2.0 },
        { typeCompatibilityId: 9, attackType: 2, targetType: 13, magnification: 0.5 },
        { typeCompatibilityId: 10, attackType: 2, targetType: 15, magnification: 0.5 },
        { typeCompatibilityId: 11, attackType: 2, targetType: 17, magnification: 2.0 },
        { typeCompatibilityId: 12, attackType: 3, targetType: 2, magnification: 2.0 },
        { typeCompatibilityId: 13, attackType: 3, targetType: 3, magnification: 0.5 },
        { typeCompatibilityId: 14, attackType: 3, targetType: 5, magnification: 0.5 },
        { typeCompatibilityId: 15, attackType: 3, targetType: 9, magnification: 2.0 },
        { typeCompatibilityId: 16, attackType: 3, targetType: 13, magnification: 2.0 },
        { typeCompatibilityId: 17, attackType: 3, targetType: 15, magnification: 0.5 },
        { typeCompatibilityId: 18, attackType: 4, targetType: 3, magnification: 2.0 },
        { typeCompatibilityId: 19, attackType: 4, targetType: 4, magnification: 0.5 },
        { typeCompatibilityId: 20, attackType: 4, targetType: 5, magnification: 0.5 },
        { typeCompatibilityId: 21, attackType: 4, targetType: 9, magnification: 0.0 },
        { typeCompatibilityId: 22, attackType: 4, targetType: 10, magnification: 2.0 },
        { typeCompatibilityId: 23, attackType: 4, targetType: 15, magnification: 0.5 },
        { typeCompatibilityId: 24, attackType: 5, targetType: 2, magnification: 0.5 },
        { typeCompatibilityId: 25, attackType: 5, targetType: 3, magnification: 2.0 },
        { typeCompatibilityId: 26, attackType: 5, targetType: 5, magnification: 0.5 },
        { typeCompatibilityId: 27, attackType: 5, targetType: 8, magnification: 0.5 },
        { typeCompatibilityId: 28, attackType: 5, targetType: 9, magnification: 2.0 },
        { typeCompatibilityId: 29, attackType: 5, targetType: 10, magnification: 0.5 },
        { typeCompatibilityId: 30, attackType: 5, targetType: 12, magnification: 0.5 },
        { typeCompatibilityId: 31, attackType: 5, targetType: 13, magnification: 2.0 },
        { typeCompatibilityId: 32, attackType: 5, targetType: 15, magnification: 0.5 },
        { typeCompatibilityId: 33, attackType: 5, targetType: 17, magnification: 0.5 },
        { typeCompatibilityId: 34, attackType: 6, targetType: 2, magnification: 0.5 },
        { typeCompatibilityId: 35, attackType: 6, targetType: 3, magnification: 0.5 },
        { typeCompatibilityId: 36, attackType: 6, targetType: 5, magnification: 2.0 },
        { typeCompatibilityId: 37, attackType: 6, targetType: 6, magnification: 0.5 },
        { typeCompatibilityId: 38, attackType: 6, targetType: 9, magnification: 2.0 },
        { typeCompatibilityId: 39, attackType: 6, targetType: 10, magnification: 2.0 },
        { typeCompatibilityId: 40, attackType: 6, targetType: 15, magnification: 2.0 },
        { typeCompatibilityId: 41, attackType: 6, targetType: 17, magnification: 0.5 },
        { typeCompatibilityId: 42, attackType: 7, targetType: 1, magnification: 2.0 },
        { typeCompatibilityId: 43, attackType: 7, targetType: 6, magnification: 2.0 },
        { typeCompatibilityId: 44, attackType: 7, targetType: 8, magnification: 0.5 },
        { typeCompatibilityId: 45, attackType: 7, targetType: 10, magnification: 0.5 },
        { typeCompatibilityId: 46, attackType: 7, targetType: 11, magnification: 0.5 },
        { typeCompatibilityId: 47, attackType: 7, targetType: 12, magnification: 0.5 },
        { typeCompatibilityId: 48, attackType: 7, targetType: 13, magnification: 2.0 },
        { typeCompatibilityId: 49, attackType: 7, targetType: 14, magnification: 0.0 },
        { typeCompatibilityId: 50, attackType: 7, targetType: 16, magnification: 2.0 },
        { typeCompatibilityId: 51, attackType: 7, targetType: 17, magnification: 2.0 },
        { typeCompatibilityId: 52, attackType: 7, targetType: 18, magnification: 0.5 },
        { typeCompatibilityId: 53, attackType: 8, targetType: 5, magnification: 2.0 },
        { typeCompatibilityId: 54, attackType: 8, targetType: 8, magnification: 0.5 },
        { typeCompatibilityId: 55, attackType: 8, targetType: 9, magnification: 0.5 },
        { typeCompatibilityId: 56, attackType: 8, targetType: 13, magnification: 0.5 },
        { typeCompatibilityId: 57, attackType: 8, targetType: 14, magnification: 0.5 },
        { typeCompatibilityId: 58, attackType: 8, targetType: 17, magnification: 0.0 },
        { typeCompatibilityId: 59, attackType: 8, targetType: 18, magnification: 2.0 },
        { typeCompatibilityId: 60, attackType: 9, targetType: 2, magnification: 2.0 },
        { typeCompatibilityId: 61, attackType: 9, targetType: 4, magnification: 2.0 },
        { typeCompatibilityId: 62, attackType: 9, targetType: 5, magnification: 0.5 },
        { typeCompatibilityId: 63, attackType: 9, targetType: 8, magnification: 2.0 },
        { typeCompatibilityId: 64, attackType: 9, targetType: 10, magnification: 0.0 },
        { typeCompatibilityId: 65, attackType: 9, targetType: 12, magnification: 0.5 },
        { typeCompatibilityId: 66, attackType: 9, targetType: 13, magnification: 2.0 },
        { typeCompatibilityId: 67, attackType: 9, targetType: 17, magnification: 2.0 },
        { typeCompatibilityId: 68, attackType: 10, targetType: 4, magnification: 0.5 },
        { typeCompatibilityId: 69, attackType: 10, targetType: 5, magnification: 2.0 },
        { typeCompatibilityId: 70, attackType: 10, targetType: 7, magnification: 2.0 },
        { typeCompatibilityId: 71, attackType: 10, targetType: 12, magnification: 2.0 },
        { typeCompatibilityId: 72, attackType: 10, targetType: 13, magnification: 0.5 },
        { typeCompatibilityId: 73, attackType: 10, targetType: 17, magnification: 0.5 },
        { typeCompatibilityId: 74, attackType: 11, targetType: 7, magnification: 2.0 },
        { typeCompatibilityId: 75, attackType: 11, targetType: 8, magnification: 2.0 },
        { typeCompatibilityId: 76, attackType: 11, targetType: 11, magnification: 0.5 },
        { typeCompatibilityId: 77, attackType: 11, targetType: 16, magnification: 0.0 },
        { typeCompatibilityId: 78, attackType: 11, targetType: 17, magnification: 0.5 },
        { typeCompatibilityId: 79, attackType: 12, targetType: 2, magnification: 0.5 },
        { typeCompatibilityId: 80, attackType: 12, targetType: 5, magnification: 2.0 },
        { typeCompatibilityId: 81, attackType: 12, targetType: 7, magnification: 0.5 },
        { typeCompatibilityId: 82, attackType: 12, targetType: 8, magnification: 0.5 },
        { typeCompatibilityId: 83, attackType: 12, targetType: 10, magnification: 0.5 },
        { typeCompatibilityId: 84, attackType: 12, targetType: 11, magnification: 2.0 },
        { typeCompatibilityId: 85, attackType: 12, targetType: 14, magnification: 0.5 },
        { typeCompatibilityId: 86, attackType: 12, targetType: 16, magnification: 2.0 },
        { typeCompatibilityId: 87, attackType: 12, targetType: 17, magnification: 0.5 },
        { typeCompatibilityId: 88, attackType: 12, targetType: 18, magnification: 0.5 },
        { typeCompatibilityId: 89, attackType: 13, targetType: 2, magnification: 2.0 },
        { typeCompatibilityId: 90, attackType: 13, targetType: 6, magnification: 2.0 },
        { typeCompatibilityId: 91, attackType: 13, targetType: 7, magnification: 0.5 },
        { typeCompatibilityId: 92, attackType: 13, targetType: 9, magnification: 0.5 },
        { typeCompatibilityId: 93, attackType: 13, targetType: 10, magnification: 2.0 },
        { typeCompatibilityId: 94, attackType: 13, targetType: 12, magnification: 2.0 },
        { typeCompatibilityId: 95, attackType: 13, targetType: 17, magnification: 0.5 },
        { typeCompatibilityId: 96, attackType: 14, targetType: 1, magnification: 0.0 },
        { typeCompatibilityId: 97, attackType: 14, targetType: 11, magnification: 2.0 },
        { typeCompatibilityId: 98, attackType: 14, targetType: 14, magnification: 2.0 },
        { typeCompatibilityId: 99, attackType: 14, targetType: 16, magnification: 0.5 },
        { typeCompatibilityId: 100, attackType: 15, targetType: 15, magnification: 2.0 },
        { typeCompatibilityId: 101, attackType: 15, targetType: 17, magnification: 0.5 },
        { typeCompatibilityId: 102, attackType: 15, targetType: 18, magnification: 0.0 },
        { typeCompatibilityId: 103, attackType: 16, targetType: 7, magnification: 0.5 },
        { typeCompatibilityId: 104, attackType: 16, targetType: 11, magnification: 2.0 },
        { typeCompatibilityId: 105, attackType: 16, targetType: 14, magnification: 2.0 },
        { typeCompatibilityId: 106, attackType: 16, targetType: 16, magnification: 0.5 },
        { typeCompatibilityId: 107, attackType: 16, targetType: 18, magnification: 0.5 },
        { typeCompatibilityId: 108, attackType: 17, targetType: 2, magnification: 0.5 },
        { typeCompatibilityId: 109, attackType: 17, targetType: 3, magnification: 0.5 },
        { typeCompatibilityId: 110, attackType: 17, targetType: 4, magnification: 0.5 },
        { typeCompatibilityId: 111, attackType: 17, targetType: 6, magnification: 2.0 },
        { typeCompatibilityId: 112, attackType: 17, targetType: 13, magnification: 2.0 },
        { typeCompatibilityId: 113, attackType: 17, targetType: 17, magnification: 0.5 },
        { typeCompatibilityId: 114, attackType: 17, targetType: 18, magnification: 2.0 },
        { typeCompatibilityId: 115, attackType: 18, targetType: 2, magnification: 0.5 },
        { typeCompatibilityId: 116, attackType: 18, targetType: 7, magnification: 2.0 },
        { typeCompatibilityId: 117, attackType: 18, targetType: 8, magnification: 0.5 },
        { typeCompatibilityId: 118, attackType: 18, targetType: 15, magnification: 2.0 },
        { typeCompatibilityId: 119, attackType: 18, targetType: 16, magnification: 2.0 },
        { typeCompatibilityId: 120, attackType: 18, targetType: 17, magnification: 1.0 }
      ],
      showModalOpponentFirst: false,
      showModalOpponentSecond: false,
      showModalOpponentThird: false,
      showModalMyFirst: false,
      showModalMySecond: false,
      showModalMyThird: false,
      damageBarMin: '0%',
      damageBarMax: '100%',
      opponentMonsterCursor: 1,
      myMonsterCursor: 4,
      attackRank: 0,
      defenseRank: 0,
      wallStatus: false,
      burnStatus: false,
      myMonster: {
        pokeName: 'カイリュー',
        nationalNo: 149,
        firstType: { label: 'ドラゴン', id: 15 },
        secondType: { label: 'ひこう', id: 10 },
        skill: 'しんそく',
        skillType: { label: 'ノーマル', id: 1 },
        classification: '物理',
        skillPower: 80,
        skills: [{ skillListId: 27911, skillId: 768, nationalNo: 149, skills: { skillId: 768, skillName: "ぼうふう", classification: "特殊", damage: 110, skillType: 10, remarks: " 30%の確率で、相手を2〜5ターンの間『こんらん』状態にする。天気が『あめ』の時は必ず命中し、『にほんばれ』の時は命中率が50%になる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中する。        " } },
        { skillListId: 27912, skillId: 761, nationalNo: 149, skills: { skillId: 761, skillName: "ほのおのパンチ", classification: "物理", damage: 75, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27913, skillId: 155, nationalNo: 149, skills: { skillId: 155, skillName: "かみなりパンチ", classification: "物理", damage: 75, skillType: 4, remarks: " 10%の確率で相手を『まひ』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27915, skillId: 777, nationalNo: 149, skills: { skillId: 777, skillName: "まきつく", classification: "物理", damage: 15, skillType: 1, remarks: " 4〜5ターンの間、毎ターン終了後最大HPの1/8のダメージを与え、その間『ゴースト』タイプではない相手は逃げたり交代できない。        " } },
        { skillListId: 27918, skillId: 424, nationalNo: 149, skills: { skillId: 424, skillName: "たつまき", classification: "特殊", damage: 40, skillType: 15, remarks: " 20%の確率で相手をひるませる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中し、ダメージが2倍になる。        " } },
        { skillListId: 27919, skillId: 488, nationalNo: 149, skills: { skillId: 488, skillName: "つばさでうつ", classification: "物理", damage: 60, skillType: 10, remarks: " 通常攻撃。        " } },
        { skillListId: 27920, skillId: 343, nationalNo: 149, skills: { skillId: 343, skillName: "しんそく", classification: "物理", damage: 80, skillType: 1, remarks: " 必ず先制できる(優先度:+2)。        " } },
        { skillListId: 27921, skillId: 568, nationalNo: 149, skills: { skillId: 568, skillName: "ドラゴンテール", classification: "物理", damage: 60, skillType: 15, remarks: " 必ず後攻になる(優先度:-6)。攻撃後、相手のポケモンを強制的に交代させる。野生のポケモンの場合は戦闘を終了する。ダイマックスしている相手には追加効果は無効。        " } },
        { skillListId: 27923, skillId: 421, nationalNo: 149, skills: { skillId: 421, skillName: "たたきつける", classification: "物理", damage: 80, skillType: 1, remarks: " 通常攻撃。        " } },
        { skillListId: 27924, skillId: 14, nationalNo: 149, skills: { skillId: 14, skillName: "アクアテール", classification: "物理", damage: 90, skillType: 3, remarks: " 通常攻撃。        " } },
        { skillListId: 27925, skillId: 567, nationalNo: 149, skills: { skillId: 567, skillName: "ドラゴンダイブ", classification: "物理", damage: 100, skillType: 15, remarks: " 20%の確率で相手をひるませる。相手が『ちいさくなる』を使用していると必ず命中し、威力が2倍になる。        " } },
        { skillListId: 27926, skillId: 252, nationalNo: 149, skills: { skillId: 252, skillName: "げきりん", classification: "物理", damage: 120, skillType: 15, remarks: " 2〜3ターン連続で攻撃し、その後自分は1〜4ターンの間『こんらん』状態になる。        " } },
        { skillListId: 27930, skillId: 632, nationalNo: 149, skills: { skillId: 632, skillName: "はかいこうせん", classification: "特殊", damage: 150, skillType: 1, remarks: " 使用した次のターンは行動できない。        " } },
        { skillListId: 27931, skillId: 531, nationalNo: 149, skills: { skillId: 531, skillName: "とっしん", classification: "物理", damage: 90, skillType: 1, remarks: " 相手に与えたダメージの1/4を自分も受ける。        " } },
        { skillListId: 27935, skillId: 808, nationalNo: 149, skills: { skillId: 808, skillName: "みずのはどう", classification: "特殊", damage: 60, skillType: 3, remarks: " 20%の確率で相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 27936, skillId: 251, nationalNo: 149, skills: { skillId: 251, skillName: "けたぐり", classification: "物理", damage: 0, skillType: 7, remarks: " 相手の『おもさ』が重いほど威力が高くなる。10kg未満は20、25kg未満は40、50kg未満は60、100kg未満は80、200kg未満は100、それ以上は120。ダイマックスしている相手には無効。        " } },
        { skillListId: 27937, skillId: 681, nationalNo: 149, skills: { skillId: 681, skillName: "ひやみず", classification: "特殊", damage: 50, skillType: 3, remarks: " 100%の確率で相手の『こうげき』ランクを1段階下げる。        " } },
        { skillListId: 27938, skillId: 758, nationalNo: 149, skills: { skillId: 758, skillName: "ほのおのうず", classification: "特殊", damage: 35, skillType: 2, remarks: " 4〜5ターンの間、毎ターン終了後最大HPの1/8のダメージを与え、その間『ゴースト』タイプではない相手は逃げたり交代できない。        " } },
        { skillListId: 27939, skillId: 156, nationalNo: 149, skills: { skillId: 156, skillName: "からげんき", classification: "物理", damage: 70, skillType: 1, remarks: " 自分が『どく』『まひ』『やけど』状態の時、威力が2倍になる。『やけど』による『こうげき』の半減の影響を受けない。        " } },
        { skillListId: 27940, skillId: 489, nationalNo: 149, skills: { skillId: 489, skillName: "つばめがえし", classification: "物理", damage: 60, skillType: 10, remarks: " 自分の命中率、相手の回避率に関係なく必ず命中する。        " } },
        { skillListId: 27941, skillId: 358, nationalNo: 149, skills: { skillId: 358, skillName: "じならし", classification: "物理", damage: 60, skillType: 9, remarks: " 100%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 27942, skillId: 393, nationalNo: 149, skills: { skillId: 393, skillName: "スピードスター", classification: "特殊", damage: 60, skillType: 1, remarks: " 自分の命中率、相手の回避率に関係なく必ず命中する。        " } },
        { skillListId: 27943, skillId: 268, nationalNo: 149, skills: { skillId: 268, skillName: "こごえるかぜ", classification: "特殊", damage: 55, skillType: 6, remarks: " 100%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 27944, skillId: 170, nationalNo: 149, skills: { skillId: 170, skillName: "がんせきふうじ", classification: "物理", damage: 60, skillType: 13, remarks: " 100%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 27945, skillId: 90, nationalNo: 149, skills: { skillId: 90, skillName: "エアカッター", classification: "特殊", damage: 60, skillType: 10, remarks: " 急所に当たりやすい(急所ランク:+1)。        " } },
        { skillListId: 27946, skillId: 587, nationalNo: 149, skills: { skillId: 587, skillName: "なげつける", classification: "物理", damage: 0, skillType: 16, remarks: " 自分の持っている道具によって効果が変化する。投げつけた道具はなくなる。        " } },
        { skillListId: 27947, skillId: 568, nationalNo: 149, skills: { skillId: 568, skillName: "ドラゴンテール", classification: "物理", damage: 60, skillType: 15, remarks: " 必ず後攻になる(優先度:-6)。攻撃後、相手のポケモンを強制的に交代させる。野生のポケモンの場合は戦闘を終了する。ダイマックスしている相手には追加効果は無効。        " } },
        { skillListId: 27953, skillId: 162, nationalNo: 149, skills: { skillId: 162, skillName: "かわらわり", classification: "物理", damage: 75, skillType: 7, remarks: " 相手の技『リフレクター』『オーロラベール』の効果を受けず、相手の技『リフレクター』『ひかりのかべ』『オーロラベール』を壊す。        " } },
        { skillListId: 27954, skillId: 91, nationalNo: 149, skills: { skillId: 91, skillName: "エアスラッシュ", classification: "特殊", damage: 75, skillType: 10, remarks: " 30%の確率で相手をひるませる。        " } },
        { skillListId: 27955, skillId: 617, nationalNo: 149, skills: { skillId: 617, skillName: "のしかかり", classification: "物理", damage: 85, skillType: 1, remarks: " 30%の確率で相手を『まひ』状態にする。相手が技『ちいさくなる』を使用していると必ず命中し、威力が2倍になる。        " } },
        { skillListId: 27956, skillId: 761, nationalNo: 149, skills: { skillId: 761, skillName: "ほのおのパンチ", classification: "物理", damage: 75, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27957, skillId: 155, nationalNo: 149, skills: { skillId: 155, skillName: "かみなりパンチ", classification: "物理", damage: 75, skillType: 4, remarks: " 10%の確率で相手を『まひ』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27958, skillId: 887, nationalNo: 149, skills: { skillId: 887, skillName: "れいとうパンチ", classification: "物理", damage: 75, skillType: 6, remarks: " 10%の確率で相手を『こおり』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27961, skillId: 418, nationalNo: 149, skills: { skillId: 418, skillName: "たきのぼり", classification: "物理", damage: 80, skillType: 3, remarks: " 20%の確率で相手をひるませる。        " } },
        { skillListId: 27962, skillId: 566, nationalNo: 149, skills: { skillId: 566, skillName: "ドラゴンクロー", classification: "物理", damage: 80, skillType: 15, remarks: " 通常攻撃。        " } },
        { skillListId: 27965, skillId: 357, nationalNo: 149, skills: { skillId: 357, skillName: "じだんだ", classification: "物理", damage: 75, skillType: 9, remarks: " 前のターンで自分が使った技が失敗していた場合、威力が2倍になる。        " } },
        { skillListId: 27967, skillId: 74, nationalNo: 149, skills: { skillId: 74, skillName: "いわなだれ", classification: "物理", damage: 75, skillType: 13, remarks: " 30%の確率で相手をひるませる。        " } },
        { skillListId: 27968, skillId: 770, nationalNo: 149, skills: { skillId: 770, skillName: "ボディプレス", classification: "物理", damage: 80, skillType: 7, remarks: " 『こうげき』ではなく、自分の『ぼうぎょ』と『ぼうぎょ』ランクを『こうげき』の数値にしてダメージ計算する。        " } },
        { skillListId: 27969, skillId: 414, nationalNo: 149, skills: { skillId: 414, skillName: "そらをとぶ", classification: "物理", damage: 90, skillType: 10, remarks: " 1ターン目に空中へ飛び上がり、2ターン目に攻撃する。空中にいる間は『うちおとす』『かぜおこし』『かみなり』『サウザンアロー』『スカイアッパー』『たつまき』『ぼうふう』以外の技を受けない。        " } },
        { skillListId: 27970, skillId: 5, nationalNo: 149, skills: { skillId: 5, skillName: "アイアンヘッド", classification: "物理", damage: 80, skillType: 17, remarks: " 30%の確率で相手をひるませる。        " } },
        { skillListId: 27974, skillId: 880, nationalNo: 149, skills: { skillId: 880, skillName: "りゅうのはどう", classification: "特殊", damage: 85, skillType: 15, remarks: " 通常攻撃。        " } },
        { skillListId: 27975, skillId: 610, nationalNo: 149, skills: { skillId: 610, skillName: "ねっぷう", classification: "特殊", damage: 95, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。        " } },
        { skillListId: 27977, skillId: 591, nationalNo: 149, skills: { skillId: 591, skillName: "なみのり", classification: "特殊", damage: 90, skillType: 3, remarks: " 相手が『ダイビング』を使っている時でも命中し、ダメージが2倍になる。        " } },
        { skillListId: 27978, skillId: 7, nationalNo: 149, skills: { skillId: 7, skillName: "アイススピナー", classification: "物理", damage: 80, skillType: 6, remarks: " 場の状態『グラスフィールド』『ミストフィールド』『エレキフィールド』『サイコフィールド』を解除し、元に戻す。        " } },
        { skillListId: 27979, skillId: 135, nationalNo: 149, skills: { skillId: 135, skillName: "かえんほうしゃ", classification: "特殊", damage: 90, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。        " } },
        { skillListId: 27980, skillId: 366, nationalNo: 149, skills: { skillId: 366, skillName: "10まんボルト", classification: "特殊", damage: 90, skillType: 4, remarks: " 10%の確率で相手を『まひ』状態にする。        " } },
        { skillListId: 27982, skillId: 888, nationalNo: 149, skills: { skillId: 888, skillName: "れいとうビーム", classification: "特殊", damage: 90, skillType: 6, remarks: " 10%の確率で相手を『こおり』状態にする。        " } },
        { skillListId: 27983, skillId: 456, nationalNo: 149, skills: { skillId: 456, skillName: "だいもんじ", classification: "特殊", damage: 110, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。        " } },
        { skillListId: 27984, skillId: 627, nationalNo: 149, skills: { skillId: 627, skillName: "ハイドロポンプ", classification: "特殊", damage: 110, skillType: 3, remarks: " 通常攻撃。        " } },
        { skillListId: 27985, skillId: 701, nationalNo: 149, skills: { skillId: 701, skillName: "ふぶき", classification: "特殊", damage: 110, skillType: 6, remarks: " 10%の確率で相手を『こおり』状態にする。天気が『ゆき』の時は必ず命中する。        " } },
        { skillListId: 27986, skillId: 355, nationalNo: 149, skills: { skillId: 355, skillName: "じしん", classification: "物理", damage: 100, skillType: 9, remarks: " 相手が技『あなをほる』を使っている時でも命中し、ダメージが2倍になる。        " } },
        { skillListId: 27987, skillId: 386, nationalNo: 149, skills: { skillId: 386, skillName: "ストーンエッジ", classification: "物理", damage: 100, skillType: 13, remarks: " 急所に当たりやすい(急所ランク:+1)。        " } },
        { skillListId: 27988, skillId: 224, nationalNo: 149, skills: { skillId: 224, skillName: "ギガインパクト", classification: "物理", damage: 150, skillType: 1, remarks: " 使用した次のターンは行動できない。        " } },
        { skillListId: 27989, skillId: 252, nationalNo: 149, skills: { skillId: 252, skillName: "げきりん", classification: "物理", damage: 120, skillType: 15, remarks: " 2〜3ターン連続で攻撃し、その後自分は1〜4ターンの間『こんらん』状態になる。        " } },
        { skillListId: 27990, skillId: 172, nationalNo: 149, skills: { skillId: 172, skillName: "きあいだま", classification: "特殊", damage: 120, skillType: 7, remarks: " 10%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 27991, skillId: 768, nationalNo: 149, skills: { skillId: 768, skillName: "ぼうふう", classification: "特殊", damage: 110, skillType: 10, remarks: " 30%の確率で、相手を2〜5ターンの間『こんらん』状態にする。天気が『あめ』の時は必ず命中し、『にほんばれ』の時は命中率が50%になる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中する。        " } },
        { skillListId: 27992, skillId: 632, nationalNo: 149, skills: { skillId: 632, skillName: "はかいこうせん", classification: "特殊", damage: 150, skillType: 1, remarks: " 使用した次のターンは行動できない。        " } },
        { skillListId: 27993, skillId: 152, nationalNo: 149, skills: { skillId: 152, skillName: "かみなり", classification: "特殊", damage: 110, skillType: 4, remarks: " 30%の確率で相手を『まひ』状態にする。天気が『あめ』の時は必ず命中し、『にほんばれ』の時は命中率が50%になる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中する。        " } },
        { skillListId: 27994, skillId: 877, nationalNo: 149, skills: { skillId: 877, skillName: "りゅうせいぐん", classification: "特殊", damage: 130, skillType: 15, remarks: " 攻撃後、100%の確率で自分の『とくこう』ランクが2段階下がる。        " } },
        { skillListId: 27995, skillId: 504, nationalNo: 149, skills: { skillId: 504, skillName: "テラバースト", classification: "特殊", damage: 80, skillType: 1, remarks: " 自分がテラスタル状態の時、技のタイプが自分のテラスタイプになる。その際、この技の分類は、自分の攻撃と特攻の高い方となるため、『こうげき』のほうが高い場合は物理技となる。        " } },
        { skillListId: 27996, skillId: 475, nationalNo: 149, skills: { skillId: 475, skillName: "ちょうおんぱ", classification: "変化", damage: 0, skillType: 1, remarks: " 相手を1〜4ターンの間『こんらん』状態にする。音系の技。相手の『みがわり』状態を貫通する。        " } },
        { skillListId: 27997, skillId: 340, nationalNo: 149, skills: { skillId: 340, skillName: "しろいきり", classification: "変化", damage: 0, skillType: 6, remarks: " 5ターンの間、自分と味方のポケモンは能力ランクを下げられなくなる。交代しても効果は続く。        " } },
        { skillListId: 27998, skillId: 879, nationalNo: 149, skills: { skillId: 879, skillName: "りゅうのいぶき", classification: "特殊", damage: 60, skillType: 15, remarks: " 30%の確率で相手を『まひ』状態にする。        " } },
        { skillListId: 27999, skillId: 343, nationalNo: 149, skills: { skillId: 343, skillName: "しんそく", classification: "物理", damage: 80, skillType: 1, remarks: " 必ず先制できる(優先度:+2)。        " } },
        { skillListId: 28000, skillId: 808, nationalNo: 149, skills: { skillId: 808, skillName: "みずのはどう", classification: "特殊", damage: 60, skillType: 3, remarks: " 20%の確率で相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 28001, skillId: 12, nationalNo: 149, skills: { skillId: 12, skillName: "アクアジェット", classification: "物理", damage: 40, skillType: 3, remarks: " 必ず先制できる(優先度:+1)。        " } },
        { skillListId: 28002, skillId: 878, nationalNo: 149, skills: { skillId: 878, skillName: "りゅうのいかり", classification: "特殊", damage: 0, skillType: 15, remarks: " 40の固定ダメージを与える。『フェアリー』タイプには無効だが、それ以外のタイプ相性の影響を受けない。        " } },
        { skillListId: 28003, skillId: 401, nationalNo: 149, skills: { skillId: 401, skillName: "ずつき", classification: "物理", damage: 70, skillType: 1, remarks: " 30%の確率で相手をひるませる。        " } },
        { skillListId: 28004, skillId: 384, nationalNo: 149, skills: { skillId: 384, skillName: "すてみタックル", classification: "物理", damage: 120, skillType: 1, remarks: " 相手に与えたダメージの33%を自分も受ける。        " } },
        { skillListId: 28005, skillId: 806, nationalNo: 149, skills: { skillId: 806, skillName: "みずでっぽう", classification: "特殊", damage: 40, skillType: 3, remarks: " 通常攻撃。        " } },
        { skillListId: 28006, skillId: 660, nationalNo: 149, skills: { skillId: 660, skillName: "バブルこうせん", classification: "特殊", damage: 65, skillType: 3, remarks: " 10%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 28007, skillId: 554, nationalNo: 149, skills: { skillId: 554, skillName: "どくどく", classification: "変化", damage: 0, skillType: 8, remarks: " 相手を『もうどく』状態にする。『もうどく』状態は通常の『どく』状態と違い、受けるダメージが1/16、2/16、3/16・・・と増えていく。『どく』タイプのポケモンが使うと必ず命中する。『どく』タイプや『はがね』タイプには無効。        " } },
        { skillListId: 28008, skillId: 51, nationalNo: 149, skills: { skillId: 51, skillName: "いかり", classification: "物理", damage: 20, skillType: 1, remarks: " 自分を『いかり』状態にする。『いかり』状態の間、相手の技のダメージを受ける度に『こうげき』ランクが1段階上がる。別の技を使うと『いかり』状態が解除される(変化したランクはそのまま)。        " } },
        { skillListId: 28012, skillId: 166, nationalNo: 149, skills: { skillId: 166, skillName: "がまん", classification: "物理", damage: 0, skillType: 1, remarks: " 必ず先制できる(優先度:+1)。今後2ターンの間に受けたダメージの2倍を相手に与える。その間、全く行動できなくなる。『ゴースト』タイプには無効だが、それ以外のタイプ相性の影響を受けない。        " } },
        { skillListId: 28013, skillId: 893, nationalNo: 149, skills: { skillId: 893, skillName: "ロケットずつき", classification: "物理", damage: 130, skillType: 1, remarks: " 1ターン目に100%の確率で自分の『ぼうぎょ』ランクを1段階上げ、2ターン目で攻撃する。        " } },
        { skillListId: 28014, skillId: 67, nationalNo: 149, skills: { skillId: 67, skillName: "いびき", classification: "特殊", damage: 50, skillType: 1, remarks: " 自分が『ねむり』状態の時のみ使用可能。30%の確率で相手をひるませる。音系の技。相手の『みがわり』状態を貫通する。        " } },
        { skillListId: 28016, skillId: 522, nationalNo: 149, skills: { skillId: 522, skillName: "でんじほう", classification: "特殊", damage: 120, skillType: 4, remarks: " 100%の確率で相手を『まひ』状態にする。        " } },
        { skillListId: 28020, skillId: 126, nationalNo: 149, skills: { skillId: 126, skillName: "おんがえし", classification: "物理", damage: 0, skillType: 1, remarks: " ポケモンがなついているほど威力が高くなる。最大102。        " } },
        { skillListId: 28021, skillId: 850, nationalNo: 149, skills: { skillId: 850, skillName: "やつあたり", classification: "物理", damage: 0, skillType: 1, remarks: " ポケモンがなついていないほど威力が高くなる。最大102。        " } },
        { skillListId: 28022, skillId: 4, nationalNo: 149, skills: { skillId: 4, skillName: "アイアンテール", classification: "物理", damage: 100, skillType: 17, remarks: " 30%の確率で相手の『ぼうぎょ』ランクを1段階下げる。        " } },
        { skillListId: 28023, skillId: 837, nationalNo: 149, skills: { skillId: 837, skillName: "めざめるパワー", classification: "特殊", damage: 60, skillType: 1, remarks: " 自分の個体値によって『タイプ』が変わる。(BW2までは威力も個体値によって変化)        " } },
        { skillListId: 28024, skillId: 78, nationalNo: 149, skills: { skillId: 78, skillName: "うずしお", classification: "特殊", damage: 35, skillType: 3, remarks: " 4〜5ターンの間、毎ターン終了後最大HPの1/8のダメージを与え、その間『ゴースト』タイプではない相手は逃げたり交代できない。相手が『ダイビング』を使っている時でも命中し、ダメージが2倍になる。        " } },
        { skillListId: 28026, skillId: 679, nationalNo: 149, skills: { skillId: 679, skillName: "ひみつのちから", classification: "物理", damage: 70, skillType: 1, remarks: " 30%の確率で相手に以下の追加効果を与える。地形が草むらやグラスフィールドの時は『ねむり』状態、水上の時は『こうげき』ランクを1段階下げ、砂地の時は命中率を1段階下げ、岩場と洞窟の時はひるませ、雪原と氷上の時は『こおり』状態、火山の時は『やけど』状態、ミストフィールドの時は『とくこう』ランクを1段階下げ、サイコフィールドの時は『すばやさ』ランクを1段階下げ、ウルトラスペースの時は『ぼうぎょ』を1段階下げ、その他は『まひ』状態にする。また、ORASではフィールドで使うと秘密基地を作ることができる。        " } },
        { skillListId: 28027, skillId: 517, nationalNo: 149, skills: { skillId: 517, skillName: "でんげきは", classification: "特殊", damage: 60, skillType: 4, remarks: " 自分の命中率、相手の回避率に関係なく必ず命中する。        " } },
        { skillListId: 28028, skillId: 320, nationalNo: 149, skills: { skillId: 320, skillName: "しぜんのめぐみ", classification: "物理", damage: 0, skillType: 1, remarks: " 持っている『きのみ』によって『タイプ』と威力が変わる。『きのみ』はなくなる。        " } },
        { skillListId: 28029, skillId: 853, nationalNo: 149, skills: { skillId: 853, skillName: "ゆうわく", classification: "変化", damage: 0, skillType: 1, remarks: " 相手が自分とは異なる性別の場合、相手の『とくこう』を2段階下げる。自分と相手の性別が同じ時や、どちらかが性別不明の場合は失敗する。        " } },
        { skillListId: 28030, skillId: 883, nationalNo: 149, skills: { skillId: 883, skillName: "りんしょう", classification: "特殊", damage: 60, skillType: 1, remarks: " 同じターンに他のポケモンも『りんしょう』を使おうとすると、『すばやさ』に関係なく最初に使用したポケモンに続いて使用でき、最初以外の『りんしょう』は威力が2倍になる。音系の技。相手の『みがわり』状態を貫通する。(ダブルバトル用)        " } },
        { skillListId: 28031, skillId: 849, nationalNo: 149, skills: { skillId: 849, skillName: "やきつくす", classification: "特殊", damage: 60, skillType: 2, remarks: " 相手の持っている『きのみ』や『ノーマルジュエル』を使えなくする。        " } },
        { skillListId: 28033, skillId: 734, nationalNo: 149, skills: { skillId: 734, skillName: "ぶんまわす", classification: "物理", damage: 60, skillType: 16, remarks: " 通常攻撃。        " } },
        { skillListId: 28034, skillId: 901, nationalNo: 149, skills: { skillId: 901, skillName: "ワイドブレイカー", classification: "物理", damage: 60, skillType: 15, remarks: " 100%の確率で相手の『こうげき』ランクを1段階下げる        " } },
        { skillListId: 28035, skillId: 487, nationalNo: 149, skills: { skillId: 487, skillName: "つのドリル", classification: "物理", damage: 0, skillType: 1, remarks: " 相手を『ひんし』状態にする。相手のレベルが自分より高いと失敗する。(30＋自分のレベル−相手のレベル)が命中率になる。『ゴースト』タイプには無効だが、それ以外のタイプ相性の影響を受けない。ダイマックスしている相手には無効。        " } },
        { skillListId: 28036, skillId: 149, nationalNo: 149, skills: { skillId: 149, skillName: "かまいたち", classification: "特殊", damage: 80, skillType: 1, remarks: " 1ターン目は攻撃せずに、2ターン目で攻撃する。急所に当たりやすい(急所ランク:+1)。        " } },
        { skillListId: 28037, skillId: 48, nationalNo: 149, skills: { skillId: 48, skillName: "いあいぎり", classification: "物理", damage: 50, skillType: 1, remarks: " 通常攻撃。        " } },
        { skillListId: 28038, skillId: 831, nationalNo: 149, skills: { skillId: 831, skillName: "メガトンキック", classification: "物理", damage: 120, skillType: 1, remarks: " 通常攻撃。        " } },
        { skillListId: 28040, skillId: 131, nationalNo: 149, skills: { skillId: 131, skillName: "かいりき", classification: "物理", damage: 80, skillType: 1, remarks: " 通常攻撃。        " } },
        { skillListId: 28041, skillId: 576, nationalNo: 149, skills: { skillId: 576, skillName: "どろかけ", classification: "特殊", damage: 20, skillType: 9, remarks: " 100%の確率で相手の命中率を1段階下げる。        " } },
        { skillListId: 28042, skillId: 890, nationalNo: 149, skills: { skillId: 890, skillName: "れんぞくぎり", classification: "物理", damage: 40, skillType: 12, remarks: " 連続で使用すると攻撃が当たる度に威力が倍増していく(最大160)。攻撃が外れたり連続で使用しないと元に戻る。        " } },
        { skillListId: 28043, skillId: 633, nationalNo: 149, skills: { skillId: 633, skillName: "はがねのつばさ", classification: "物理", damage: 70, skillType: 17, remarks: " 10%の確率で自分の『ぼうぎょ』ランクが1段階上がる。        " } },
        { skillListId: 28044, skillId: 657, nationalNo: 149, skills: { skillId: 657, skillName: "ばくれつパンチ", classification: "物理", damage: 100, skillType: 7, remarks: " 100%の確率で相手を1〜4ターンの間『こんらん』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 28045, skillId: 73, nationalNo: 149, skills: { skillId: 73, skillName: "いわくだき", classification: "物理", damage: 40, skillType: 7, remarks: " 50%の確率で相手の『ぼうぎょ』ランクを1段階下げる。        " } },
        { skillListId: 28046, skillId: 174, nationalNo: 149, skills: { skillId: 174, skillName: "きあいパンチ", classification: "物理", damage: 150, skillType: 7, remarks: " 必ず後攻になり(優先度:-3)、攻撃するまでに技を受けると失敗してしまう。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 28047, skillId: 655, nationalNo: 149, skills: { skillId: 655, skillName: "ばかぢから", classification: "物理", damage: 120, skillType: 7, remarks: " 攻撃後、自分の『こうげき』『ぼうぎょ』ランクが1段階ずつ下がる。        " } },
        { skillListId: 28048, skillId: 451, nationalNo: 149, skills: { skillId: 451, skillName: "ダイビング", classification: "物理", damage: 80, skillType: 3, remarks: " 1ターン目に水中へ潜り、2ターン目に攻撃する。水中にいる間は『なみのり』『うずしお』以外の技を受けない。        " } },
        { skillListId: 28051, skillId: 710, nationalNo: 149, skills: { skillId: 710, skillName: "フリーフォール", classification: "物理", damage: 60, skillType: 10, remarks: " 1ターン目に相手とともに空中へ飛び上がり、2ターン目で攻撃する。空中にいる間は『うちおとす』『かぜおこし』『かみなり』『サウザンアロー』『スカイアッパー』『たつまき』『ぼうふう』以外の技を受けず、相手は行動できない。『ひこう』タイプのポケモンはダメージを受けない。相手が体重200kg以上の場合は失敗する。        " } },
        { skillListId: 28052, skillId: 250, nationalNo: 149, skills: { skillId: 250, skillName: "グロウパンチ", classification: "物理", damage: 40, skillType: 7, remarks: " 100%の確率で自分の『こうげき』ランクが1段階上がる。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 28054, skillId: 330, nationalNo: 149, skills: { skillId: 330, skillName: "しめつける", classification: "物理", damage: 15, skillType: 1, remarks: " 4〜5ターンの間、毎ターン終了後最大HPの1/8のダメージを与え、その間『ゴースト』タイプではない相手は逃げたり交代できない。        " } },
        { skillListId: 28055, skillId: 378, nationalNo: 149, skills: { skillId: 378, skillName: "スケイルショット", classification: "物理", damage: 25, skillType: 15, remarks: " 1ターンに2〜5回連続で攻撃する。その後、自分の『ぼうぎょ』ランクが1段階下がり、『すばやさ』ランクが1段階上がる。        " } },
        { skillListId: 28056, skillId: 38, nationalNo: 149, skills: { skillId: 38, skillName: "あやしいかぜ", classification: "特殊", damage: 60, skillType: 14, remarks: " 10%の確率で自分の『こうげき』『ぼうぎょ』『とくこう』『とくぼう』『すばやさ』ランクが1段階ずつ上がる。        " } },
        { skillListId: 28057, skillId: 463, nationalNo: 149, skills: { skillId: 463, skillName: "ダブルウイング", classification: "物理", damage: 40, skillType: 10, remarks: " 1ターンに2回連続で攻撃する。        " } }],
        skillList: ["ぼうふう",
          "ほのおのパンチ",
          "かみなりパンチ",
          "まきつく",
          "たつまき",
          "つばさでうつ",
          "しんそく",
          "ドラゴンテール",
          "こうそくいどう",
          "たたきつける",
          "アクアテール",
          "ドラゴンダイブ",
          "げきりん",
          "はかいこうせん",
          "とっしん",
          "こうそくいどう",
          "みずのはどう",
          "けたぐり",
          "ひやみず",
          "ほのおのうず",
          "からげんき",
          "つばめがえし",
          "じならし",
          "スピードスター",
          "こごえるかぜ",
          "がんせきふうじ",
          "エアカッター",
          "なげつける",
          "ドラゴンテール",
          "かわらわり",
          "エアスラッシュ",
          "のしかかり",
          "ほのおのパンチ",
          "かみなりパンチ",
          "れいとうパンチ",
          "ねごと",
          "たきのぼり",
          "ドラゴンクロー",
          "じだんだ",
          "いわなだれ",
          "ボディプレス",
          "そらをとぶ",
          "アイアンヘッド",
          "りゅうのはどう",
          "ねっぷう",
          "なみのり",
          "アイススピナー",
          "かえんほうしゃ",
          "10まんボルト",
          "れいとうビーム",
          "だいもんじ",
          "ハイドロポンプ",
          "ふぶき",
          "じしん",
          "ストーンエッジ",
          "ギガインパクト",
          "げきりん",
          "きあいだま",
          "ぼうふう",
          "はかいこうせん",
          "かみなり",
          "りゅうせいぐん",
          "テラバースト",
          "りゅうのいぶき",
          "しんそく",
          "みずのはどう",
          "アクアジェット",
          "りゅうのいかり",
          "ずつき",
          "すてみタックル",
          "みずでっぽう",
          "バブルこうせん"],
        ability: '-',
        abilities: [],
        abilityList: [],
        item: '-',
        teraStatus: false,
        teraType: { label: 'ノーマル', id: 1 },
        hitPoints: 198,
        attack: 154,
        defense: 147,
        specialAttack: 120,
        specialDefense: 121,
        speed: 100,
        effortHitPoints: 252,
        effortAttack: 0,
        effortDefense: 252,
        effortSpecialAttack: 0,
        effortSpecialDefense: 4,
        effortSpeed: 0
      },
      opponentMonster: {
        pokeName: 'カイリュー',
        nationalNo: 149,
        firstType: { label: 'ドラゴン', id: 15 },
        secondType: { label: 'ひこう', id: 10 },
        skill: 'しんそく',
        skillType: { label: 'ノーマル', id: 1 },
        classification: '物理',
        skillPower: 80,
        skills: [],
        skillList: [],
        ability: '-',
        abilities: [],
        abilityList: [],
        item: '-',
        teraStatus: false,
        teraType: { label: 'ノーマル', id: 1 },
        hitPoints: 198,
        attack: 154,
        defense: 147,
        specialAttack: 120,
        specialDefense: 121,
        speed: 100,
        effortHitPoints: 252,
        effortAttack: 0,
        effortDefense: 252,
        effortSpecialAttack: 0,
        effortSpecialDefense: 4,
        effortSpeed: 0
      },
      myMonsterFirst: {
        pokeName: 'カイリュー',
        nationalNo: 149,
        firstType: { label: 'ドラゴン', id: 15 },
        secondType: { label: 'ひこう', id: 10 },
        skill: 'しんそく',
        skillType: { label: 'ノーマル', id: 1 },
        classification: '物理',
        skillPower: 80,
        skills: [{ skillListId: 27911, skillId: 768, nationalNo: 149, skills: { skillId: 768, skillName: "ぼうふう", classification: "特殊", damage: 110, skillType: 10, remarks: " 30%の確率で、相手を2〜5ターンの間『こんらん』状態にする。天気が『あめ』の時は必ず命中し、『にほんばれ』の時は命中率が50%になる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中する。        " } },
        { skillListId: 27912, skillId: 761, nationalNo: 149, skills: { skillId: 761, skillName: "ほのおのパンチ", classification: "物理", damage: 75, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27913, skillId: 155, nationalNo: 149, skills: { skillId: 155, skillName: "かみなりパンチ", classification: "物理", damage: 75, skillType: 4, remarks: " 10%の確率で相手を『まひ』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27914, skillId: 646, nationalNo: 149, skills: { skillId: 646, skillName: "はねやすめ", classification: "変化", damage: 0, skillType: 10, remarks: " HPが最大HPの半分回復するが、そのターンは『ひこう』タイプではなくなる。        " } },
        { skillListId: 27915, skillId: 777, nationalNo: 149, skills: { skillId: 777, skillName: "まきつく", classification: "物理", damage: 15, skillType: 1, remarks: " 4〜5ターンの間、毎ターン終了後最大HPの1/8のダメージを与え、その間『ゴースト』タイプではない相手は逃げたり交代できない。        " } },
        { skillListId: 27916, skillId: 600, nationalNo: 149, skills: { skillId: 600, skillName: "にらみつける", classification: "変化", damage: 0, skillType: 1, remarks: " 相手の『ぼうぎょ』ランクを1段階下げる。        " } },
        { skillListId: 27917, skillId: 520, nationalNo: 149, skills: { skillId: 520, skillName: "でんじは", classification: "変化", damage: 0, skillType: 4, remarks: " 相手を『まひ』状態にする。『じめん』タイプや『でんき』タイプには無効。        " } },
        { skillListId: 27918, skillId: 424, nationalNo: 149, skills: { skillId: 424, skillName: "たつまき", classification: "特殊", damage: 40, skillType: 15, remarks: " 20%の確率で相手をひるませる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中し、ダメージが2倍になる。        " } },
        { skillListId: 27919, skillId: 488, nationalNo: 149, skills: { skillId: 488, skillName: "つばさでうつ", classification: "物理", damage: 60, skillType: 10, remarks: " 通常攻撃。        " } },
        { skillListId: 27920, skillId: 343, nationalNo: 149, skills: { skillId: 343, skillName: "しんそく", classification: "物理", damage: 80, skillType: 1, remarks: " 必ず先制できる(優先度:+2)。        " } },
        { skillListId: 27921, skillId: 568, nationalNo: 149, skills: { skillId: 568, skillName: "ドラゴンテール", classification: "物理", damage: 60, skillType: 15, remarks: " 必ず後攻になる(優先度:-6)。攻撃後、相手のポケモンを強制的に交代させる。野生のポケモンの場合は戦闘を終了する。ダイマックスしている相手には追加効果は無効。        " } },
        { skillListId: 27922, skillId: 261, nationalNo: 149, skills: { skillId: 261, skillName: "こうそくいどう", classification: "変化", damage: 0, skillType: 11, remarks: " 自分の『すばやさ』ランクが2段階上がる。        " } },
        { skillListId: 27923, skillId: 421, nationalNo: 149, skills: { skillId: 421, skillName: "たたきつける", classification: "物理", damage: 80, skillType: 1, remarks: " 通常攻撃。        " } },
        { skillListId: 27924, skillId: 14, nationalNo: 149, skills: { skillId: 14, skillName: "アクアテール", classification: "物理", damage: 90, skillType: 3, remarks: " 通常攻撃。        " } },
        { skillListId: 27925, skillId: 567, nationalNo: 149, skills: { skillId: 567, skillName: "ドラゴンダイブ", classification: "物理", damage: 100, skillType: 15, remarks: " 20%の確率で相手をひるませる。相手が『ちいさくなる』を使用していると必ず命中し、威力が2倍になる。        " } },
        { skillListId: 27926, skillId: 252, nationalNo: 149, skills: { skillId: 252, skillName: "げきりん", classification: "物理", damage: 120, skillType: 15, remarks: " 2〜3ターン連続で攻撃し、その後自分は1〜4ターンの間『こんらん』状態になる。        " } },
        { skillListId: 27927, skillId: 346, nationalNo: 149, skills: { skillId: 346, skillName: "しんぴのまもり", classification: "変化", damage: 0, skillType: 1, remarks: " 5ターンの間、自分と味方のポケモンは状態異常にならなくなる。交代しても効果は続く。        " } },
        { skillListId: 27928, skillId: 37, nationalNo: 149, skills: { skillId: 37, skillName: "あまごい", classification: "変化", damage: 0, skillType: 3, remarks: " 5ターンの間、天気を『あめ』にする。『みず』タイプの技のダメージが1.5倍になり、『ほのお』タイプの技のダメージが半減する。        " } },
        { skillListId: 27929, skillId: 881, nationalNo: 149, skills: { skillId: 881, skillName: "りゅうのまい", classification: "変化", damage: 0, skillType: 15, remarks: " 自分の『こうげき』『すばやさ』ランクを1段階ずつ上げる。        " } },
        { skillListId: 27930, skillId: 632, nationalNo: 149, skills: { skillId: 632, skillName: "はかいこうせん", classification: "特殊", damage: 150, skillType: 1, remarks: " 使用した次のターンは行動できない。        " } },
        { skillListId: 27931, skillId: 531, nationalNo: 149, skills: { skillId: 531, skillName: "とっしん", classification: "物理", damage: 90, skillType: 1, remarks: " 相手に与えたダメージの1/4を自分も受ける。        " } },
        { skillListId: 27932, skillId: 261, nationalNo: 149, skills: { skillId: 261, skillName: "こうそくいどう", classification: "変化", damage: 0, skillType: 11, remarks: " 自分の『すばやさ』ランクが2段階上がる。        " } },
        { skillListId: 27933, skillId: 279, nationalNo: 149, skills: { skillId: 279, skillName: "こわいかお", classification: "変化", damage: 0, skillType: 1, remarks: " 相手の『すばやさ』ランクを2段階下げる。        " } },
        { skillListId: 27934, skillId: 792, nationalNo: 149, skills: { skillId: 792, skillName: "まもる", classification: "変化", damage: 0, skillType: 1, remarks: " 必ず先制でき(優先度:+4)、そのターンの間、相手の技を受けない。連続で使うと失敗しやすくなる。ダイマックス技や第7世代のZワザの攻撃技は貫通し、1/4のダメージを受ける。        " } },
        { skillListId: 27935, skillId: 808, nationalNo: 149, skills: { skillId: 808, skillName: "みずのはどう", classification: "特殊", damage: 60, skillType: 3, remarks: " 20%の確率で相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 27936, skillId: 251, nationalNo: 149, skills: { skillId: 251, skillName: "けたぐり", classification: "物理", damage: 0, skillType: 7, remarks: " 相手の『おもさ』が重いほど威力が高くなる。10kg未満は20、25kg未満は40、50kg未満は60、100kg未満は80、200kg未満は100、それ以上は120。ダイマックスしている相手には無効。        " } },
        { skillListId: 27937, skillId: 681, nationalNo: 149, skills: { skillId: 681, skillName: "ひやみず", classification: "特殊", damage: 50, skillType: 3, remarks: " 100%の確率で相手の『こうげき』ランクを1段階下げる。        " } },
        { skillListId: 27938, skillId: 758, nationalNo: 149, skills: { skillId: 758, skillName: "ほのおのうず", classification: "特殊", damage: 35, skillType: 2, remarks: " 4〜5ターンの間、毎ターン終了後最大HPの1/8のダメージを与え、その間『ゴースト』タイプではない相手は逃げたり交代できない。        " } },
        { skillListId: 27939, skillId: 156, nationalNo: 149, skills: { skillId: 156, skillName: "からげんき", classification: "物理", damage: 70, skillType: 1, remarks: " 自分が『どく』『まひ』『やけど』状態の時、威力が2倍になる。『やけど』による『こうげき』の半減の影響を受けない。        " } },
        { skillListId: 27940, skillId: 489, nationalNo: 149, skills: { skillId: 489, skillName: "つばめがえし", classification: "物理", damage: 60, skillType: 10, remarks: " 自分の命中率、相手の回避率に関係なく必ず命中する。        " } },
        { skillListId: 27941, skillId: 358, nationalNo: 149, skills: { skillId: 358, skillName: "じならし", classification: "物理", damage: 60, skillType: 9, remarks: " 100%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 27942, skillId: 393, nationalNo: 149, skills: { skillId: 393, skillName: "スピードスター", classification: "特殊", damage: 60, skillType: 1, remarks: " 自分の命中率、相手の回避率に関係なく必ず命中する。        " } },
        { skillListId: 27943, skillId: 268, nationalNo: 149, skills: { skillId: 268, skillName: "こごえるかぜ", classification: "特殊", damage: 55, skillType: 6, remarks: " 100%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 27944, skillId: 170, nationalNo: 149, skills: { skillId: 170, skillName: "がんせきふうじ", classification: "物理", damage: 60, skillType: 13, remarks: " 100%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 27945, skillId: 90, nationalNo: 149, skills: { skillId: 90, skillName: "エアカッター", classification: "特殊", damage: 60, skillType: 10, remarks: " 急所に当たりやすい(急所ランク:+1)。        " } },
        { skillListId: 27946, skillId: 587, nationalNo: 149, skills: { skillId: 587, skillName: "なげつける", classification: "物理", damage: 0, skillType: 16, remarks: " 自分の持っている道具によって効果が変化する。投げつけた道具はなくなる。        " } },
        { skillListId: 27947, skillId: 568, nationalNo: 149, skills: { skillId: 568, skillName: "ドラゴンテール", classification: "物理", damage: 60, skillType: 15, remarks: " 必ず後攻になる(優先度:-6)。攻撃後、相手のポケモンを強制的に交代させる。野生のポケモンの場合は戦闘を終了する。ダイマックスしている相手には追加効果は無効。        " } },
        { skillListId: 27948, skillId: 277, nationalNo: 149, skills: { skillId: 277, skillName: "こらえる", classification: "変化", damage: 0, skillType: 1, remarks: " 必ず先制できる(優先度:+4)。そのターンに『ひんし』状態になる攻撃を受けてもHPが1残る。連続で使うと失敗しやすくなる。        " } },
        { skillListId: 27949, skillId: 599, nationalNo: 149, skills: { skillId: 599, skillName: "にほんばれ", classification: "変化", damage: 0, skillType: 2, remarks: " 5ターンの間、天気を『にほんばれ』にする。『ほのお』タイプの技のダメージが1.5倍になり、『みず』タイプの技のダメージが半減する。『こおり』状態にならない。        " } },
        { skillListId: 27950, skillId: 37, nationalNo: 149, skills: { skillId: 37, skillName: "あまごい", classification: "変化", damage: 0, skillType: 3, remarks: " 5ターンの間、天気を『あめ』にする。『みず』タイプの技のダメージが1.5倍になり、『ほのお』タイプの技のダメージが半減する。        " } },
        { skillListId: 27951, skillId: 388, nationalNo: 149, skills: { skillId: 388, skillName: "すなあらし", classification: "変化", damage: 0, skillType: 13, remarks: " 5ターンの間、天気を『すなあらし』にする。『いわ』『じめん』『はがね』タイプではないポケモンは、毎ターン最大HPの1/16のダメージを受ける。また、『いわ』タイプのポケモンは『とくぼう』が1.5倍になる。        " } },
        { skillListId: 27952, skillId: 854, nationalNo: 149, skills: { skillId: 854, skillName: "ゆきげしき", classification: "変化", damage: 0, skillType: 6, remarks: " 5ターンの間、天気を『ゆき』にする(『こおり』タイプのポケモンは、『ぼうぎょ』が1.5倍になる)。        " } },
        { skillListId: 27953, skillId: 162, nationalNo: 149, skills: { skillId: 162, skillName: "かわらわり", classification: "物理", damage: 75, skillType: 7, remarks: " 相手の技『リフレクター』『オーロラベール』の効果を受けず、相手の技『リフレクター』『ひかりのかべ』『オーロラベール』を壊す。        " } },
        { skillListId: 27954, skillId: 91, nationalNo: 149, skills: { skillId: 91, skillName: "エアスラッシュ", classification: "特殊", damage: 75, skillType: 10, remarks: " 30%の確率で相手をひるませる。        " } },
        { skillListId: 27955, skillId: 617, nationalNo: 149, skills: { skillId: 617, skillName: "のしかかり", classification: "物理", damage: 85, skillType: 1, remarks: " 30%の確率で相手を『まひ』状態にする。相手が技『ちいさくなる』を使用していると必ず命中し、威力が2倍になる。        " } },
        { skillListId: 27956, skillId: 761, nationalNo: 149, skills: { skillId: 761, skillName: "ほのおのパンチ", classification: "物理", damage: 75, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27957, skillId: 155, nationalNo: 149, skills: { skillId: 155, skillName: "かみなりパンチ", classification: "物理", damage: 75, skillType: 4, remarks: " 10%の確率で相手を『まひ』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27958, skillId: 887, nationalNo: 149, skills: { skillId: 887, skillName: "れいとうパンチ", classification: "物理", damage: 75, skillType: 6, remarks: " 10%の確率で相手を『こおり』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 27959, skillId: 605, nationalNo: 149, skills: { skillId: 605, skillName: "ねごと", classification: "変化", damage: 0, skillType: 1, remarks: " 自分が『ねむり』状態の時のみ使用可能。自分の持っている技のうち1つをランダムで使う。PPが減少するのはこの技のみ。        " } },
        { skillListId: 27960, skillId: 672, nationalNo: 149, skills: { skillId: 672, skillName: "ひかりのかべ", classification: "変化", damage: 0, skillType: 11, remarks: " 5ターンの間、相手の特殊攻撃のダメージを半分にする。味方が複数の場合は半分ではなく2/3になる。急所に当たった場合は軽減されない。交代しても効果は続く。        " } },
        { skillListId: 27961, skillId: 418, nationalNo: 149, skills: { skillId: 418, skillName: "たきのぼり", classification: "物理", damage: 80, skillType: 3, remarks: " 20%の確率で相手をひるませる。        " } },
        { skillListId: 27962, skillId: 566, nationalNo: 149, skills: { skillId: 566, skillName: "ドラゴンクロー", classification: "物理", damage: 80, skillType: 15, remarks: " 通常攻撃。        " } },
        { skillListId: 27963, skillId: 856, nationalNo: 149, skills: { skillId: 856, skillName: "ゆびをふる", classification: "変化", damage: 0, skillType: 1, remarks: " ほぼすべての技の中からランダムで1つ使う。        " } },
        { skillListId: 27964, skillId: 520, nationalNo: 149, skills: { skillId: 520, skillName: "でんじは", classification: "変化", damage: 0, skillType: 4, remarks: " 相手を『まひ』状態にする。『じめん』タイプや『でんき』タイプには無効。        " } },
        { skillListId: 27965, skillId: 357, nationalNo: 149, skills: { skillId: 357, skillName: "じだんだ", classification: "物理", damage: 75, skillType: 9, remarks: " 前のターンで自分が使った技が失敗していた場合、威力が2倍になる。        " } },
        { skillListId: 27966, skillId: 613, nationalNo: 149, skills: { skillId: 613, skillName: "ねむる", classification: "変化", damage: 0, skillType: 11, remarks: " HPと状態異常をすべて回復した後、2ターンの間『ねむり』状態になる。        " } },
        { skillListId: 27967, skillId: 74, nationalNo: 149, skills: { skillId: 74, skillName: "いわなだれ", classification: "物理", damage: 75, skillType: 13, remarks: " 30%の確率で相手をひるませる。        " } },
        { skillListId: 27968, skillId: 770, nationalNo: 149, skills: { skillId: 770, skillName: "ボディプレス", classification: "物理", damage: 80, skillType: 7, remarks: " 『こうげき』ではなく、自分の『ぼうぎょ』と『ぼうぎょ』ランクを『こうげき』の数値にしてダメージ計算する。        " } },
        { skillListId: 27969, skillId: 414, nationalNo: 149, skills: { skillId: 414, skillName: "そらをとぶ", classification: "物理", damage: 90, skillType: 10, remarks: " 1ターン目に空中へ飛び上がり、2ターン目に攻撃する。空中にいる間は『うちおとす』『かぜおこし』『かみなり』『サウザンアロー』『スカイアッパー』『たつまき』『ぼうふう』以外の技を受けない。        " } },
        { skillListId: 27970, skillId: 5, nationalNo: 149, skills: { skillId: 5, skillName: "アイアンヘッド", classification: "物理", damage: 80, skillType: 17, remarks: " 30%の確率で相手をひるませる。        " } },
        { skillListId: 27971, skillId: 881, nationalNo: 149, skills: { skillId: 881, skillName: "りゅうのまい", classification: "変化", damage: 0, skillType: 15, remarks: " 自分の『こうげき』『すばやさ』ランクを1段階ずつ上げる。        " } },
        { skillListId: 27972, skillId: 798, nationalNo: 149, skills: { skillId: 798, skillName: "みがわり", classification: "変化", damage: 0, skillType: 1, remarks: " 自分のHPを最大HPの1/4だけ減らして、減らしたHPと同じHPの自分の分身を作り、分身のHPが0になるまですべての攻撃を自分の代わりに分身が受ける。分身は状態異常にならない。ただし、音系の技などはそのまま受ける。相手のダイマックス技も防げるが、自分がダイマックスすると分身が消える。        " } },
        { skillListId: 27973, skillId: 108, nationalNo: 149, skills: { skillId: 108, skillName: "おいかぜ", classification: "変化", damage: 0, skillType: 10, remarks: " 4ターンの間、自分と味方の『すばやさ』が2倍になる。        " } },
        { skillListId: 27974, skillId: 880, nationalNo: 149, skills: { skillId: 880, skillName: "りゅうのはどう", classification: "特殊", damage: 85, skillType: 15, remarks: " 通常攻撃。        " } },
        { skillListId: 27975, skillId: 610, nationalNo: 149, skills: { skillId: 610, skillName: "ねっぷう", classification: "特殊", damage: 95, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。        " } },
        { skillListId: 27976, skillId: 46, nationalNo: 149, skills: { skillId: 46, skillName: "アンコール", classification: "変化", damage: 0, skillType: 1, remarks: " 3ターンの間、相手は最後に使用した技しか使えなくなる。ただし、PPが0になると解除される。ダイマックスしているポケモンには無効。なお、ダイマックス技や第7世代のZワザは影響を受けずに使うことができる。        " } },
        { skillListId: 27977, skillId: 591, nationalNo: 149, skills: { skillId: 591, skillName: "なみのり", classification: "特殊", damage: 90, skillType: 3, remarks: " 相手が『ダイビング』を使っている時でも命中し、ダメージが2倍になる。        " } },
        { skillListId: 27978, skillId: 7, nationalNo: 149, skills: { skillId: 7, skillName: "アイススピナー", classification: "物理", damage: 80, skillType: 6, remarks: " 場の状態『グラスフィールド』『ミストフィールド』『エレキフィールド』『サイコフィールド』を解除し、元に戻す。        " } },
        { skillListId: 27979, skillId: 135, nationalNo: 149, skills: { skillId: 135, skillName: "かえんほうしゃ", classification: "特殊", damage: 90, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。        " } },
        { skillListId: 27980, skillId: 366, nationalNo: 149, skills: { skillId: 366, skillName: "10まんボルト", classification: "特殊", damage: 90, skillType: 4, remarks: " 10%の確率で相手を『まひ』状態にする。        " } },
        { skillListId: 27981, skillId: 501, nationalNo: 149, skills: { skillId: 501, skillName: "てだすけ", classification: "変化", damage: 0, skillType: 1, remarks: " 必ず先制できる(優先度:+5)。使用したターンの間、味方の技の威力を1.5倍にする。        " } },
        { skillListId: 27982, skillId: 888, nationalNo: 149, skills: { skillId: 888, skillName: "れいとうビーム", classification: "特殊", damage: 90, skillType: 6, remarks: " 10%の確率で相手を『こおり』状態にする。        " } },
        { skillListId: 27983, skillId: 456, nationalNo: 149, skills: { skillId: 456, skillName: "だいもんじ", classification: "特殊", damage: 110, skillType: 2, remarks: " 10%の確率で相手を『やけど』状態にする。        " } },
        { skillListId: 27984, skillId: 627, nationalNo: 149, skills: { skillId: 627, skillName: "ハイドロポンプ", classification: "特殊", damage: 110, skillType: 3, remarks: " 通常攻撃。        " } },
        { skillListId: 27985, skillId: 701, nationalNo: 149, skills: { skillId: 701, skillName: "ふぶき", classification: "特殊", damage: 110, skillType: 6, remarks: " 10%の確率で相手を『こおり』状態にする。天気が『ゆき』の時は必ず命中する。        " } },
        { skillListId: 27986, skillId: 355, nationalNo: 149, skills: { skillId: 355, skillName: "じしん", classification: "物理", damage: 100, skillType: 9, remarks: " 相手が技『あなをほる』を使っている時でも命中し、ダメージが2倍になる。        " } },
        { skillListId: 27987, skillId: 386, nationalNo: 149, skills: { skillId: 386, skillName: "ストーンエッジ", classification: "物理", damage: 100, skillType: 13, remarks: " 急所に当たりやすい(急所ランク:+1)。        " } },
        { skillListId: 27988, skillId: 224, nationalNo: 149, skills: { skillId: 224, skillName: "ギガインパクト", classification: "物理", damage: 150, skillType: 1, remarks: " 使用した次のターンは行動できない。        " } },
        { skillListId: 27989, skillId: 252, nationalNo: 149, skills: { skillId: 252, skillName: "げきりん", classification: "物理", damage: 120, skillType: 15, remarks: " 2〜3ターン連続で攻撃し、その後自分は1〜4ターンの間『こんらん』状態になる。        " } },
        { skillListId: 27990, skillId: 172, nationalNo: 149, skills: { skillId: 172, skillName: "きあいだま", classification: "特殊", damage: 120, skillType: 7, remarks: " 10%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 27991, skillId: 768, nationalNo: 149, skills: { skillId: 768, skillName: "ぼうふう", classification: "特殊", damage: 110, skillType: 10, remarks: " 30%の確率で、相手を2〜5ターンの間『こんらん』状態にする。天気が『あめ』の時は必ず命中し、『にほんばれ』の時は命中率が50%になる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中する。        " } },
        { skillListId: 27992, skillId: 632, nationalNo: 149, skills: { skillId: 632, skillName: "はかいこうせん", classification: "特殊", damage: 150, skillType: 1, remarks: " 使用した次のターンは行動できない。        " } },
        { skillListId: 27993, skillId: 152, nationalNo: 149, skills: { skillId: 152, skillName: "かみなり", classification: "特殊", damage: 110, skillType: 4, remarks: " 30%の確率で相手を『まひ』状態にする。天気が『あめ』の時は必ず命中し、『にほんばれ』の時は命中率が50%になる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中する。        " } },
        { skillListId: 27994, skillId: 877, nationalNo: 149, skills: { skillId: 877, skillName: "りゅうせいぐん", classification: "特殊", damage: 130, skillType: 15, remarks: " 攻撃後、100%の確率で自分の『とくこう』ランクが2段階下がる。        " } },
        { skillListId: 27995, skillId: 504, nationalNo: 149, skills: { skillId: 504, skillName: "テラバースト", classification: "特殊", damage: 80, skillType: 1, remarks: " 自分がテラスタル状態の時、技のタイプが自分のテラスタイプになる。その際、この技の分類は、自分の攻撃と特攻の高い方となるため、『こうげき』のほうが高い場合は物理技となる。        " } },
        { skillListId: 27996, skillId: 475, nationalNo: 149, skills: { skillId: 475, skillName: "ちょうおんぱ", classification: "変化", damage: 0, skillType: 1, remarks: " 相手を1〜4ターンの間『こんらん』状態にする。音系の技。相手の『みがわり』状態を貫通する。        " } },
        { skillListId: 27997, skillId: 340, nationalNo: 149, skills: { skillId: 340, skillName: "しろいきり", classification: "変化", damage: 0, skillType: 6, remarks: " 5ターンの間、自分と味方のポケモンは能力ランクを下げられなくなる。交代しても効果は続く。        " } },
        { skillListId: 27998, skillId: 879, nationalNo: 149, skills: { skillId: 879, skillName: "りゅうのいぶき", classification: "特殊", damage: 60, skillType: 15, remarks: " 30%の確率で相手を『まひ』状態にする。        " } },
        { skillListId: 27999, skillId: 343, nationalNo: 149, skills: { skillId: 343, skillName: "しんそく", classification: "物理", damage: 80, skillType: 1, remarks: " 必ず先制できる(優先度:+2)。        " } },
        { skillListId: 28000, skillId: 808, nationalNo: 149, skills: { skillId: 808, skillName: "みずのはどう", classification: "特殊", damage: 60, skillType: 3, remarks: " 20%の確率で相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 28001, skillId: 12, nationalNo: 149, skills: { skillId: 12, skillName: "アクアジェット", classification: "物理", damage: 40, skillType: 3, remarks: " 必ず先制できる(優先度:+1)。        " } },
        { skillListId: 28002, skillId: 878, nationalNo: 149, skills: { skillId: 878, skillName: "りゅうのいかり", classification: "特殊", damage: 0, skillType: 15, remarks: " 40の固定ダメージを与える。『フェアリー』タイプには無効だが、それ以外のタイプ相性の影響を受けない。        " } },
        { skillListId: 28003, skillId: 401, nationalNo: 149, skills: { skillId: 401, skillName: "ずつき", classification: "物理", damage: 70, skillType: 1, remarks: " 30%の確率で相手をひるませる。        " } },
        { skillListId: 28004, skillId: 384, nationalNo: 149, skills: { skillId: 384, skillName: "すてみタックル", classification: "物理", damage: 120, skillType: 1, remarks: " 相手に与えたダメージの33%を自分も受ける。        " } },
        { skillListId: 28005, skillId: 806, nationalNo: 149, skills: { skillId: 806, skillName: "みずでっぽう", classification: "特殊", damage: 40, skillType: 3, remarks: " 通常攻撃。        " } },
        { skillListId: 28006, skillId: 660, nationalNo: 149, skills: { skillId: 660, skillName: "バブルこうせん", classification: "特殊", damage: 65, skillType: 3, remarks: " 10%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 28007, skillId: 554, nationalNo: 149, skills: { skillId: 554, skillName: "どくどく", classification: "変化", damage: 0, skillType: 8, remarks: " 相手を『もうどく』状態にする。『もうどく』状態は通常の『どく』状態と違い、受けるダメージが1/16、2/16、3/16・・・と増えていく。『どく』タイプのポケモンが使うと必ず命中する。『どく』タイプや『はがね』タイプには無効。        " } },
        { skillListId: 28008, skillId: 51, nationalNo: 149, skills: { skillId: 51, skillName: "いかり", classification: "物理", damage: 20, skillType: 1, remarks: " 自分を『いかり』状態にする。『いかり』状態の間、相手の技のダメージを受ける度に『こうげき』ランクが1段階上がる。別の技を使うと『いかり』状態が解除される(変化したランクはそのまま)。        " } },
        { skillListId: 28009, skillId: 846, nationalNo: 149, skills: { skillId: 846, skillName: "ものまね", classification: "変化", damage: 0, skillType: 1, remarks: " 一時的に、相手が最後に自分に使った技が使えるようになる。PPはコピーした技のポイントアップ未使用時の最大値となる。交代すると元に戻る。相手の技が第7世代のZワザの場合は失敗する。        " } },
        { skillListId: 28010, skillId: 142, nationalNo: 149, skills: { skillId: 142, skillName: "かげぶんしん", classification: "変化", damage: 0, skillType: 1, remarks: " 自分の回避率を1段階上げる。        " } },
        { skillListId: 28011, skillId: 874, nationalNo: 149, skills: { skillId: 874, skillName: "リフレクター", classification: "変化", damage: 0, skillType: 11, remarks: " 5ターンの間、相手の物理攻撃のダメージを半分にする。味方が複数の場合は半分ではなく2/3になる。急所に当たった場合は軽減されない。交代しても効果は続く。        " } },
        { skillListId: 28012, skillId: 166, nationalNo: 149, skills: { skillId: 166, skillName: "がまん", classification: "物理", damage: 0, skillType: 1, remarks: " 必ず先制できる(優先度:+1)。今後2ターンの間に受けたダメージの2倍を相手に与える。その間、全く行動できなくなる。『ゴースト』タイプには無効だが、それ以外のタイプ相性の影響を受けない。        " } },
        { skillListId: 28013, skillId: 893, nationalNo: 149, skills: { skillId: 893, skillName: "ロケットずつき", classification: "物理", damage: 130, skillType: 1, remarks: " 1ターン目に100%の確率で自分の『ぼうぎょ』ランクを1段階上げ、2ターン目で攻撃する。        " } },
        { skillListId: 28014, skillId: 67, nationalNo: 149, skills: { skillId: 67, skillName: "いびき", classification: "特殊", damage: 50, skillType: 1, remarks: " 自分が『ねむり』状態の時のみ使用可能。30%の確率で相手をひるませる。音系の技。相手の『みがわり』状態を貫通する。        " } },
        { skillListId: 28015, skillId: 619, nationalNo: 149, skills: { skillId: 619, skillName: "のろい", classification: "変化", damage: 0, skillType: 14, remarks: " 自分のタイプが『ゴースト』タイプの時は自分のHPを最大HPの半分だけ減らして、相手を『のろい』状態にする。『のろい』状態になると毎ターン、HPが最大HPの1/4ずつ減る。それ以外のポケモンの場合『すばやさ』ランクが1段階下がり、『こうげき』『ぼうぎょ』ランクが1段階ずつ上がる。        " } },
        { skillListId: 28016, skillId: 522, nationalNo: 149, skills: { skillId: 522, skillName: "でんじほう", classification: "特殊", damage: 120, skillType: 4, remarks: " 100%の確率で相手を『まひ』状態にする。        " } },
        { skillListId: 28017, skillId: 799, nationalNo: 149, skills: { skillId: 799, skillName: "みきり", classification: "変化", damage: 0, skillType: 7, remarks: " 必ず先制でき(優先度:+4)、そのターンの間、相手の技を受けない。連続で使うと失敗しやすくなる。ダイマックス技や第7世代のZワザの攻撃技は貫通し、1/4のダメージを受ける。        " } },
        { skillListId: 28018, skillId: 66, nationalNo: 149, skills: { skillId: 66, skillName: "いばる", classification: "変化", damage: 0, skillType: 1, remarks: " 相手を1〜4ターンの間『こんらん』状態にするが、相手の『こうげき』ランクを2段階上げてしまう。        " } },
        { skillListId: 28019, skillId: 843, nationalNo: 149, skills: { skillId: 843, skillName: "メロメロ", classification: "変化", damage: 0, skillType: 1, remarks: " 相手が自分とは異なる性別の場合、相手を『メロメロ』状態にする。『メロメロ』状態になると、50%の確率で相手は自分に攻撃できなくなる。自分と相手の性別が同じ時や、どちらかが性別不明の場合は失敗する。        " } },
        { skillListId: 28020, skillId: 126, nationalNo: 149, skills: { skillId: 126, skillName: "おんがえし", classification: "物理", damage: 0, skillType: 1, remarks: " ポケモンがなついているほど威力が高くなる。最大102。        " } },
        { skillListId: 28021, skillId: 850, nationalNo: 149, skills: { skillId: 850, skillName: "やつあたり", classification: "物理", damage: 0, skillType: 1, remarks: " ポケモンがなついていないほど威力が高くなる。最大102。        " } },
        { skillListId: 28022, skillId: 4, nationalNo: 149, skills: { skillId: 4, skillName: "アイアンテール", classification: "物理", damage: 100, skillType: 17, remarks: " 30%の確率で相手の『ぼうぎょ』ランクを1段階下げる。        " } },
        { skillListId: 28023, skillId: 837, nationalNo: 149, skills: { skillId: 837, skillName: "めざめるパワー", classification: "特殊", damage: 60, skillType: 1, remarks: " 自分の個体値によって『タイプ』が変わる。(BW2までは威力も個体値によって変化)        " } },
        { skillListId: 28024, skillId: 78, nationalNo: 149, skills: { skillId: 78, skillName: "うずしお", classification: "特殊", damage: 35, skillType: 3, remarks: " 4〜5ターンの間、毎ターン終了後最大HPの1/8のダメージを与え、その間『ゴースト』タイプではない相手は逃げたり交代できない。相手が『ダイビング』を使っている時でも命中し、ダメージが2倍になる。        " } },
        { skillListId: 28025, skillId: 40, nationalNo: 149, skills: { skillId: 40, skillName: "あられ", classification: "変化", damage: 0, skillType: 6, remarks: " 5ターンの間、天気を『あられ』にする。『こおり』タイプではないポケモンは、毎ターン最大HPの1/16のダメージを受ける。        " } },
        { skillListId: 28026, skillId: 679, nationalNo: 149, skills: { skillId: 679, skillName: "ひみつのちから", classification: "物理", damage: 70, skillType: 1, remarks: " 30%の確率で相手に以下の追加効果を与える。地形が草むらやグラスフィールドの時は『ねむり』状態、水上の時は『こうげき』ランクを1段階下げ、砂地の時は命中率を1段階下げ、岩場と洞窟の時はひるませ、雪原と氷上の時は『こおり』状態、火山の時は『やけど』状態、ミストフィールドの時は『とくこう』ランクを1段階下げ、サイコフィールドの時は『すばやさ』ランクを1段階下げ、ウルトラスペースの時は『ぼうぎょ』を1段階下げ、その他は『まひ』状態にする。また、ORASではフィールドで使うと秘密基地を作ることができる。        " } },
        { skillListId: 28027, skillId: 517, nationalNo: 149, skills: { skillId: 517, skillName: "でんげきは", classification: "特殊", damage: 60, skillType: 4, remarks: " 自分の命中率、相手の回避率に関係なく必ず命中する。        " } },
        { skillListId: 28028, skillId: 320, nationalNo: 149, skills: { skillId: 320, skillName: "しぜんのめぐみ", classification: "物理", damage: 0, skillType: 1, remarks: " 持っている『きのみ』によって『タイプ』と威力が変わる。『きのみ』はなくなる。        " } },
        { skillListId: 28029, skillId: 853, nationalNo: 149, skills: { skillId: 853, skillName: "ゆうわく", classification: "変化", damage: 0, skillType: 1, remarks: " 相手が自分とは異なる性別の場合、相手の『とくこう』を2段階下げる。自分と相手の性別が同じ時や、どちらかが性別不明の場合は失敗する。        " } },
        { skillListId: 28030, skillId: 883, nationalNo: 149, skills: { skillId: 883, skillName: "りんしょう", classification: "特殊", damage: 60, skillType: 1, remarks: " 同じターンに他のポケモンも『りんしょう』を使おうとすると、『すばやさ』に関係なく最初に使用したポケモンに続いて使用でき、最初以外の『りんしょう』は威力が2倍になる。音系の技。相手の『みがわり』状態を貫通する。(ダブルバトル用)        " } },
        { skillListId: 28031, skillId: 849, nationalNo: 149, skills: { skillId: 849, skillName: "やきつくす", classification: "特殊", damage: 60, skillType: 2, remarks: " 相手の持っている『きのみ』や『ノーマルジュエル』を使えなくする。        " } },
        { skillListId: 28032, skillId: 580, nationalNo: 149, skills: { skillId: 580, skillName: "ないしょばなし", classification: "変化", damage: 0, skillType: 1, remarks: " 相手の『とくこう』ランクを1段階下げる。自分の命中率、相手の回避率に関係なく必ず命中する。相手の『まもる』『みきり』『トーチカ』『ニードルガード』『ブロッキング』の効果を受けない(『ダイウォール』を除く)。音系の技。相手の『みがわり』状態を貫通する。        " } },
        { skillListId: 28033, skillId: 734, nationalNo: 149, skills: { skillId: 734, skillName: "ぶんまわす", classification: "物理", damage: 60, skillType: 16, remarks: " 通常攻撃。        " } },
        { skillListId: 28034, skillId: 901, nationalNo: 149, skills: { skillId: 901, skillName: "ワイドブレイカー", classification: "物理", damage: 60, skillType: 15, remarks: " 100%の確率で相手の『こうげき』ランクを1段階下げる        " } },
        { skillListId: 28035, skillId: 487, nationalNo: 149, skills: { skillId: 487, skillName: "つのドリル", classification: "物理", damage: 0, skillType: 1, remarks: " 相手を『ひんし』状態にする。相手のレベルが自分より高いと失敗する。(30＋自分のレベル−相手のレベル)が命中率になる。『ゴースト』タイプには無効だが、それ以外のタイプ相性の影響を受けない。ダイマックスしている相手には無効。        " } },
        { skillListId: 28036, skillId: 149, nationalNo: 149, skills: { skillId: 149, skillName: "かまいたち", classification: "特殊", damage: 80, skillType: 1, remarks: " 1ターン目は攻撃せずに、2ターン目で攻撃する。急所に当たりやすい(急所ランク:+1)。        " } },
        { skillListId: 28037, skillId: 48, nationalNo: 149, skills: { skillId: 48, skillName: "いあいぎり", classification: "物理", damage: 50, skillType: 1, remarks: " 通常攻撃。        " } },
        { skillListId: 28038, skillId: 831, nationalNo: 149, skills: { skillId: 831, skillName: "メガトンキック", classification: "物理", damage: 120, skillType: 1, remarks: " 通常攻撃。        " } },
        { skillListId: 28039, skillId: 750, nationalNo: 149, skills: { skillId: 750, skillName: "ほえる", classification: "変化", damage: 0, skillType: 1, remarks: " 必ず後攻になる(優先度:-6)。相手のポケモンを強制的に交代させる。野生のポケモンの場合は戦闘を終了する。自分の命中率、相手の回避率に関係なく必ず命中する。相手の『まもる』『みきり』『トーチカ』『ニードルガード』『ブロッキング』の効果を受けない。音系の技。相手の『みがわり』状態を貫通する。ダイマックスしている相手には無効。野生のポケモンの場合、相手が自分のレベルより高い場合は失敗する。        " } },
        { skillListId: 28040, skillId: 131, nationalNo: 149, skills: { skillId: 131, skillName: "かいりき", classification: "物理", damage: 80, skillType: 1, remarks: " 通常攻撃。        " } },
        { skillListId: 28041, skillId: 576, nationalNo: 149, skills: { skillId: 576, skillName: "どろかけ", classification: "特殊", damage: 20, skillType: 9, remarks: " 100%の確率で相手の命中率を1段階下げる。        " } },
        { skillListId: 28042, skillId: 890, nationalNo: 149, skills: { skillId: 890, skillName: "れんぞくぎり", classification: "物理", damage: 40, skillType: 12, remarks: " 連続で使用すると攻撃が当たる度に威力が倍増していく(最大160)。攻撃が外れたり連続で使用しないと元に戻る。        " } },
        { skillListId: 28043, skillId: 633, nationalNo: 149, skills: { skillId: 633, skillName: "はがねのつばさ", classification: "物理", damage: 70, skillType: 17, remarks: " 10%の確率で自分の『ぼうぎょ』ランクが1段階上がる。        " } },
        { skillListId: 28044, skillId: 657, nationalNo: 149, skills: { skillId: 657, skillName: "ばくれつパンチ", classification: "物理", damage: 100, skillType: 7, remarks: " 100%の確率で相手を1〜4ターンの間『こんらん』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 28045, skillId: 73, nationalNo: 149, skills: { skillId: 73, skillName: "いわくだき", classification: "物理", damage: 40, skillType: 7, remarks: " 50%の確率で相手の『ぼうぎょ』ランクを1段階下げる。        " } },
        { skillListId: 28046, skillId: 174, nationalNo: 149, skills: { skillId: 174, skillName: "きあいパンチ", classification: "物理", damage: 150, skillType: 7, remarks: " 必ず後攻になり(優先度:-3)、攻撃するまでに技を受けると失敗してしまう。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 28047, skillId: 655, nationalNo: 149, skills: { skillId: 655, skillName: "ばかぢから", classification: "物理", damage: 120, skillType: 7, remarks: " 攻撃後、自分の『こうげき』『ぼうぎょ』ランクが1段階ずつ下がる。        " } },
        { skillListId: 28048, skillId: 451, nationalNo: 149, skills: { skillId: 451, skillName: "ダイビング", classification: "物理", damage: 80, skillType: 3, remarks: " 1ターン目に水中へ潜り、2ターン目に攻撃する。水中にいる間は『なみのり』『うずしお』以外の技を受けない。        " } },
        { skillListId: 28049, skillId: 218, nationalNo: 149, skills: { skillId: 218, skillName: "きりばらい", classification: "変化", damage: 0, skillType: 10, remarks: " 相手の回避率を1段階下げる。相手の場の『しろいきり』『ひかりのかべ』『リフレクター』『しんぴのまもり』『オーロラベール』、両方の場の『まきびし』『どくびし』『ステルスロック』『ねばねばネット』、さらに第8世代からは『エレキフィールド』『グラスフィールド』『サイコフィールド』『ミストフィールド』の効果を解除する。相手が『みがわり』状態の場合、回避率を下げる効果のみ失敗する。        " } },
        { skillListId: 28050, skillId: 492, nationalNo: 149, skills: { skillId: 492, skillName: "つめとぎ", classification: "変化", damage: 0, skillType: 16, remarks: " 自分の『こうげき』『命中率』ランクが1段階ずつ上がる。        " } },
        { skillListId: 28051, skillId: 710, nationalNo: 149, skills: { skillId: 710, skillName: "フリーフォール", classification: "物理", damage: 60, skillType: 10, remarks: " 1ターン目に相手とともに空中へ飛び上がり、2ターン目で攻撃する。空中にいる間は『うちおとす』『かぜおこし』『かみなり』『サウザンアロー』『スカイアッパー』『たつまき』『ぼうふう』以外の技を受けず、相手は行動できない。『ひこう』タイプのポケモンはダメージを受けない。相手が体重200kg以上の場合は失敗する。        " } },
        { skillListId: 28052, skillId: 250, nationalNo: 149, skills: { skillId: 250, skillName: "グロウパンチ", classification: "物理", damage: 40, skillType: 7, remarks: " 100%の確率で自分の『こうげき』ランクが1段階上がる。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 28053, skillId: 239, nationalNo: 149, skills: { skillId: 239, skillName: "くろいきり", classification: "変化", damage: 0, skillType: 6, remarks: " すべてのポケモンの能力ランクの変化を元に戻す。        " } },
        { skillListId: 28054, skillId: 330, nationalNo: 149, skills: { skillId: 330, skillName: "しめつける", classification: "物理", damage: 15, skillType: 1, remarks: " 4〜5ターンの間、毎ターン終了後最大HPの1/8のダメージを与え、その間『ゴースト』タイプではない相手は逃げたり交代できない。        " } },
        { skillListId: 28055, skillId: 378, nationalNo: 149, skills: { skillId: 378, skillName: "スケイルショット", classification: "物理", damage: 25, skillType: 15, remarks: " 1ターンに2〜5回連続で攻撃する。その後、自分の『ぼうぎょ』ランクが1段階下がり、『すばやさ』ランクが1段階上がる。        " } },
        { skillListId: 28056, skillId: 38, nationalNo: 149, skills: { skillId: 38, skillName: "あやしいかぜ", classification: "特殊", damage: 60, skillType: 14, remarks: " 10%の確率で自分の『こうげき』『ぼうぎょ』『とくこう』『とくぼう』『すばやさ』ランクが1段階ずつ上がる。        " } },
        { skillListId: 28057, skillId: 463, nationalNo: 149, skills: { skillId: 463, skillName: "ダブルウイング", classification: "物理", damage: 40, skillType: 10, remarks: " 1ターンに2回連続で攻撃する。        " } },
        { skillListId: 28058, skillId: 68, nationalNo: 149, skills: { skillId: 68, skillName: "いやしのすず", classification: "変化", damage: 0, skillType: 1, remarks: " 自分と味方全体のポケモンの状態異常を治す。音系の技。味方が『みがわり』状態でも効果が発生する。この技は『ダイウォール』の効果も受けない。        " } },
        { skillListId: 28059, skillId: 661, nationalNo: 149, skills: { skillId: 661, skillName: "バリアー", classification: "変化", damage: 0, skillType: 11, remarks: " 自分の『ぼうぎょ』ランクを2段階上げる。        " } }],
        skillList: ["ぼうふう",
          "ほのおのパンチ",
          "かみなりパンチ",
          "はねやすめ",
          "まきつく",
          "にらみつける",
          "でんじは",
          "たつまき",
          "つばさでうつ",
          "しんそく",
          "ドラゴンテール",
          "こうそくいどう",
          "たたきつける",
          "アクアテール",
          "ドラゴンダイブ",
          "げきりん",
          "しんぴのまもり",
          "あまごい",
          "りゅうのまい",
          "はかいこうせん",
          "とっしん",
          "こうそくいどう",
          "こわいかお",
          "まもる",
          "みずのはどう",
          "けたぐり",
          "ひやみず",
          "ほのおのうず",
          "からげんき",
          "つばめがえし",
          "じならし",
          "スピードスター",
          "こごえるかぜ",
          "がんせきふうじ",
          "エアカッター",
          "なげつける",
          "ドラゴンテール",
          "こらえる",
          "にほんばれ",
          "あまごい",
          "すなあらし",
          "ゆきげしき",
          "かわらわり",
          "エアスラッシュ",
          "のしかかり",
          "ほのおのパンチ",
          "かみなりパンチ",
          "れいとうパンチ",
          "ねごと",
          "ひかりのかべ",
          "たきのぼり",
          "ドラゴンクロー",
          "ゆびをふる",
          "でんじは",
          "じだんだ",
          "ねむる",
          "いわなだれ",
          "ボディプレス",
          "そらをとぶ",
          "アイアンヘッド",
          "りゅうのまい",
          "みがわり",
          "おいかぜ",
          "りゅうのはどう",
          "ねっぷう",
          "アンコール",
          "なみのり",
          "アイススピナー",
          "かえんほうしゃ",
          "10まんボルト",
          "てだすけ",
          "れいとうビーム",
          "だいもんじ",
          "ハイドロポンプ",
          "ふぶき",
          "じしん",
          "ストーンエッジ",
          "ギガインパクト",
          "げきりん",
          "きあいだま",
          "ぼうふう",
          "はかいこうせん",
          "かみなり",
          "りゅうせいぐん",
          "テラバースト",
          "ちょうおんぱ",
          "しろいきり",
          "りゅうのいぶき",
          "しんそく",
          "みずのはどう",
          "アクアジェット",
          "りゅうのいかり",
          "ずつき",
          "すてみタックル",
          "みずでっぽう",
          "バブルこうせん",
          "どくどく",
          "いかり",
          "ものまね",
          "かげぶんしん"],
        ability: '-',
        abilities: [],
        abilityList: [],
        item: '-',
        teraType: { label: 'ノーマル', id: 1 },
        hitPoints: 91,
        attack: 134,
        defense: 95,
        specialAttack: 100,
        specialDefense: 100,
        speed: 80,
        effortHitPoints: 252,
        effortAttack: 0,
        effortDefense: 252,
        effortSpecialAttack: 0,
        effortSpecialDefense: 4,
        effortSpeed: 0
      },
      myMonsterSecond: {
        pokeName: 'ハバタクカミ',
        nationalNo: 987,
        firstType: { label: 'ゴースト', id: 14 },
        secondType: { label: 'フェアリー', id: 18 },
        skill: 'ムーンフォース',
        skillType: { label: 'フェアリー', id: 18 },
        classification: '特殊',
        skillPower: 95,
        skills: [{ skillListId: 29766, skillId: 39, nationalNo: 987, skills: { skillId: 39, skillName: "あやしいひかり", classification: "変化", damage: 0, skillType: 14, remarks: "相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 29767, skillId: 87, nationalNo: 987, skills: { skillId: 87, skillName: "うらみ", classification: "変化", damage: 0, skillType: 14, remarks: "相手の最後に使った技のPPを4減らす。        " } },
        { skillListId: 29768, skillId: 121, nationalNo: 987, skills: { skillId: 121, skillName: "おどろかす", classification: "物理", damage: 30, skillType: 14, remarks: "30%の確率で相手をひるませる。        " } },
        { skillListId: 29769, skillId: 285, nationalNo: 987, skills: { skillId: 285, skillName: "サイケこうせん", classification: "特殊", damage: 65, skillType: 11, remarks: "10%の確率で相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 29770, skillId: 240, nationalNo: 987, skills: { skillId: 240, skillName: "くろいまなざし", classification: "変化", damage: 0, skillType: 1, remarks: "『ゴースト』タイプではない相手は逃げたり、交代できなくなる。自分が場を離れると効果は消える。        " } },
        { skillListId: 29771, skillId: 113, nationalNo: 987, skills: { skillId: 113, skillName: "おきみやげ", classification: "変化", damage: 0, skillType: 16, remarks: "自分が『ひんし』になる代わりに、相手の『こうげき』『とくこう』ランクを2段階ずつ下げる。        " } },
        { skillListId: 29772, skillId: 601, nationalNo: 987, skills: { skillId: 601, skillName: "ねがいごと", classification: "変化", damage: 0, skillType: 1, remarks: "次のターンの終了時にHPが最大HPの半分回復する。交代した場合は同じ位置にいるポケモンが回復する。        " } },
        { skillListId: 29773, skillId: 782, nationalNo: 987, skills: { skillId: 782, skillName: "マジカルシャイン", classification: "特殊", damage: 80, skillType: 18, remarks: "通常攻撃。        " } },
        { skillListId: 29774, skillId: 336, nationalNo: 987, skills: { skillId: 336, skillName: "シャドーボール", classification: "特殊", damage: 80, skillType: 14, remarks: "20%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 29775, skillId: 783, nationalNo: 987, skills: { skillId: 783, skillName: "マジカルフレイム", classification: "特殊", damage: 75, skillType: 2, remarks: "100%の確率で相手の『とくこう』ランクを1段階下げる。        " } },
        { skillListId: 29776, skillId: 668, nationalNo: 987, skills: { skillId: 668, skillName: "パワージェム", classification: "特殊", damage: 80, skillType: 13, remarks: "通常攻撃。        " } },
        { skillListId: 29777, skillId: 290, nationalNo: 987, skills: { skillId: 290, skillName: "サイコショック", classification: "特殊", damage: 80, skillType: 11, remarks: "相手の『とくぼう』ではなく、相手の『ぼうぎょ』の能力値でダメージ計算する。        " } },
        { skillListId: 29778, skillId: 281, nationalNo: 987, skills: { skillId: 281, skillName: "ゴーストダイブ", classification: "物理", damage: 90, skillType: 14, remarks: "1ターン目に消えて、2ターン目に攻撃する。消えている間はほとんどの技を受けない。相手の『まもる』『みきり』『たたみがえし』『トーチカ』『キングシールド』『ニードルガード』『ブロッキング』『スレッドトラップ』の効果を受けない(『ダイウォール』を除く)。(なお、第7世代以降は相手が『ちいさくなる』を使っている時の必中2倍効果はない)        " } },
        { skillListId: 29779, skillId: 57, nationalNo: 987, skills: { skillId: 57, skillName: "いたみわけ", classification: "変化", damage: 0, skillType: 1, remarks: "自分と相手のHPを2匹の残りHPの平均値にする(足して2で割る)。ダイマックス時は、もとのHPで平均値を計算して、もとのHPと平均値との差分値を現在のダイマックス時のHPから増減する。        " } },
        { skillListId: 29780, skillId: 822, nationalNo: 987, skills: { skillId: 822, skillName: "ムーンフォース", classification: "特殊", damage: 95, skillType: 18, remarks: "30%の確率で相手の『とくこう』ランクを1段階下げる。        " } },
        { skillListId: 29781, skillId: 764, nationalNo: 987, skills: { skillId: 764, skillName: "ほろびのうた", classification: "変化", damage: 0, skillType: 1, remarks: "3ターン終了後に、この技を使った時に出ていた自分を含むすべてのポケモンは『ひんし』状態になる。それまでに交代したポケモンは効果が消える。音系の技。相手の『みがわり』状態を貫通する。        " } },
        { skillListId: 29782, skillId: 36, nationalNo: 987, skills: { skillId: 36, skillName: "あまえる", classification: "変化", damage: 0, skillType: 18, remarks: "相手の『こうげき』ランクを2段階下げる。        " } },
        { skillListId: 29783, skillId: 79, nationalNo: 987, skills: { skillId: 79, skillName: "うそなき", classification: "変化", damage: 0, skillType: 16, remarks: "相手の『とくぼう』ランクを2段階下げる。        " } },
        { skillListId: 29784, skillId: 792, nationalNo: 987, skills: { skillId: 792, skillName: "まもる", classification: "変化", damage: 0, skillType: 1, remarks: "必ず先制でき(優先度:+4)、そのターンの間、相手の技を受けない。連続で使うと失敗しやすくなる。ダイマックス技や第7世代のZワザの攻撃技は貫通し、1/4のダメージを受ける。        " } },
        { skillListId: 29785, skillId: 285, nationalNo: 987, skills: { skillId: 285, skillName: "サイケこうせん", classification: "特殊", damage: 65, skillType: 11, remarks: "10%の確率で相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 29786, skillId: 39, nationalNo: 987, skills: { skillId: 39, skillName: "あやしいひかり", classification: "変化", damage: 0, skillType: 14, remarks: "相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 29787, skillId: 474, nationalNo: 987, skills: { skillId: 474, skillName: "チャームボイス", classification: "特殊", damage: 40, skillType: 18, remarks: "自分の命中率、相手の回避率に関係なく必ず命中する。音系の技。相手の『みがわり』状態を貫通する。        " } },
        { skillListId: 29788, skillId: 473, nationalNo: 987, skills: { skillId: 473, skillName: "チャージビーム", classification: "特殊", damage: 50, skillType: 4, remarks: "70%の確率で自分の『とくこう』ランクが1段階上がる。        " } },
        { skillListId: 29789, skillId: 423, nationalNo: 987, skills: { skillId: 423, skillName: "たたりめ", classification: "特殊", damage: 65, skillType: 14, remarks: "相手が状態異常の時、威力が2倍になる。        " } },
        { skillListId: 29790, skillId: 393, nationalNo: 987, skills: { skillId: 393, skillName: "スピードスター", classification: "特殊", damage: 60, skillType: 1, remarks: "自分の命中率、相手の回避率に関係なく必ず命中する。        " } },
        { skillListId: 29791, skillId: 784, nationalNo: 987, skills: { skillId: 784, skillName: "マジカルリーフ", classification: "特殊", damage: 60, skillType: 5, remarks: "自分の命中率、相手の回避率に関係なく必ず命中する。        " } },
        { skillListId: 29792, skillId: 268, nationalNo: 987, skills: { skillId: 268, skillName: "こごえるかぜ", classification: "特殊", damage: 55, skillType: 6, remarks: "100%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 29793, skillId: 573, nationalNo: 987, skills: { skillId: 573, skillName: "ドレインキッス", classification: "特殊", damage: 50, skillType: 18, remarks: "相手に与えたダメージの3/4だけ自分のHPが回復する。        " } },
        { skillListId: 29794, skillId: 27, nationalNo: 987, skills: { skillId: 27, skillName: "アシストパワー", classification: "特殊", damage: 20, skillType: 11, remarks: "自分のいずれかの能力ランクが1つ上がる度に威力が20上がる。        " } },
        { skillListId: 29795, skillId: 582, nationalNo: 987, skills: { skillId: 582, skillName: "ナイトヘッド", classification: "特殊", damage: 0, skillType: 14, remarks: "自分のレベルと同じ値の固定ダメージを与える。『ノーマル』タイプには無効だが、それ以外のタイプ相性の影響を受けない。        " } },
        { skillListId: 29796, skillId: 277, nationalNo: 987, skills: { skillId: 277, skillName: "こらえる", classification: "変化", damage: 0, skillType: 1, remarks: "必ず先制できる(優先度:+4)。そのターンに『ひんし』状態になる攻撃を受けてもHPが1残る。連続で使うと失敗しやすくなる。        " } },
        { skillListId: 29797, skillId: 599, nationalNo: 987, skills: { skillId: 599, skillName: "にほんばれ", classification: "変化", damage: 0, skillType: 2, remarks: "5ターンの間、天気を『にほんばれ』にする。『ほのお』タイプの技のダメージが1.5倍になり、『みず』タイプの技のダメージが半減する。『こおり』状態にならない。        " } },
        { skillListId: 29798, skillId: 290, nationalNo: 987, skills: { skillId: 290, skillName: "サイコショック", classification: "特殊", damage: 80, skillType: 11, remarks: "相手の『とくぼう』ではなく、相手の『ぼうぎょ』の能力値でダメージ計算する。        " } },
        { skillListId: 29799, skillId: 605, nationalNo: 987, skills: { skillId: 605, skillName: "ねごと", classification: "変化", damage: 0, skillType: 1, remarks: "自分が『ねむり』状態の時のみ使用可能。自分の持っている技のうち1つをランダムで使う。PPが減少するのはこの技のみ。        " } },
        { skillListId: 29800, skillId: 782, nationalNo: 987, skills: { skillId: 782, skillName: "マジカルシャイン", classification: "特殊", damage: 80, skillType: 18, remarks: "通常攻撃。        " } },
        { skillListId: 29801, skillId: 520, nationalNo: 987, skills: { skillId: 520, skillName: "でんじは", classification: "変化", damage: 0, skillType: 4, remarks: "相手を『まひ』状態にする。『じめん』タイプや『でんき』タイプには無効。        " } },
        { skillListId: 29802, skillId: 613, nationalNo: 987, skills: { skillId: 613, skillName: "ねむる", classification: "変化", damage: 0, skillType: 11, remarks: "HPと状態異常をすべて回復した後、2ターンの間『ねむり』状態になる。        " } },
        { skillListId: 29803, skillId: 478, nationalNo: 987, skills: { skillId: 478, skillName: "ちょうはつ", classification: "変化", damage: 0, skillType: 16, remarks: "3ターンの間、相手は攻撃技しか出せなくなる。(変化技を使えなくする)        " } },
        { skillListId: 29804, skillId: 692, nationalNo: 987, skills: { skillId: 692, skillName: "ふういん", classification: "変化", damage: 0, skillType: 11, remarks: "自分が場を離れるまで、自分が覚えている技と同じ技を相手のポケモンは使えなくなる。        " } },
        { skillListId: 29805, skillId: 20, nationalNo: 987, skills: { skillId: 20, skillName: "あくのはどう", classification: "特殊", damage: 80, skillType: 16, remarks: "20%の確率で相手をひるませる。        " } },
        { skillListId: 29806, skillId: 668, nationalNo: 987, skills: { skillId: 668, skillName: "パワージェム", classification: "特殊", damage: 80, skillType: 13, remarks: "通常攻撃。        " } },
        { skillListId: 29807, skillId: 798, nationalNo: 987, skills: { skillId: 798, skillName: "みがわり", classification: "変化", damage: 0, skillType: 1, remarks: "自分のHPを最大HPの1/4だけ減らして、減らしたHPと同じHPの自分の分身を作り、分身のHPが0になるまですべての攻撃を自分の代わりに分身が受ける。分身は状態異常にならない。ただし、音系の技などはそのまま受ける。相手のダイマックス技も防げるが、自分がダイマックスすると分身が消える。        " } },
        { skillListId: 29808, skillId: 336, nationalNo: 987, skills: { skillId: 336, skillName: "シャドーボール", classification: "特殊", damage: 80, skillType: 14, remarks: "20%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 29809, skillId: 630, nationalNo: 987, skills: { skillId: 630, skillName: "ハイパーボイス", classification: "特殊", damage: 90, skillType: 1, remarks: "音系の技。相手の『みがわり』状態を貫通する。        " } },
        { skillListId: 29810, skillId: 95, nationalNo: 987, skills: { skillId: 95, skillName: "エナジーボール", classification: "特殊", damage: 90, skillType: 5, remarks: "10%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 29811, skillId: 366, nationalNo: 987, skills: { skillId: 366, skillName: "10まんボルト", classification: "特殊", damage: 90, skillType: 4, remarks: "10%の確率で相手を『まひ』状態にする。        " } },
        { skillListId: 29812, skillId: 830, nationalNo: 987, skills: { skillId: 830, skillName: "めいそう", classification: "変化", damage: 0, skillType: 11, remarks: "自分の『とくこう』『とくぼう』ランクが1段階ずつ上がる。        " } },
        { skillListId: 29813, skillId: 501, nationalNo: 987, skills: { skillId: 501, skillName: "てだすけ", classification: "変化", damage: 0, skillType: 1, remarks: "必ず先制できる(優先度:+5)。使用したターンの間、味方の技の威力を1.5倍にする。        " } },
        { skillListId: 29814, skillId: 802, nationalNo: 987, skills: { skillId: 802, skillName: "ミストフィールド", classification: "変化", damage: 0, skillType: 18, remarks: "5ターンの間、場の状態を『ミストフィールド』にする。『ひこう』タイプや特性『ふゆう』などではない地面にいるすべてのポケモンは、状態異常にならず、また『ドラゴン』タイプの技の受けるダメージが半減する。すでに状態異常の場合は回復しない。道具『ミストシード』を持ったポケモンは『とくぼう』ランクが1段階上がる。        " } },
        { skillListId: 29815, skillId: 281, nationalNo: 987, skills: { skillId: 281, skillName: "ゴーストダイブ", classification: "物理", damage: 90, skillType: 14, remarks: "1ターン目に消えて、2ターン目に攻撃する。消えている間はほとんどの技を受けない。相手の『まもる』『みきり』『たたみがえし』『トーチカ』『キングシールド』『ニードルガード』『ブロッキング』『スレッドトラップ』の効果を受けない(『ダイウォール』を除く)。(なお、第7世代以降は相手が『ちいさくなる』を使っている時の必中2倍効果はない)        " } },
        { skillListId: 29816, skillId: 224, nationalNo: 987, skills: { skillId: 224, skillName: "ギガインパクト", classification: "物理", damage: 150, skillType: 1, remarks: "使用した次のターンは行動できない。        " } },
        { skillListId: 29817, skillId: 546, nationalNo: 987, skills: { skillId: 546, skillName: "トリックルーム", classification: "変化", damage: 0, skillType: 11, remarks: "5ターンの間、『すばやさ』が低いポケモンから攻撃できるようになる。ただし、必ず先制できる技には無効。もう1度使用すると元に戻る。必ず後攻になる(優先度:-7)。        " } },
        { skillListId: 29818, skillId: 632, nationalNo: 987, skills: { skillId: 632, skillName: "はかいこうせん", classification: "特殊", damage: 150, skillType: 1, remarks: "使用した次のターンは行動できない。        " } },
        { skillListId: 29819, skillId: 152, nationalNo: 987, skills: { skillId: 152, skillName: "かみなり", classification: "特殊", damage: 110, skillType: 4, remarks: "30%の確率で相手を『まひ』状態にする。天気が『あめ』の時は必ず命中し、『にほんばれ』の時は命中率が50%になる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中する。        " } },
        { skillListId: 29820, skillId: 504, nationalNo: 987, skills: { skillId: 504, skillName: "テラバースト", classification: "特殊", damage: 80, skillType: 1, remarks: "自分がテラスタル状態の時、技のタイプが自分のテラスタイプになる。その際、この技の分類は、自分の攻撃と特攻の高い方となるため、『こうげき』のほうが高い場合は物理技となる。        " } },
        { skillListId: 29821, skillId: 599, nationalNo: 987, skills: { skillId: 599, skillName: "にほんばれ", classification: "変化", damage: 0, skillType: 2, remarks: "5ターンの間、天気を『にほんばれ』にする。『ほのお』タイプの技のダメージが1.5倍になり、『みず』タイプの技のダメージが半減する。『こおり』状態にならない。        " } }],
        skillList: ["あやしいひかり",
          "うらみ",
          "おどろかす",
          "サイケこうせん",
          "くろいまなざし",
          "おきみやげ",
          "ねがいごと",
          "マジカルシャイン",
          "シャドーボール",
          "マジカルフレイム",
          "パワージェム",
          "サイコショック",
          "ゴーストダイブ",
          "いたみわけ",
          "ムーンフォース",
          "ほろびのうた",
          "あまえる",
          "うそなき",
          "まもる",
          "サイケこうせん",
          "あやしいひかり",
          "チャームボイス",
          "チャージビーム",
          "たたりめ",
          "スピードスター",
          "マジカルリーフ",
          "こごえるかぜ",
          "ドレインキッス",
          "アシストパワー",
          "ナイトヘッド",
          "こらえる",
          "にほんばれ",
          "サイコショック",
          "ねごと",
          "マジカルシャイン",
          "でんじは",
          "ねむる",
          "ちょうはつ",
          "ふういん",
          "あくのはどう",
          "パワージェム",
          "みがわり",
          "シャドーボール",
          "ハイパーボイス",
          "エナジーボール",
          "10まんボルト",
          "めいそう",
          "てだすけ",
          "ミストフィールド",
          "ゴーストダイブ",
          "ギガインパクト",
          "トリックルーム",
          "はかいこうせん",
          "かみなり",
          "テラバースト",
          "にほんばれ"
        ],
        ability: '-',
        abilities: [],
        abilityList: [],
        item: '-',
        teraType: { label: 'ゴースト', id: 14 },
        hitPoints: 55,
        attack: 55,
        defense: 55,
        specialAttack: 135,
        specialDefense: 135,
        speed: 135,
        effortHitPoints: 0,
        effortAttack: 0,
        effortDefense: 0,
        effortSpecialAttack: 0,
        effortSpecialDefense: 0,
        effortSpeed: 0
      },
      myMonsterThird: {
        pokeName: 'サーフゴー',
        pokeNo: 1000,
        firstType: { label: 'はがね', id: 17 },
        secondType: { label: 'ゴースト', id: 14 },
        skill: 'シャドーボール',
        skillType: 18,
        classification: '特殊',
        skillPower: 95,
        skills: [{ skillListId: 30220, skillId: 416, nationalNo: 1000, skills: { skillId: 416, skillName: "たいあたり", classification: "物理", damage: 40, skillType: 1, remarks: "通常攻撃。        " } },
        { skillListId: 30221, skillId: 121, nationalNo: 1000, skills: { skillId: 121, skillName: "おどろかす", classification: "物理", damage: 30, skillType: 14, remarks: "30%の確率で相手をひるませる。        " } },
        { skillListId: 30222, skillId: 582, nationalNo: 1000, skills: { skillId: 582, skillName: "ナイトヘッド", classification: "特殊", damage: 0, skillType: 14, remarks: "自分のレベルと同じ値の固定ダメージを与える。『ノーマル』タイプには無効だが、それ以外のタイプ相性の影響を受けない。        " } },
        { skillListId: 30223, skillId: 39, nationalNo: 1000, skills: { skillId: 39, skillName: "あやしいひかり", classification: "変化", damage: 0, skillType: 14, remarks: "相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 30224, skillId: 798, nationalNo: 1000, skills: { skillId: 798, skillName: "みがわり", classification: "変化", damage: 0, skillType: 1, remarks: "自分のHPを最大HPの1/4だけ減らして、減らしたHPと同じHPの自分の分身を作り、分身のHPが0になるまですべての攻撃を自分の代わりに分身が受ける。分身は状態異常にならない。ただし、音系の技などはそのまま受ける。相手のダイマックス技も防げるが、自分がダイマックスすると分身が消える。        " } },
        { skillListId: 30225, skillId: 221, nationalNo: 1000, skills: { skillId: 221, skillName: "きんぞくおん", classification: "変化", damage: 0, skillType: 17, remarks: "音系の技。相手の『とくぼう』ランクを2段階下げる。        " } },
        { skillListId: 30226, skillId: 336, nationalNo: 1000, skills: { skillId: 336, skillName: "シャドーボール", classification: "特殊", damage: 80, skillType: 14, remarks: "20%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 30227, skillId: 352, nationalNo: 1000, skills: { skillId: 352, skillName: "じこさいせい", classification: "変化", damage: 0, skillType: 1, remarks: "HPが最大HPの半分回復する。        " } },
        { skillListId: 30228, skillId: 668, nationalNo: 1000, skills: { skillId: 668, skillName: "パワージェム", classification: "特殊", damage: 80, skillType: 13, remarks: "通常攻撃。        " } },
        { skillListId: 30229, skillId: 282, nationalNo: 1000, skills: { skillId: 282, skillName: "ゴールドラッシュ", classification: "特殊", damage: 120, skillType: 17, remarks: "攻撃後、自分の『とくこう』ランクが1段階下がる。また、戦闘終了後にお金が(自分のレベル×攻撃回数×5)円多く手に入る。        " } },
        { skillListId: 30230, skillId: 906, nationalNo: 1000, skills: { skillId: 906, skillName: "わるだくみ", classification: "変化", damage: 0, skillType: 16, remarks: "自分の『とくこう』ランクを2段階上げる。        " } },
        { skillListId: 30231, skillId: 113, nationalNo: 1000, skills: { skillId: 113, skillName: "おきみやげ", classification: "変化", damage: 0, skillType: 16, remarks: "自分が『ひんし』になる代わりに、相手の『こうげき』『とくこう』ランクを2段階ずつ下げる。        " } },
        { skillListId: 30232, skillId: 531, nationalNo: 1000, skills: { skillId: 531, skillName: "とっしん", classification: "物理", damage: 90, skillType: 1, remarks: "相手に与えたダメージの1/4を自分も受ける。        " } },
        { skillListId: 30233, skillId: 792, nationalNo: 1000, skills: { skillId: 792, skillName: "まもる", classification: "変化", damage: 0, skillType: 1, remarks: "必ず先制でき(優先度:+4)、そのターンの間、相手の技を受けない。連続で使うと失敗しやすくなる。ダイマックス技や第7世代のZワザの攻撃技は貫通し、1/4のダメージを受ける。        " } },
        { skillListId: 30234, skillId: 251, nationalNo: 1000, skills: { skillId: 251, skillName: "けたぐり", classification: "物理", damage: 0, skillType: 7, remarks: "相手の『おもさ』が重いほど威力が高くなる。10kg未満は20、25kg未満は40、50kg未満は60、100kg未満は80、200kg未満は100、それ以上は120。ダイマックスしている相手には無効。        " } },
        { skillListId: 30235, skillId: 39, nationalNo: 1000, skills: { skillId: 39, skillName: "あやしいひかり", classification: "変化", damage: 0, skillType: 14, remarks: "相手を1〜4ターンの間『こんらん』状態にする。        " } },
        { skillListId: 30236, skillId: 578, nationalNo: 1000, skills: { skillId: 578, skillName: "どろぼう", classification: "物理", damage: 60, skillType: 16, remarks: "相手が持っている道具を自分の物にする。自分が既に道具を持っている場合は失敗するが、技『はたきおとす』で自分の道具が無効化されている時は奪う事ができ、道具は上書きされる。トレーナー戦の場合はバトル終了後になくなる。        " } },
        { skillListId: 30237, skillId: 473, nationalNo: 1000, skills: { skillId: 473, skillName: "チャージビーム", classification: "特殊", damage: 50, skillType: 4, remarks: "70%の確率で自分の『とくこう』ランクが1段階上がる。        " } },
        { skillListId: 30238, skillId: 423, nationalNo: 1000, skills: { skillId: 423, skillName: "たたりめ", classification: "特殊", damage: 65, skillType: 14, remarks: "相手が状態異常の時、威力が2倍になる。        " } },
        { skillListId: 30239, skillId: 892, nationalNo: 1000, skills: { skillId: 892, skillName: "ローキック", classification: "物理", damage: 65, skillType: 7, remarks: "100%の確率で相手の『すばやさ』ランクを1段階下げる。        " } },
        { skillListId: 30240, skillId: 582, nationalNo: 1000, skills: { skillId: 582, skillName: "ナイトヘッド", classification: "特殊", damage: 0, skillType: 14, remarks: "自分のレベルと同じ値の固定ダメージを与える。『ノーマル』タイプには無効だが、それ以外のタイプ相性の影響を受けない。        " } },
        { skillListId: 30241, skillId: 587, nationalNo: 1000, skills: { skillId: 587, skillName: "なげつける", classification: "物理", damage: 0, skillType: 16, remarks: "自分の持っている道具によって効果が変化する。投げつけた道具はなくなる。        " } },
        { skillListId: 30242, skillId: 277, nationalNo: 1000, skills: { skillId: 277, skillName: "こらえる", classification: "変化", damage: 0, skillType: 1, remarks: "必ず先制できる(優先度:+4)。そのターンに『ひんし』状態になる攻撃を受けてもHPが1残る。連続で使うと失敗しやすくなる。        " } },
        { skillListId: 30243, skillId: 388, nationalNo: 1000, skills: { skillId: 388, skillName: "すなあらし", classification: "変化", damage: 0, skillType: 13, remarks: "5ターンの間、天気を『すなあらし』にする。『いわ』『じめん』『はがね』タイプではないポケモンは、毎ターン最大HPの1/16のダメージを受ける。また、『いわ』タイプのポケモンは『とくぼう』が1.5倍になる。        " } },
        { skillListId: 30244, skillId: 290, nationalNo: 1000, skills: { skillId: 290, skillName: "サイコショック", classification: "特殊", damage: 80, skillType: 11, remarks: "相手の『とくぼう』ではなく、相手の『ぼうぎょ』の能力値でダメージ計算する。        " } },
        { skillListId: 30245, skillId: 155, nationalNo: 1000, skills: { skillId: 155, skillName: "かみなりパンチ", classification: "物理", damage: 75, skillType: 4, remarks: "10%の確率で相手を『まひ』状態にする。特性『てつのこぶし』の時、威力が1.2倍になる。        " } },
        { skillListId: 30246, skillId: 605, nationalNo: 1000, skills: { skillId: 605, skillName: "ねごと", classification: "変化", damage: 0, skillType: 1, remarks: "自分が『ねむり』状態の時のみ使用可能。自分の持っている技のうち1つをランダムで使う。PPが減少するのはこの技のみ。        " } },
        { skillListId: 30247, skillId: 99, nationalNo: 1000, skills: { skillId: 99, skillName: "エレキボール", classification: "特殊", damage: 0, skillType: 4, remarks: "相手より『すばやさ』が高いほど威力が高くなる。1倍未満は40、2倍未満は60、3倍未満は80、4倍未満は120、それ以上は150。        " } },
        { skillListId: 30248, skillId: 874, nationalNo: 1000, skills: { skillId: 874, skillName: "リフレクター", classification: "変化", damage: 0, skillType: 11, remarks: "5ターンの間、相手の物理攻撃のダメージを半分にする。味方が複数の場合は半分ではなく2/3になる。急所に当たった場合は軽減されない。交代しても効果は続く。        " } },
        { skillListId: 30249, skillId: 672, nationalNo: 1000, skills: { skillId: 672, skillName: "ひかりのかべ", classification: "変化", damage: 0, skillType: 11, remarks: "5ターンの間、相手の特殊攻撃のダメージを半分にする。味方が複数の場合は半分ではなく2/3になる。急所に当たった場合は軽減されない。交代しても効果は続く。        " } },
        { skillListId: 30250, skillId: 782, nationalNo: 1000, skills: { skillId: 782, skillName: "マジカルシャイン", classification: "特殊", damage: 80, skillType: 18, remarks: "通常攻撃。        " } },
        { skillListId: 30251, skillId: 520, nationalNo: 1000, skills: { skillId: 520, skillName: "でんじは", classification: "変化", damage: 0, skillType: 4, remarks: "相手を『まひ』状態にする。『じめん』タイプや『でんき』タイプには無効。        " } },
        { skillListId: 30252, skillId: 613, nationalNo: 1000, skills: { skillId: 613, skillName: "ねむる", classification: "変化", damage: 0, skillType: 11, remarks: "HPと状態異常をすべて回復した後、2ターンの間『ねむり』状態になる。        " } },
        { skillListId: 30253, skillId: 868, nationalNo: 1000, skills: { skillId: 868, skillName: "ラスターカノン", classification: "特殊", damage: 80, skillType: 17, remarks: "10%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 30254, skillId: 5, nationalNo: 1000, skills: { skillId: 5, skillName: "アイアンヘッド", classification: "物理", damage: 80, skillType: 17, remarks: "30%の確率で相手をひるませる。        " } },
        { skillListId: 30255, skillId: 668, nationalNo: 1000, skills: { skillId: 668, skillName: "パワージェム", classification: "特殊", damage: 80, skillType: 13, remarks: "通常攻撃。        " } },
        { skillListId: 30256, skillId: 798, nationalNo: 1000, skills: { skillId: 798, skillName: "みがわり", classification: "変化", damage: 0, skillType: 1, remarks: "自分のHPを最大HPの1/4だけ減らして、減らしたHPと同じHPの自分の分身を作り、分身のHPが0になるまですべての攻撃を自分の代わりに分身が受ける。分身は状態異常にならない。ただし、音系の技などはそのまま受ける。相手のダイマックス技も防げるが、自分がダイマックスすると分身が消える。        " } },
        { skillListId: 30257, skillId: 543, nationalNo: 1000, skills: { skillId: 543, skillName: "トリック", classification: "変化", damage: 0, skillType: 11, remarks: "相手と自分が持っている道具を入れ替える。片方しか道具を持っていない場合も成功する。        " } },
        { skillListId: 30258, skillId: 336, nationalNo: 1000, skills: { skillId: 336, skillName: "シャドーボール", classification: "特殊", damage: 80, skillType: 14, remarks: "20%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 30259, skillId: 288, nationalNo: 1000, skills: { skillId: 288, skillName: "サイコキネシス", classification: "特殊", damage: 90, skillType: 11, remarks: "10%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 30260, skillId: 742, nationalNo: 1000, skills: { skillId: 742, skillName: "ヘビーボンバー", classification: "物理", damage: 0, skillType: 17, remarks: "自分の『おもさ』が相手より重いほど威力が高くなる。相手の『おもさ』が自分の1/5以下なら120、1/4以下なら100、1/3以下なら80、1/2以下なら60、それより大きければ40。相手が技『ちいさくなる』を使用していると必ず命中し、威力が2倍になる(第7世代以降)。ダイマックスしている相手には無効。        " } },
        { skillListId: 30261, skillId: 366, nationalNo: 1000, skills: { skillId: 366, skillName: "10まんボルト", classification: "特殊", damage: 90, skillType: 4, remarks: "10%の確率で相手を『まひ』状態にする。        " } },
        { skillListId: 30262, skillId: 906, nationalNo: 1000, skills: { skillId: 906, skillName: "わるだくみ", classification: "変化", damage: 0, skillType: 16, remarks: "自分の『とくこう』ランクを2段階上げる。        " } },
        { skillListId: 30263, skillId: 224, nationalNo: 1000, skills: { skillId: 224, skillName: "ギガインパクト", classification: "物理", damage: 150, skillType: 1, remarks: "使用した次のターンは行動できない。        " } },
        { skillListId: 30264, skillId: 172, nationalNo: 1000, skills: { skillId: 172, skillName: "きあいだま", classification: "特殊", damage: 120, skillType: 7, remarks: "10%の確率で相手の『とくぼう』ランクを1段階下げる。        " } },
        { skillListId: 30265, skillId: 632, nationalNo: 1000, skills: { skillId: 632, skillName: "はかいこうせん", classification: "特殊", damage: 150, skillType: 1, remarks: "使用した次のターンは行動できない。        " } },
        { skillListId: 30266, skillId: 152, nationalNo: 1000, skills: { skillId: 152, skillName: "かみなり", classification: "特殊", damage: 110, skillType: 4, remarks: "30%の確率で相手を『まひ』状態にする。天気が『あめ』の時は必ず命中し、『にほんばれ』の時は命中率が50%になる。相手が技『そらをとぶ』『とびはねる』『フリーフォール』を使っている時でも命中する。        " } },
        { skillListId: 30267, skillId: 502, nationalNo: 1000, skills: { skillId: 502, skillName: "てっていこうせん", classification: "特殊", damage: 140, skillType: 17, remarks: "自分のHPを最大HPの半分だけ減らす。『まもる』などで技が防がれた場合も、自分のHPは減る。        " } },
        { skillListId: 30268, skillId: 504, nationalNo: 1000, skills: { skillId: 504, skillName: "テラバースト", classification: "特殊", damage: 80, skillType: 1, remarks: "自分がテラスタル状態の時、技のタイプが自分のテラスタイプになる。その際、この技の分類は、自分の攻撃と特攻の高い方となるため、『こうげき』のほうが高い場合は物理技となる。        " } }
        ],
        skillList: ["たいあたり",
          "おどろかす",
          "ナイトヘッド",
          "あやしいひかり",
          "みがわり",
          "きんぞくおん",
          "シャドーボール",
          "じこさいせい",
          "パワージェム",
          "ゴールドラッシュ",
          "わるだくみ",
          "おきみやげ",
          "とっしん",
          "まもる",
          "けたぐり",
          "あやしいひかり",
          "どろぼう",
          "チャージビーム",
          "たたりめ",
          "ローキック",
          "ナイトヘッド",
          "なげつける",
          "こらえる",
          "すなあらし",
          "サイコショック",
          "かみなりパンチ",
          "ねごと",
          "エレキボール",
          "リフレクター",
          "ひかりのかべ",
          "マジカルシャイン",
          "でんじは",
          "ねむる",
          "ラスターカノン",
          "アイアンヘッド",
          "パワージェム",
          "みがわり",
          "トリック",
          "シャドーボール",
          "サイコキネシス",
          "ヘビーボンバー",
          "10まんボルト",
          "わるだくみ",
          "ギガインパクト",
          "きあいだま",
          "はかいこうせん",
          "かみなり",
          "てっていこうせん",
          "テラバースト",
          "ゴーストダイブ",
          "ギガインパクト",
          "トリックルーム",
          "はかいこうせん",
          "かみなり",
          "テラバースト",
          "にほんばれ"
        ],
        ability: '-',
        abilities: [],
        abilityList: [],
        item: '-',
        teraType: { label: 'ゴースト', id: 14 },
        hitPoints: 87,
        attack: 60,
        defense: 95,
        specialAttack: 133,
        specialDefense: 91,
        speed: 84,
        effortHitPoints: 0,
        effortAttack: 0,
        effortDefense: 0,
        effortSpecialAttack: 0,
        effortSpecialDefense: 0,
        effortSpeed: 0
      },
      opponentMonsterFirst: {
        pokeName: 'カイリュー',
        nationalNo: 149,
        firstType: { label: 'ドラゴン', id: 15 },
        secondType: { label: 'ひこう', id: 10 },
        skill: 'しんそく',
        skillType: { label: 'ノーマル', id: 1 },
        classification: '物理',
        skillPower: 80,
        skills: [],
        skillList: [],
        ability: '-',
        abilities: [],
        abilityList: [],
        item: '-',
        teraType: { label: 'ノーマル', id: 1 },
        hitPoints: 91,
        attack: 134,
        defense: 95,
        specialAttack: 100,
        specialDefense: 100,
        speed: 80,
        effortHitPoints: 252,
        effortAttack: 0,
        effortDefense: 252,
        effortSpecialAttack: 0,
        effortSpecialDefense: 4,
        effortSpeed: 0
      },
      opponentMonsterSecond: {
        pokeName: 'ハバタクカミ',
        nationalNo: 987,
        firstType: { label: 'ゴースト', id: 14 },
        secondType: { label: 'フェアリー', id: 18 },
        skill: 'ムーンフォース',
        skillType: { label: 'フェアリー', id: 18 },
        classification: '特殊',
        skillPower: 95,
        skillList: [],
        ability: '-',
        abilities: [],
        abilityList: [],
        item: '-',
        teraType: { label: 'ゴースト', id: 14 },
        hitPoints: 55,
        attack: 55,
        defense: 55,
        specialAttack: 135,
        specialDefense: 135,
        speed: 135,
        effortHitPoints: 0,
        effortAttack: 0,
        effortDefense: 0,
        effortSpecialAttack: 0,
        effortSpecialDefense: 0,
        effortSpeed: 0
      },
      opponentMonsterThird: {
        pokeName: 'サーフゴー',
        nationalNo: 1000,
        firstType: { label: 'はがね', id: 17 },
        secondType: { label: 'ゴースト', id: 14 },
        skill: 'シャドーボール',
        skillType: 18,
        classification: '特殊',
        skillPower: 95,
        skillList: [],
        ability: '-',
        abilities: [],
        abilityList: [],
        item: '-',
        teraType: { label: 'ゴースト', id: 14 },
        hitPoints: 87,
        attack: 60,
        defense: 95,
        specialAttack: 133,
        specialDefense: 91,
        speed: 84,
        effortHitPoints: 0,
        effortAttack: 0,
        effortDefense: 0,
        effortSpecialAttack: 0,
        effortSpecialDefense: 0,
        effortSpeed: 0
      },
      typeList: [
        { label: '', id: 0 },
        { label: 'ノーマル', id: 1 },
        { label: 'ほのお', id: 2 },
        { label: 'みず', id: 3 },
        { label: 'でんき', id: 4 },
        { label: 'くさ', id: 5 },
        { label: 'こおり', id: 6 },
        { label: 'かくとう', id: 7 },
        { label: 'どく', id: 8 },
        { label: 'じめん', id: 9 },
        { label: 'ひこう', id: 10 },
        { label: 'エスパー', id: 11 },
        { label: 'むし', id: 12 },
        { label: 'いわ', id: 13 },
        { label: 'ゴースト', id: 14 },
        { label: 'ドラゴン', id: 15 },
        { label: 'あく', id: 16 },
        { label: 'はがね', id: 17 },
        { label: 'フェアリー', id: 18 },
      ],
    }
  },
  mounted() {
    this.fetchPokeList();
  },
  computed: {
    damageResultMin() {
      return resultMin(this.myMonster, this.opponentMonster, this.typeCompatibilities,
        this.attackRank, this.defenseRank, this.burnStatus, this.wallStatus);
    },
    damageResultMax() {
      return resultMax(this.myMonster, this.opponentMonster, this.typeCompatibilities,
        this.attackRank, this.defenseRank, this.burnStatus, this.wallStatus);
    },
    attackCount() {
      return attackCount(this.damageResultMax, this.damageResultMin, this.opponentMonster);
    }
  },
  watch: {
    //攻撃ランクのバリデーション
    attackRank: function (newValue) {
      if (newValue < -6) {
        //攻撃ランクの最小値
        this.attackRank = -6
      } else if (newValue > 6) {
        //攻撃ランクの最大値
        this.attackRank = 6
      }
    },
    //防御ランクのバリデーション
    defenseRank: function (newValue) {
      if (newValue < -6) {
        //防御ランクの最小値
        this.defenseRank = -6
      } else if (newValue > 6) {
        //防御ランクの最大値
        this.defenseRank = 6
      }
    },
    //現在選択されている相手モンスター
    opponentMonsterCursor: function (newMonster) {
      this.opponentMonsterCousorChange(newMonster)
    },
    //左側１番目の相手モンスター選択時
    opponentMonsterFirst: {
      handler: function () {
        if (this.opponentMonsterCursor == 1) {
          this.opponentMonsterCousorChange(1)
        }
      },
      deep: true
    },
    //左側２番目の相手モンスター選択時
    opponentMonsterSecond: {
      handler: function () {
        if (this.opponentMonsterCursor == 2) {
          this.opponentMonsterCousorChange(2)
        }
      },
      deep: true
    },
    //左側３番目の相手モンスター選択時
    opponentMonsterThird: {
      handler: function () {
        if (this.opponentMonsterCursor == 3) {
          this.opponentMonsterCousorChange(3)
        }
      },
      deep: true
    },
    //現在選択されている自分モンスター
    myMonsterCursor: function (newMonster, oldMonster) {
      if (oldMonster == 4) {
        this.myMonsterFirst.skill = this.myMonster.skill;
        this.myMonsterFirst.skillPower = this.myMonster.skillPower;
        this.myMonsterFirst.classification = this.myMonster.classification;
        this.myMonsterFirst.skillType = this.myMonster.skillType;
      }
      if (oldMonster == 5) {
        this.myMonsterSecond.skill = this.myMonster.skill;
        this.myMonsterSecond.skillPower = this.myMonster.skillPower;
        this.myMonsterSecond.classification = this.myMonster.classification;
        this.myMonsterFirst.skillType = this.myMonster.skillType;
      }
      if (oldMonster == 6) {
        this.myMonsterThird.skill = this.myMonster.skill;
        this.myMonsterThird.skillPower = this.myMonster.skillPower;
        this.myMonsterThird.classification = this.myMonster.classification;
        this.myMonsterFirst.skillType = this.myMonster.skillType;
      }
      this.myMonsterCousorChange(newMonster)
    },
    //左側１番目の自分モンスター選択時
    myMonsterFirst: {
      handler: function () {
        if (this.myMonsterCursor == 4) {
          this.myMonsterCousorChange(4)
        }
      },
      deep: true
    },
    //左側２番目の自分モンスター選択時
    myMonsterSecond: {
      handler: function () {
        if (this.myMonsterCursor == 5) {
          this.myMonsterCousorChange(5)
        }
      },
      deep: true
    },
    //左側３番目の自分モンスター選択時
    myMonsterThird: {
      handler: function () {
        if (this.myMonsterCursor == 6) {
          this.myMonsterCousorChange(6)
        }
      },
      deep: true
    },
    //自分のモンスターの技が変更された場合
    'myMonster.skill': function (newSkill) {
      var updateSkill = this.myMonster.skills.find(skillList => skillList.skills.skillName === newSkill);
      this.myMonster.skillPower = updateSkill.skills.damage;
      this.myMonster.classification = updateSkill.skills.classification;
      this.myMonster.skillType = this.typeList.find(list => list.id === updateSkill.skills.skillType);
    },
    //最小ダメージに変更があった場合this.damageBarMinに反映
    damageResultMin: function (newValue) {
      let damagePercentageMax = 1.00;
      let damagePercentageMin = 1.00;
      damagePercentageMax = (100 - (this.damageResultMax / this.opponentMonster.hitPoints * 100));
      damagePercentageMin = (100 - (newValue / this.opponentMonster.hitPoints * 100));
      if (Math.sign(damagePercentageMin) === -1) {
        this.damageBarMin = '0%';
      } else if (Math.sign(damagePercentageMax) === -1) {
        this.damageBarMin = damagePercentageMin.toString() + '%';
      } else {
        this.damageBarMin = (damagePercentageMin - damagePercentageMax).toString() + '%';
      }
    },
    //最大ダメージに変更があった場合this.damageBarMaxに反映
    damageResultMax: function (newValue) {
      let damagePercentage = 1.00;
      damagePercentage = 100 - (newValue / this.opponentMonster.hitPoints * 100);
      this.damageBarMax = damagePercentage.toString() + '%';
    },
  },
  methods: {
    opponentMonsterCousorChange(newMonsterCousor) {
      this.opponentMonsterCursor = newMonsterCousor;
      this.opponentMonsterStatusChange(newMonsterCousor);
    },
    opponentMonsterStatusChange(newMonsterCousor) {
      if (newMonsterCousor == 1) {
        this.opponentMonster.pokeName = this.opponentMonsterFirst.pokeName;
        this.opponentMonster.nationalNo = this.opponentMonsterFirst.nationalNo;
        this.opponentMonster.skill = this.opponentMonsterFirst.skill;
        this.opponentMonster.ability = this.opponentMonsterFirst.ability;
        this.opponentMonster.abilityList = this.opponentMonsterFirst.abilityList;
        this.opponentMonster.item = this.opponentMonsterFirst.item;
        this.opponentMonster.teraType = this.opponentMonsterFirst.teraType;
        this.opponentMonster.firstType = this.opponentMonsterFirst.firstType;
        this.opponentMonster.secondType = this.opponentMonsterFirst.secondType;
        this.opponentMonster.hitPoints = this.realValueHitPoints(this.opponentMonsterFirst.hitPoints, this.opponentMonsterFirst.effortHitPoints);
        this.opponentMonster.attack = this.realValueAttack(this.opponentMonsterFirst.attack, this.opponentMonsterFirst.effortAttack);
        this.opponentMonster.defense = this.realValueDefense(this.opponentMonsterFirst.defense, this.opponentMonsterFirst.effortDefense);
        this.opponentMonster.specialAttack = this.realValueSpecialAttack(this.opponentMonsterFirst.specialAttack, this.opponentMonsterFirst.effortSpecialAttack);
        this.opponentMonster.specialDefense = this.realValueSpecialDefense(this.opponentMonsterFirst.specialDefense, this.opponentMonsterFirst.effortSpecialDefense);
        this.opponentMonster.speed = this.realValueSpeed(this.opponentMonsterFirst.speed, this.opponentMonsterFirst.effortSpeed);
        this.opponentMonster.effortHitPoints = this.opponentMonsterFirst.effortHitPoints;
        this.opponentMonster.effortAttack = this.opponentMonsterFirst.effortAttack;
        this.opponentMonster.effortDefense = this.opponentMonsterFirst.effortDefense;
        this.opponentMonster.effortSpecialAttack = this.opponentMonsterFirst.effortSpecialAttack;
        this.opponentMonster.effortSpecialDefense = this.opponentMonsterFirst.effortSpecialDefense;
        this.opponentMonster.effortSpeed = this.opponentMonsterFirst.effortSpeed;
        this.opponentMonster.skillList = this.opponentMonsterFirst.skillList;
      }
      if (newMonsterCousor == 2) {
        this.opponentMonster.pokeName = this.opponentMonsterSecond.pokeName;
        this.opponentMonster.nationalNo = this.opponentMonsterSecond.nationalNo;
        this.opponentMonster.skill = this.opponentMonsterSecond.skill;
        this.opponentMonster.ability = this.opponentMonsterSecond.ability;
        this.opponentMonster.abilityList = this.opponentMonsterSecond.abilityList;
        this.opponentMonster.item = this.opponentMonsterSecond.item;
        this.opponentMonster.teraType = this.opponentMonsterSecond.teraType;
        this.opponentMonster.firstType = this.opponentMonsterSecond.firstType;
        this.opponentMonster.secondType = this.opponentMonsterSecond.secondType;
        this.opponentMonster.hitPoints = this.realValueHitPoints(this.opponentMonsterSecond.hitPoints, this.opponentMonsterSecond.effortHitPoints);
        this.opponentMonster.attack = this.realValueAttack(this.opponentMonsterSecond.attack, this.opponentMonsterSecond.effortAttack);
        this.opponentMonster.defense = this.realValueDefense(this.opponentMonsterSecond.defense, this.opponentMonsterSecond.effortDefense);
        this.opponentMonster.specialAttack = this.realValueSpecialAttack(this.opponentMonsterSecond.specialAttack, this.opponentMonsterSecond.effortSpecialAttack);
        this.opponentMonster.specialDefense = this.realValueSpecialDefense(this.opponentMonsterSecond.specialDefense, this.opponentMonsterSecond.effortSpecialDefense);
        this.opponentMonster.speed = this.realValueSpeed(this.opponentMonsterSecond.speed, this.opponentMonsterSecond.effortSpeed);
        this.opponentMonster.effortHitPoints = this.opponentMonsterSecond.effortHitPoints;
        this.opponentMonster.effortAttack = this.opponentMonsterSecond.effortAttack;
        this.opponentMonster.effortDefense = this.opponentMonsterSecond.effortDefense;
        this.opponentMonster.effortSpecialAttack = this.opponentMonsterSecond.effortSpecialAttack;
        this.opponentMonster.effortSpecialDefense = this.opponentMonsterSecond.effortSpecialDefense;
        this.opponentMonster.effortSpeed = this.opponentMonsterSecond.effortSpeed;
        this.opponentMonster.skillList = this.opponentMonsterSecond.skillList;
      }
      if (newMonsterCousor == 3) {
        this.opponentMonster.pokeName = this.opponentMonsterThird.pokeName;
        this.opponentMonster.nationalNo = this.opponentMonsterThird.nationalNo;
        this.opponentMonster.skill = this.opponentMonsterThird.skill;
        this.opponentMonster.ability = this.opponentMonsterThird.ability;
        this.opponentMonster.abilityList = this.opponentMonsterThird.abilityList;
        this.opponentMonster.item = this.opponentMonsterThird.item;
        this.opponentMonster.teraType = this.opponentMonsterThird.teraType;
        this.opponentMonster.firstType = this.opponentMonsterThird.firstType;
        this.opponentMonster.secondType = this.opponentMonsterThird.secondType;
        this.opponentMonster.hitPoints = this.realValueHitPoints(this.opponentMonsterThird.hitPoints, this.opponentMonsterThird.effortHitPoints);
        this.opponentMonster.attack = this.realValueAttack(this.opponentMonsterThird.attack, this.opponentMonsterThird.effortAttack);
        this.opponentMonster.defense = this.realValueDefense(this.opponentMonsterThird.defense, this.opponentMonsterThird.effortDefense);
        this.opponentMonster.specialAttack = this.realValueSpecialAttack(this.opponentMonsterThird.specialAttack, this.opponentMonsterThird.effortSpecialAttack);
        this.opponentMonster.specialDefense = this.realValueSpecialDefense(this.opponentMonsterThird.specialDefense, this.opponentMonsterThird.effortSpecialDefense);
        this.opponentMonster.speed = this.realValueSpeed(this.opponentMonsterThird.speed, this.opponentMonsterThird.effortSpeed);
        this.opponentMonster.effortHitPoints = this.opponentMonsterThird.effortHitPoints;
        this.opponentMonster.effortAttack = this.opponentMonsterThird.effortAttack;
        this.opponentMonster.effortDefense = this.opponentMonsterThird.effortDefense;
        this.opponentMonster.effortSpecialAttack = this.opponentMonsterThird.effortSpecialAttack;
        this.opponentMonster.effortSpecialDefense = this.opponentMonsterThird.effortSpecialDefense;
        this.opponentMonster.effortSpeed = this.opponentMonsterThird.effortSpeed;
        this.opponentMonster.skillList = this.opponentMonsterThird.skillList;
      }
    },
    myMonsterCousorChange(newMonsterCousor) {
      this.myMonsterCursor = newMonsterCousor;
      this.myMonsterStatusChange(newMonsterCousor);
    },
    myMonsterStatusChange(newMonsterCousor) {
      if (newMonsterCousor == 4) {
        this.myMonster.pokeName = this.myMonsterFirst.pokeName;
        this.myMonster.nationalNo = this.myMonsterFirst.nationalNo;
        this.myMonster.skill = this.myMonsterFirst.skill;
        this.myMonster.ability = this.myMonsterFirst.ability;
        this.myMonster.abilityList = this.myMonsterFirst.abilityList;
        this.myMonster.item = this.myMonsterFirst.item;
        this.myMonster.teraType = this.myMonsterFirst.teraType;
        this.myMonster.firstType = this.myMonsterFirst.firstType;
        this.myMonster.secondType = this.myMonsterFirst.secondType;
        this.myMonster.hitPoints = this.realValueHitPoints(this.myMonsterFirst.hitPoints, this.myMonsterFirst.effortHitPoints);
        this.myMonster.attack = this.realValueAttack(this.myMonsterFirst.attack, this.myMonsterFirst.effortAttack);
        this.myMonster.defense = this.realValueDefense(this.myMonsterFirst.defense, this.myMonsterFirst.effortDefense);
        this.myMonster.specialAttack = this.realValueSpecialAttack(this.myMonsterFirst.specialAttack, this.myMonsterFirst.effortSpecialAttack);
        this.myMonster.specialDefense = this.realValueSpecialDefense(this.myMonsterFirst.specialDefense, this.myMonsterFirst.effortSpecialDefense);
        this.myMonster.speed = this.realValueSpeed(this.myMonsterFirst.speed, this.myMonsterFirst.effortSpeed);
        this.myMonster.effortHitPoints = this.myMonsterFirst.effortHitPoints;
        this.myMonster.effortAttack = this.myMonsterFirst.effortAttack;
        this.myMonster.effortDefense = this.myMonsterFirst.effortDefense;
        this.myMonster.effortSpecialAttack = this.myMonsterFirst.effortSpecialAttack;
        this.myMonster.effortSpecialDefense = this.myMonsterFirst.effortSpecialDefense;
        this.myMonster.effortSpeed = this.myMonsterFirst.effortSpeed;
        this.myMonster.skills = this.myMonsterFirst.skills;
        this.myMonster.skillList = this.myMonsterFirst.skillList;
        this.myMonster.skillPower = this.myMonsterFirst.skillPower;
        this.myMonster.classification = this.myMonsterFirst.classification;
      }
      if (newMonsterCousor == 5) {
        this.myMonster.pokeName = this.myMonsterSecond.pokeName;
        this.myMonster.nationalNo = this.myMonsterSecond.nationalNo;
        this.myMonster.skill = this.myMonsterSecond.skill;
        this.myMonster.ability = this.myMonsterSecond.ability;
        this.myMonster.abilityList = this.myMonsterSecond.abilityList;
        this.myMonster.item = this.myMonsterSecond.item;
        this.myMonster.teraType = this.myMonsterSecond.teraType;
        this.myMonster.firstType = this.myMonsterSecond.firstType;
        this.myMonster.secondType = this.myMonsterSecond.secondType;
        this.myMonster.hitPoints = this.realValueHitPoints(this.myMonsterSecond.hitPoints, this.myMonsterSecond.effortHitPoints);
        this.myMonster.attack = this.realValueAttack(this.myMonsterSecond.attack, this.myMonsterSecond.effortAttack);
        this.myMonster.defense = this.realValueDefense(this.myMonsterSecond.defense, this.myMonsterSecond.effortDefense);
        this.myMonster.specialAttack = this.realValueSpecialAttack(this.myMonsterSecond.specialAttack, this.myMonsterSecond.effortSpecialAttack);
        this.myMonster.specialDefense = this.realValueSpecialDefense(this.myMonsterSecond.specialDefense, this.myMonsterSecond.effortSpecialDefense);
        this.myMonster.speed = this.realValueSpeed(this.myMonsterSecond.speed, this.myMonsterSecond.effortSpeed);
        this.myMonster.effortHitPoints = this.myMonsterSecond.effortHitPoints;
        this.myMonster.effortAttack = this.myMonsterSecond.effortAttack;
        this.myMonster.effortDefense = this.myMonsterSecond.effortDefense;
        this.myMonster.effortSpecialAttack = this.myMonsterSecond.effortSpecialAttack;
        this.myMonster.effortSpecialDefense = this.myMonsterSecond.effortSpecialDefense;
        this.myMonster.effortSpeed = this.myMonsterSecond.effortSpeed;
        this.myMonster.skills = this.myMonsterSecond.skills;
        this.myMonster.skillList = this.myMonsterSecond.skillList;
        this.myMonster.skillPower = this.myMonsterSecond.skillPower;
        this.myMonster.classification = this.myMonsterSecond.classification;
      }
      if (newMonsterCousor == 6) {
        this.myMonster.pokeName = this.myMonsterThird.pokeName;
        this.myMonster.nationalNo = this.myMonsterThird.nationalNo;
        this.myMonster.skill = this.myMonsterThird.skill;
        this.myMonster.ability = this.myMonsterThird.ability;
        this.myMonster.abilityList = this.myMonsterThird.abilityList;
        this.myMonster.item = this.myMonsterThird.item;
        this.myMonster.teraType = this.myMonsterThird.teraType;
        this.myMonster.firstType = this.myMonsterThird.firstType;
        this.myMonster.secondType = this.myMonsterThird.secondType;
        this.myMonster.hitPoints = this.realValueHitPoints(this.myMonsterThird.hitPoints, this.myMonsterThird.effortHitPoints);
        this.myMonster.attack = this.realValueAttack(this.myMonsterThird.attack, this.myMonsterThird.effortAttack);
        this.myMonster.defense = this.realValueDefense(this.myMonsterThird.defense, this.myMonsterThird.effortDefense);
        this.myMonster.specialAttack = this.realValueSpecialAttack(this.myMonsterThird.specialAttack, this.myMonsterThird.effortSpecialAttack);
        this.myMonster.specialDefense = this.realValueSpecialDefense(this.myMonsterThird.specialDefense, this.myMonsterThird.effortSpecialDefense);
        this.myMonster.speed = this.realValueSpeed(this.myMonsterThird.speed, this.myMonsterThird.effortSpeed);
        this.myMonster.effortHitPoints = this.myMonsterThird.effortHitPoints;
        this.myMonster.effortAttack = this.myMonsterThird.effortAttack;
        this.myMonster.effortDefense = this.myMonsterThird.effortDefense;
        this.myMonster.effortSpecialAttack = this.myMonsterThird.effortSpecialAttack;
        this.myMonster.effortSpecialDefense = this.myMonsterThird.effortSpecialDefense;
        this.myMonster.effortSpeed = this.myMonsterThird.effortSpeed;
        this.myMonster.skills = this.myMonsterThird.skills;
        this.myMonster.skillList = this.myMonsterThird.skillList;
        this.myMonster.skillPower = this.myMonsterThird.skillPower;
        this.myMonster.classification = this.myMonsterThird.classification;
      }
    },
    opponentMonsterFirstUpdate(formPokeInfo) {
      var tmpPokeName = this.opponentMonsterFirst.pokeName;
      this.opponentMonsterFirst.pokeName = formPokeInfo.pokeName;
      this.opponentMonsterFirst.nationalNo = formPokeInfo.nationalNo;
      this.opponentMonsterFirst.ability = formPokeInfo.ability;
      this.opponentMonsterFirst.abilityList = formPokeInfo.abilityList;
      this.opponentMonsterFirst.item = formPokeInfo.item;
      this.opponentMonsterFirst.teraType = formPokeInfo.teraType;
      this.opponentMonsterFirst.effortHitPoints = formPokeInfo.effortHitPoints;
      this.opponentMonsterFirst.effortAttack = formPokeInfo.effortAttack;
      this.opponentMonsterFirst.effortDefense = formPokeInfo.effortDefense;
      this.opponentMonsterFirst.effortSpecialAttack = formPokeInfo.effortSpecialAttack;
      this.opponentMonsterFirst.effortSpecialDefense = formPokeInfo.effortSpecialDefense;
      this.opponentMonsterFirst.effortSpeed = formPokeInfo.effortSpeed;
      var updatePoke = this.pokeList.find(monster => monster.monsterName === formPokeInfo.pokeName);
      this.opponentMonsterFirst.firstType = this.typeList.find(list => list.id === updatePoke.firstType);
      this.opponentMonsterFirst.secondType = this.typeList.find(list => list.id === updatePoke.secondType)
      this.opponentMonsterFirst.hitPoints = updatePoke.hitPoints;
      this.opponentMonsterFirst.attack = updatePoke.attack;
      this.opponentMonsterFirst.defense = updatePoke.defense;
      this.opponentMonsterFirst.specialAttack = updatePoke.spAttack;
      this.opponentMonsterFirst.specialDefense = updatePoke.spDefense;
      this.opponentMonsterFirst.speed = updatePoke.speed;
      if (tmpPokeName !== formPokeInfo.pokeName) {
        this.fetchSkills(updatePoke.nationalNo, 1);
      }
    },
    opponentMonsterSecondUpdate(formPokeInfo) {
      var tmpPokeName = this.opponentMonsterSecond.pokeName;
      this.opponentMonsterSecond.pokeName = formPokeInfo.pokeName;
      this.opponentMonsterSecond.nationalNo = formPokeInfo.nationalNo;
      this.opponentMonsterSecond.ability = formPokeInfo.ability;
      this.opponentMonsterSecond.abilityList = formPokeInfo.abilityList;
      this.opponentMonsterSecond.item = formPokeInfo.item;
      this.opponentMonsterSecond.teraType = formPokeInfo.teraType;
      this.opponentMonsterSecond.effortHitPoints = formPokeInfo.effortHitPoints;
      this.opponentMonsterSecond.effortAttack = formPokeInfo.effortAttack;
      this.opponentMonsterSecond.effortDefense = formPokeInfo.effortDefense;
      this.opponentMonsterSecond.effortSpecialAttack = formPokeInfo.effortSpecialAttack;
      this.opponentMonsterSecond.effortSpecialDefense = formPokeInfo.effortSpecialDefense;
      this.opponentMonsterSecond.effortSpeed = formPokeInfo.effortSpeed;
      var updatePoke = this.pokeList.find(monster => monster.monsterName === formPokeInfo.pokeName);
      this.opponentMonsterSecond.firstType = this.typeList.find(list => list.id === updatePoke.firstType);
      this.opponentMonsterSecond.secondType = this.typeList.find(list => list.id === updatePoke.secondType);
      this.opponentMonsterSecond.hitPoints = updatePoke.hitPoints;
      this.opponentMonsterSecond.attack = updatePoke.attack;
      this.opponentMonsterSecond.defense = updatePoke.defense;
      this.opponentMonsterSecond.specialAttack = updatePoke.spAttack;
      this.opponentMonsterSecond.specialDefense = updatePoke.spDefense;
      this.opponentMonsterSecond.speed = updatePoke.speed;
      if (tmpPokeName !== formPokeInfo.pokeName) {
        this.fetchSkills(updatePoke.nationalNo, 2);
      }
    },
    opponentMonsterThirdUpdate(formPokeInfo) {
      var tmpPokeName = this.opponentMonsterThird.pokeName;
      this.opponentMonsterThird.pokeName = formPokeInfo.pokeName;
      this.opponentMonsterThird.nationalNo = formPokeInfo.nationalNo;
      this.opponentMonsterThird.ability = formPokeInfo.ability;
      this.opponentMonsterThird.abilityList = formPokeInfo.abilityList;
      this.opponentMonsterThird.item = formPokeInfo.item;
      this.opponentMonsterThird.teraType = formPokeInfo.teraType;
      this.opponentMonsterThird.effortHitPoints = formPokeInfo.effortHitPoints;
      this.opponentMonsterThird.effortAttack = formPokeInfo.effortAttack;
      this.opponentMonsterThird.effortDefense = formPokeInfo.effortDefense;
      this.opponentMonsterThird.effortSpecialAttack = formPokeInfo.effortSpecialAttack;
      this.opponentMonsterThird.effortSpecialDefense = formPokeInfo.effortSpecialDefense;
      this.opponentMonsterThird.effortSpeed = formPokeInfo.effortSpeed;
      var updatePoke = this.pokeList.find(monster => monster.monsterName === formPokeInfo.pokeName);
      this.opponentMonsterThird.firstType = this.typeList.find(list => list.id === updatePoke.firstType);
      this.opponentMonsterThird.secondType = this.typeList.find(list => list.id === updatePoke.secondType);
      this.opponentMonsterThird.hitPoints = updatePoke.hitPoints;
      this.opponentMonsterThird.attack = updatePoke.attack;
      this.opponentMonsterThird.defense = updatePoke.defense;
      this.opponentMonsterThird.specialAttack = updatePoke.spAttack;
      this.opponentMonsterThird.specialDefense = updatePoke.spDefense;
      this.opponentMonsterThird.speed = updatePoke.speed;
      if (tmpPokeName !== formPokeInfo.pokeName) {
        this.fetchSkills(updatePoke.nationalNo, 3);
      }
    },
    myMonsterFirstUpdate(formPokeInfo) {
      var tmpPokeName = this.myMonsterFirst.pokeName;
      this.myMonsterFirst.pokeName = formPokeInfo.pokeName;
      this.myMonsterFirst.nationalNo = formPokeInfo.nationalNo;
      this.myMonsterFirst.ability = formPokeInfo.ability;
      this.myMonsterFirst.abilityList = formPokeInfo.abilityList;
      this.myMonsterFirst.item = formPokeInfo.item;
      this.myMonsterFirst.teraType = formPokeInfo.teraType;
      this.myMonsterFirst.effortHitPoints = formPokeInfo.effortHitPoints;
      this.myMonsterFirst.effortAttack = formPokeInfo.effortAttack;
      this.myMonsterFirst.effortDefense = formPokeInfo.effortDefense;
      this.myMonsterFirst.effortSpecialAttack = formPokeInfo.effortSpecialAttack;
      this.myMonsterFirst.effortSpecialDefense = formPokeInfo.effortSpecialDefense;
      this.myMonsterFirst.effortSpeed = formPokeInfo.effortSpeed;
      var updatePoke = this.pokeList.find(monster => monster.monsterName === formPokeInfo.pokeName);
      this.myMonsterFirst.firstType = this.typeList.find(list => list.id === updatePoke.firstType);
      this.myMonsterFirst.secondType = this.typeList.find(list => list.id === updatePoke.secondType);
      this.myMonsterFirst.hitPoints = updatePoke.hitPoints;
      this.myMonsterFirst.attack = updatePoke.attack;
      this.myMonsterFirst.defense = updatePoke.defense;
      this.myMonsterFirst.specialAttack = updatePoke.spAttack;
      this.myMonsterFirst.specialDefense = updatePoke.spDefense;
      this.myMonsterFirst.speed = updatePoke.speed;
      if (tmpPokeName !== formPokeInfo.pokeName) {
        this.fetchSkills(updatePoke.nationalNo, 4);
      }
    },
    myMonsterSecondUpdate(formPokeInfo) {
      var tmpPokeName = this.myMonsterSecond.pokeName;
      this.myMonsterSecond.pokeName = formPokeInfo.pokeName;
      this.myMonsterSecond.nationalNo = formPokeInfo.nationalNo;
      this.myMonsterSecond.ability = formPokeInfo.ability;
      this.myMonsterSecond.abilityList = formPokeInfo.abilityList;
      this.myMonsterSecond.item = formPokeInfo.item;
      this.myMonsterSecond.teraType = formPokeInfo.teraType;
      this.myMonsterSecond.effortHitPoints = formPokeInfo.effortHitPoints;
      this.myMonsterSecond.effortAttack = formPokeInfo.effortAttack;
      this.myMonsterSecond.effortDefense = formPokeInfo.effortDefense;
      this.myMonsterSecond.effortSpecialAttack = formPokeInfo.effortSpecialAttack;
      this.myMonsterSecond.effortSpecialDefense = formPokeInfo.effortSpecialDefense;
      this.myMonsterSecond.effortSpeed = formPokeInfo.effortSpeed;
      var updatePoke = this.pokeList.find(monster => monster.monsterName === formPokeInfo.pokeName);
      this.myMonsterSecond.firstType = this.typeList.find(list => list.id === updatePoke.firstType);
      this.myMonsterSecond.secondType = this.typeList.find(list => list.id === updatePoke.secondType);
      this.myMonsterSecond.hitPoints = updatePoke.hitPoints;
      this.myMonsterSecond.attack = updatePoke.attack;
      this.myMonsterSecond.defense = updatePoke.defense;
      this.myMonsterSecond.specialAttack = updatePoke.spAttack;
      this.myMonsterSecond.specialDefense = updatePoke.spDefense;
      this.myMonsterSecond.speed = updatePoke.speed;
      if (tmpPokeName !== formPokeInfo.pokeName) {
        this.fetchSkills(updatePoke.nationalNo, 5);
      }
    },
    myMonsterThirdUpdate(formPokeInfo) {
      var tmpPokeName = this.myMonsterThird.pokeName;
      this.myMonsterThird.pokeName = formPokeInfo.pokeName;
      this.myMonsterThird.nationalNo = formPokeInfo.nationalNo;
      this.myMonsterThird.ability = formPokeInfo.ability;
      this.myMonsterThird.abilityList = formPokeInfo.abilityList;
      this.myMonsterThird.item = formPokeInfo.item;
      this.myMonsterThird.teraType = formPokeInfo.teraType;
      this.myMonsterThird.effortHitPoints = formPokeInfo.effortHitPoints;
      this.myMonsterThird.effortAttack = formPokeInfo.effortAttack;
      this.myMonsterThird.effortDefense = formPokeInfo.effortDefense;
      this.myMonsterThird.effortSpecialAttack = formPokeInfo.effortSpecialAttack;
      this.myMonsterThird.effortSpecialDefense = formPokeInfo.effortSpecialDefense;
      this.myMonsterThird.effortSpeed = formPokeInfo.effortSpeed;
      var updatePoke = this.pokeList.find(monster => monster.monsterName === formPokeInfo.pokeName);
      this.myMonsterThird.firstType = this.typeList.find(list => list.id === updatePoke.firstType);
      this.myMonsterThird.secondType = this.typeList.find(list => list.id === updatePoke.secondType);
      this.myMonsterThird.hitPoints = updatePoke.hitPoints;
      this.myMonsterThird.attack = updatePoke.attack;
      this.myMonsterThird.defense = updatePoke.defense;
      this.myMonsterThird.specialAttack = updatePoke.spAttack;
      this.myMonsterThird.specialDefense = updatePoke.spDefense;
      this.myMonsterThird.speed = updatePoke.speed;
      if (tmpPokeName !== formPokeInfo.pokeName) {
        this.fetchSkills(updatePoke.nationalNo, 6);
      }
    },
    fetchPokeList() {
      this.axios.get("https://pokesingle.com/monster/all/monsters")
        .then(response => {
          this.pokeList = response.data;
          for (const elem of this.pokeList) {
            this.pokeNameList.push(elem.monsterName);
          }
        })
        .catch(err => {
          console.error(err);
        })
    },
    // ポケモンのわざリストを取得
    fetchSkills(nationalNo, newMonsterCousor) {
      var url = "https://pokesingle.com/skillList/nationalNo/" + nationalNo
      this.axios.get(url)
        .then(response => {

          if (newMonsterCousor == 4) {
            this.myMonsterFirst.skills = response.data;
            this.myMonsterFirst.skillList = [];
            for (const elem of this.myMonsterFirst.skills) {
              this.myMonsterFirst.skillList.push(elem.skills.skillName);
            }

            this.myMonsterFirst.skill = this.myMonsterFirst.skills[0].skills.skillName;
            this.myMonsterFirst.skillPower = this.myMonsterFirst.skills[0].skills.damage;
            this.myMonsterFirst.classification = this.myMonsterFirst.skills[0].skills.classification;
            this.myMonsterFirst.skillType = this.typeList.find(list => list.id === this.myMonsterFirst.skills[0].skills.skillType);
          }
          if (newMonsterCousor == 5) {
            this.myMonsterSecond.skills = response.data;
            this.myMonsterSecond.skillList = [];
            for (const elem of this.myMonsterSecond.skills) {
              this.myMonsterSecond.skillList.push(elem.skills.skillName);
            }
            this.myMonsterSecond.skill = this.myMonsterSecond.skills[0].skills.skillName;
            this.myMonsterSecond.skillPower = this.myMonsterSecond.skills[0].skills.damage;
            this.myMonsterSecond.classification = this.myMonsterSecond.skills[0].skills.classification;
            this.myMonsterSecond.skillType = this.typeList.find(list => list.id === this.myMonsterFirst.skills[0].skills.skillType);
          }
          if (newMonsterCousor == 6) {
            this.myMonsterThird.skills = response.data;
            this.myMonsterThird.skillList = [];
            for (const elem of this.myMonsterThird.skills) {
              this.myMonsterThird.skillList.push(elem.skills.skillName);
            }
            this.myMonsterThird.skill = this.myMonsterThird.skills[0].skills.skillName;
            this.myMonsterThird.skillPower = this.myMonsterThird.skills[0].skills.damage;
            this.myMonsterThird.classification = this.myMonsterThird.skills[0].skills.classification;
            this.myMonsterThird.skillType = this.typeList.find(list => list.id === this.myMonsterFirst.skills[0].skills.skillType);
          }
        })
        .catch(err => {
          console.error(err);
        })
    },
    fetchItemList() {

    },
    fetchTypeCompatibilities() {
      this.axios.get("https://pokesingle.com/typeCompatibility/all")
        .then(response => {
          this.typeCompatibilities = response.data;
        })
        .catch(err => {
          console.error(err);
        })
    },
    realValueHitPoints(hitPoints, effortHitPoints) {
      if (effortHitPoints == 0) {
        return Math.trunc((hitPoints * 2 + 31) / 2) + 60
      }
      return Math.trunc((hitPoints * 2 + 31 + (effortHitPoints / 4)) / 2) + 60
    },
    //実際の攻撃力
    realValueAttack(attack, effortAttack) {
      if (effortAttack == 0) {
        return Math.trunc((attack * 2 + 31) / 2) + 5
      }
      //※性格補正なし
      return Math.trunc((attack * 2 + 31 + (effortAttack / 4)) / 2) + 5
    },
    //実際の防御力
    realValueDefense(defense, effortDefense) {
      if (effortDefense == 0) {
        return Math.trunc((defense * 2 + 31) / 2) + 5
      }
      //※性格補正なし
      return Math.trunc((defense * 2 + 31 + (effortDefense / 4)) / 2) + 5
    },
    //実際の特殊攻撃力
    realValueSpecialAttack(spAttack, effortSpAttack) {
      if (effortSpAttack == 0) {
        return Math.trunc((spAttack * 2 + 31) / 2) + 5
      }
      //※性格補正なし
      return Math.trunc((spAttack * 2 + 31 + (effortSpAttack / 4)) / 2) + 5
    },
    //実際の特殊防御力
    realValueSpecialDefense(spDefense, effortSpDefense) {
      if (effortSpDefense == 0) {
        return Math.trunc((spDefense * 2 + 31) / 2) + 5
      }
      //※性格補正なし
      return Math.trunc((spDefense * 2 + 31 + (effortSpDefense / 4)) / 2) + 5
    },
    //実際の素早さ
    realValueSpeed(speed, effortSpeed) {
      if (effortSpeed == 0) {
        return Math.trunc((speed * 2 + 31) / 2) + 5
      }
      //※性格補正なし
      return Math.trunc((speed * 2 + 31 + (effortSpeed / 4)) / 2) + 5
    }
  }
}

</script>

<style>
.pokeCalc {
  width : 390px;
  /* min-width: 390px;
  max-width: 500px; */
}

/* ダメージバー */
.progress {
  height: 2rem;
  border-radius: 0.6rem;
}

.progress-bar {}

.min-bar {
  width: v-bind(damageBarMin);
  background-color: #558E54;
}

.max-bar {
  width: v-bind(damageBarMax);
  background-color: #80D67F;
}

.damagebar {
  height: 10%;
}

/* メイン画面     */
.damageCaluc {
  height: 5rem;
  border-bottom: 1.5px solid #F9F7F7;
}

.opponentStatusName {
  font-size: 1.0rem;
  margin: 0;
}

.myStatusName {
  font-size: 1.0rem;
  margin: 0;
}

.opponentStatus {
  background-color: #525252;
  font-size: 1.2rem;
  border: solid 2px #3f72af;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
}

.myStatus {
  background-color: #525252;
  font-size: 1.2rem;
  border: solid 2px #FA4B4B;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
}

.opponentName {
  font-size: 1.4rem;
}

.myName {
  font-size: 1.4rem;
}

.damageText {
  font-size: 1.0rem;
}

.opponentBaseStatus {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.myBaseStatus {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

/* .opponentField {
    } */

.myField {
  padding-bottom: 10%;
}

.btn {
  color: #F9F7F7;
  padding: 0.5rem 0.75rem;
}

.opponentFieldText {
  font-size: 1.4rem;
}

.myFieldText {
  font-size: 1.4rem;
}

.opponentMonster {
  background-color: #3f72af;
  font-size: 1.1rem;
  white-space: nowrap;
  font-weight: 300;
}

.myMonster {
  background-color: #FA4B4B;
  font-size: 1.1rem;
  white-space: nowrap;
  font-weight: 300;
}

.statusRow {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 2.0rem;
}

.lCol {
}

.rCol {
  margin-left: 0;
}

.statusTitle {
  padding-right:0;
  padding-left: 0;
  color: #F9F7F7;
  border-bottom: 1.5px solid #F9F7F7;
}

.statusValue {
  padding-right:0;
  padding-left: 0;
  color: #F9F7F7;
  border-bottom: 1.5px solid #F9F7F7;
}

/* わざ選択のカスタム */
.skillSelect {
  width: 100%
}

/*ステータス選択ボックスのカスタム*/
.statusSelect {
  position: relative;
  bottom: 5px;
}

/* 選択ボックスのカスタム */
.vs__dropdown-toggle {
  background-color: #F9F7F7;
  color: #202020;
}

.skillSelect .vs__selected {
  font-size: 1.2rem;
  white-space: nowrap;
}

.statusSelect .vs__selected {
  font-size: 1.0rem;
  white-space: nowrap;
}


.skillSelect .vs__dropdown-menu {
  color: #202020;
  font-size: 1.2rem;
  max-height: 100px;
}

.statusSelect .vs__dropdown-menu {
  color: #202020;
  font-size: 1.0rem;
  max-height: 100px;
}

.vs__selected-options {
  flex-wrap: nowrap;
}

.statusSelect .vs__actions {
  position: absolute;
  right: 0;
  top: 4px;
}

.statusSelect .vs__clear {
  margin-right: 3px;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin-bottom: 8px;
  margin-right: 10px;
}

/* 開発中用の表示style */
.development {
  text-align: right;
  color: #AAAAAA;
}
</style>../calculate/damageCalc.js./damageCalc.js