// 攻撃の確定数、乱数確率を計算する関数
function attackCount(maxDamage, minDamage, opponentMonster) {

    //ダメージが0の場合は空文字を返す
    if (maxDamage === 0) {
        return '';
    }

    let needMin = Math.ceil(opponentMonster.hitPoints / maxDamage);
    let needMax = Math.ceil(opponentMonster.hitPoints / minDamage);

    let messageHit = '';
    let messagePer = '';
    // 確定
    if (needMin === needMax) {
        messageHit = '確定' + needMin.toString() + '発';
    } else {
        messageHit = '乱数' + needMin.toString() + '～' + needMax.toString() + '発';
    }

    //ダメージ量のパーセンテージ表示
    var minDamagePer = Math.round(1000 * minDamage / opponentMonster.hitPoints) / 10;
    var maxDamagePer = Math.round(1000 * maxDamage / opponentMonster.hitPoints) / 10;

    messagePer = ' (' + minDamagePer.toString() + '%～' + maxDamagePer.toString() + '%) ';

    return messagePer + messageHit;
}

export { attackCount };
